<template>
  <div class="charts-view">
    <div class="charts-view__content">
      <title-deck>Statistiques</title-deck>
      <div class="charts-view__filter">
        <dc-select
          v-if="thematics"
          selectId="thematicFilter"
          :options="thematics"
          v-model="selectedThematic"
          placeholder="Thématique"
        />
        <dc-select
          v-if="advisers"
          selectId="adviserFilter"
          :options="advisers"
          v-model="selectedAdviser"
          placeholder="Conseillè(re)"
        />
        <dc-select
          v-if="places"
          selectId="placeFilter"
          :options="places"
          v-model="selectedPlace"
          placeholder="Lieu"
        />
        <dc-select
          v-if="status"
          selectId="statusFilter"
          :options="status"
          v-model="selectedStatus"
          placeholder="Statut"
        />
        <dc-input
          type="date"
          inputId="startDate"
          v-model="startDate"
        />
        <dc-input
          type="date"
          inputId="endDate"
          v-model="endDate"
        />
      </div>
      <dc-pie-chart
        ref="chartRef"
        v-if="!loadingData && chartData"
        :chart-data="chartData"
        :options="options"
      />
      <div
        v-if="appointments"
        class="charts-view__actions row"
      >
        <div class="col-7"></div>
        <div class="col-2">
          <dc-button @click="exportToCsv">Exporter (csv)</dc-button>
        </div>
        <div class="col-1"></div>
        <div class="col-2">
          <dc-button @click="exportToPdf">Exporter (pdf)</dc-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import TitleDeck from '@/components/ui/TitleDeck'
import DcPieChart from '@/components/charts/pie'
import DcSelect from '@/components/ui/Select'
import DcInput from '@/components/ui/Input'
import DcButton from '@/components/ui/Button'
import { getAllThematics } from '@/services/thematic.service.js'
import { getUserByRole } from '@/services/user.service.js'
import { getAllPlaces } from '@/services/place.service.js'
import { getAllStatus, getAppointmentStats } from '@/services/appointment.service.js'
import { exportChartsToPdf } from '@/services/pdf.service.js'

export default {
  name: 'ChartsView',
  components: {
    DcPieChart,
    DcSelect,
    TitleDeck,
    DcInput,
    DcButton
  },
  data () {
    return {
      chartData: null,
      options: {
        responsive: true,
        maintainAspectRatio: false
      },
      thematics: null,
      selectedThematic: null,
      advisers: null,
      selectedAdviser: null,
      places: null,
      selectedPlace: null,
      status: [
        { label: 'Rendez-vous', value: 'APPOINTMENT' },
        { label: 'Absence', value: 'ABSCENCE' },
        { label: 'Indisponibilité', value: 'UNAVAILABILITY' },
        { label: 'Annulé', value: 'CANCELED' },
        { label: 'Défaillant', value: 'FAILING' }
      ],
      selectedStatus: null,
      startDate: null,
      endDate: null,
      loadingData: false,
      appointments: null,
      total: 0,
      value: 0
    }
  },
  watch: {
    selectedThematic () {
      this.refreshData()
    },
    selectedAdviser () {
      this.refreshData()
    },
    selectedPlace () {
      this.refreshData()
    },
    selectedStatus () {
      this.refreshData()
    },
    startDate () {
      this.refreshData()
    },
    endDate () {
      this.refreshData()
    }
  },
  computed: {
    thematicId () {
      return this.selectedThematic ? JSON.parse(this.selectedThematic).id : null
    },
    userId () {
      return this.selectedAdviser ? JSON.parse(this.selectedAdviser).id : null
    },
    placeId () {
      return this.selectedPlace ? JSON.parse(this.selectedPlace).id : null
    },
    formattedStartDate () {
      return this.startDate ? dayjs(this.startDate).format('DD/MM/YYYY') : dayjs().format('DD/MM/YYYY')
    },
    formattedEndDate () {
      return this.endDate ? dayjs(this.endDate).format('DD/MM/YYYY') : dayjs().format('DD/MM/YYYY')
    },
    labels () {
      return [`Total ${this.total}`, `Filtrés ${this.value}`]
    }
  },
  methods: {
    async exportToPdf () {
      try {
        await exportChartsToPdf(this.appointments, this.$refs.chartRef)
      } catch (error) {
        // console.error(error)
      }
    },
    exportToCsv () {
      const header = Object.keys(this.appointments[0])
      let csvContent = 'data:text/csv;charset=utf-8,'
      csvContent += header.join(',')
      csvContent += '\r\n'
      this.appointments.forEach(a => {
        const line = []
        header.forEach(h => {
          line.push(a[h])
        })
        csvContent += line.join(',')
        csvContent += '\r\n'
      })

      const date = dayjs().format('DD-MM-YYYY')
      var encodedUri = encodeURI(csvContent)
      var link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', `export-${date}.csv`)
      document.body.appendChild(link)

      link.click()
      document.body.removeChild(link)
    },
    async refreshData () {
      this.loadingData = true
      try {
        const result = await getAppointmentStats(
          this.thematicId,
          this.userId,
          this.placeId,
          this.selectedStatus,
          this.formattedStartDate,
          this.formattedEndDate
        )
        if (result) {
          this.total = result.total
          this.value = result.value
          this.appointments = result.appointments
          this.chartData = {
            labels: this.labels,
            datasets: [{
              backgroundColor: ['#0597de', '#fa4e12'],
              data: [result.total, result.value]
            }]
          }
        }
      } catch (error) {
        // console.error('loadPlaces', error)
      } finally {
        this.loadingData = false
      }
    },
    async loadPlaces () {
      try {
        const results = await getAllPlaces()
        if (results) {
          this.places = results.map(p => {
            return {
              label: p.name,
              value: JSON.stringify(p)
            }
          }).sort((a, b) => {
            if (a.label > b.label) {
              return 1
            } else {
              return -1
            }
          })
        }
      } catch (error) {
        // console.error('loadPlaces', error)
      }
    },
    async loadThematics () {
      try {
        const results = await getAllThematics()
        if (results) {
          this.thematics = results.map(t => {
            return {
              label: t.name,
              value: JSON.stringify(t)
            }
          }).sort((a, b) => {
            if (a.label > b.label) {
              return 1
            } else {
              return -1
            }
          })
        }
      } catch (error) {
        // console.error('loadThematics', error)
      }
    },
    async loadAdvisers () {
      try {
        const results = await getUserByRole(['JURISTE', 'JURISTESUP'])
        if (results) {
          this.advisers = results.map(a => {
            return {
              label: `${a.firstName} ${a.lastName}`,
              value: JSON.stringify(a)
            }
          }).sort((a, b) => {
            if (a.label > b.label) {
              return 1
            } else {
              return -1
            }
          })
        }
      } catch (error) {
        // console.error('loadAdvisers', error)
      }
    },
    async loadStatus () {
      try {
        const results = await getAllStatus()
        if (results) {
          this.status = results.map(s => {
            return {
              label: s,
              value: s
            }
          }).sort((a, b) => {
            if (a.label > b.label) {
              return 1
            } else {
              return -1
            }
          })
        }
      } catch (error) {
        // console.error('loadStatus', error)
      }
    }
  },
  async mounted () {
    this.endDate = dayjs().format('YYYY-MM-DD')
    this.startDate = dayjs().add(-1, 'year').format('YYYY-MM-DD')
    await this.loadThematics()
    await this.loadAdvisers()
    await this.loadPlaces()
    // await this.loadStatus()
    await this.refreshData()
  }
}
</script>

<style lang="scss">
.charts-view {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: $light-grey-blue;
  background: linear-gradient(90deg, $blue 38%, $light-grey-blue 38%);
  padding-top: calc(#{$header-height} + 1rem);
  overflow-y: auto;
  &__content {
    position: absolute;
    z-index: 2;
    border-radius: 1rem;
    left: 50%;
    top: 14rem;
    transform: translateX(-50%);
    width: 123.5rem;
    background-color: $light;
    box-shadow: 4.8px 7.6px 12.2px 0.8px rgba(0, 0, 0, 0.07);
    padding: 5rem;
    padding-top: 9rem;
    margin-bottom: 12rem;
  }
  &__filter {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-bottom: 2rem;
  }
}
</style>
