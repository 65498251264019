import axios from 'axios'
import store from '@/store'

export async function getAllUsersList () {
  let users = null
  try {
    store.dispatch('SET_LOADING', true)
    users = await axios.get(process.env.VUE_APP_API_URL + '/user?roles=JURISTE,JURISTESUP,ADMIN,MAIRIE,PARTENAIRE')
  } catch (error) {
    throw Error('ERROR user getAllUsersList() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
  return users
}

export async function userQuery (query) {
  try {
    const response = await axios.get(process.env.VUE_APP_API_URL + `/user/autocomplete?query=${query}&roles=CLIENT`)
    return response.data
  } catch (error) {
    throw Error('ERROR userQuery(' + query + ') : ' + error)
  }
}

export async function getUserList () {
  let users = null
  try {
    store.dispatch('SET_LOADING', true)
    users = await axios.get(process.env.VUE_APP_API_URL + '/user?role=USER')
    users.data.forEach(u => {
      u.label = `${u.firstName} ${u.lastName}`
    })
  } catch (error) {
    throw Error('ERROR user getUserList() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
  return users ? users.data.data : null
}

export async function getUserByRole (roles) {
  let response = null
  try {
    store.dispatch('SET_LOADING', true)
    response = await axios.get(process.env.VUE_APP_API_URL + `/user?roles=${roles.join(',')}`)
    response.data.forEach(u => {
      u.label = `${u.firstName} ${u.lastName}`
    })
  } catch (error) {
    throw Error('ERROR user getUserByRole() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
  return response ? response.data : null
}

export async function login (email, password) {
  let user = null
  let token = null
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.post(process.env.VUE_APP_API_URL + '/login', { email, password })
    sessionStorage.setItem('currentUser', JSON.stringify(response.data.user))
    sessionStorage.setItem('secrettoken', response.data.token)
    user = response.data.user
    token = response.data.token
  } catch (error) {
    throw Error('ERROR user login() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
  return {
    user,
    token
  }
}

export async function logout () {
  try {
    store.dispatch('SET_LOADING', true)
    await store.dispatch('LOGOUT')
    sessionStorage.removeItem('currentUser')
    sessionStorage.removeItem('secrettoken')
  } catch (error) {
    throw Error('ERROR user logout() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function register (user) {
  let users = null
  try {
    store.dispatch('SET_LOADING', true)
    users = await axios.post(process.env.VUE_APP_API_URL + '/registration', user)
    if (users.data && users.data.code && users.data.code === 'DUPLICATE_USER_MAIL_OR_PHONE') {
      throw Object.assign({ code: users.data.code, message: 'Votre compte existe déjà (même numéro de téléphone ou email), veuillez renouveler votre mot de passe si vous l\'avez oublié' })
    }
  } catch (error) {
    if (error.code === 'DUPLICATE_USER_MAIL_OR_PHONE') {
      throw Error(error.message)
    } else {
      throw Error(error)
    }
  } finally {
    store.dispatch('SET_LOADING', false)
  }
  return users
}

export async function add (user) {
  let users = null
  try {
    store.dispatch('SET_LOADING', true)
    users = await axios.post(process.env.VUE_APP_API_URL + '/user', user)
  } catch (error) {
    throw Error('ERROR user add() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
  return users
}

export async function update (user) {
  let users = null
  const endPoint = '/user/update'
  try {
    store.dispatch('SET_LOADING', true)
    users = await axios.post(process.env.VUE_APP_API_URL + endPoint, user)
    if (users.data && users.data.code && users.data.code === 'error') {
      throw users.data.label
    }
  } catch (error) {
    throw Error('ERROR user update() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
  return users
}

export async function activate (token) {
  let user = null
  try {
    user = await axios.post(process.env.VUE_APP_API_URL + '/user/activate', { token })
    if (user.data && user.data.code && user.data.code === 'error') {
      throw user.data.label
    }
  } catch (error) {
    throw Error('ERROR user activate() : ' + error)
  }
  return user
}

export async function desactivate () {
  let user = null
  try {
    user = await axios.post(process.env.VUE_APP_API_URL + '/user/desactivate')
    if (user.data && user.data.code && user.data.code === 'error') {
      throw user.data.label
    }
  } catch (error) {
    throw Error('ERROR user desactivate() : ' + error)
  }
  return user
}

export async function resetPassword (reset) {
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.post(process.env.VUE_APP_API_URL + '/reset_password', reset)
    if (response.data && response.data.code && response.data.code === 'error') {
      throw response.data.label
    }
    return response.data
  } catch (error) {
    throw Error('ERROR user resetPassword : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function askResetPassword (email) {
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.post(process.env.VUE_APP_API_URL + '/password/' + email)
    if (response.data && response.data.code && response.data.code === 'error') {
      throw response.data.label
    }
    return response.data
  } catch (error) {
    throw Error('ERROR user askResetPassword : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function getBannerForUser (id) {
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.get(process.env.VUE_APP_API_URL + `/user/banner/${id}`)
    return response?.data
  } catch (error) {
    throw Error('ERROR user getBannerForUser() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}
