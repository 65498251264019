export const mutations = {
  SET_SEARCH (state, search) {
    state.search = search
  },
  SET_LOADING (state, isLoading) {
    if (isLoading === true) {
      state.isLoading++
    } else if (isLoading === false) {
      state.isLoading--
      if (state.isLoading < 0) {
        state.isLoading = 0
      }
    }
  },
  SET_PREVIOUS_ROUTE (state, route) {
    state.previousRoute = route
  },
  SET_EDITION_PLACE (state, place) {
    state.editionPlace = place
  },
  CLEAR_EDITION_PLACE (state, place) {
    state.editionPlace = null
  },
  SET_USER (state, user) {
    state.user = user
  },
  SET_TOKEN (state, user) {
    state.token = user
  },
  SET_USERS (state, users) {
    state.users = users
  },
  SET_CITIES (state, cities) {
    state.cities = cities
  },
  SET_THEMATICS (state, cities) {
    state.thematics = cities
  },
  SET_CURRENT_THEMATIC (state, thematicId) {
    state.currentThematic = thematicId
  },
  SET_MENU_ITEMS (state, menuItems) {
    state.menuItems = menuItems
  },
  SET_ADIL_LIST (state, adilList) {
    state.adilList = adilList
  },
  SET_PERMANENCES_LIST (state, permanances) {
    state.permanances = permanances
  },
  SAVE_APPOINTMENT (state, appointment) {
    state.appointments.push(appointment)
  },
  UPDATE_APPOINTMENT (state, appointment) {
    state.appointments.push(appointment)
  },
  SET_APPOINTMENT_NEXT (state, appointments) {
    state.appointmentsNext = appointments
  },
  SET_APPOINTMENT_HISTORY (state, appointments) {
    state.appointmentsHistory = appointments
  },
  SET_BANNER (state, banner) {
    state.banner = banner
  }
}
