<template>
  <div class="forgotten-password-view">
    <div class="forgotten-password-view__form">
      <title-deck>Mot de passe</title-deck>
      <div class="forgotten-password-view__fields">
        <dc-input
          inputId="email"
          type="email"
          name="email"
          placeholder="Saisissez votre adresse e-mail"
          v-model="$v.email.$model"
          :invalid="$v.email.$error && (!$v.email.required || !$v.email.email)"
        />
        <div
          class="error-message"
          v-if="$v.email.$error && !$v.email.required"
        >
          Vous devez saisir un e-mail
        </div>
        <div
          class="error-message"
          v-if="$v.email.$error && !$v.email.email"
        >
          Vous devez saisir un e-mail valide
        </div>
        <dc-button @click="sendRequest">
          Récupérer mon mot de passe
        </dc-button>
      </div>
    </div>

  </div>
</template>

<script>
import TitleDeck from '@/components/ui/TitleDeck'
import DcButton from '@/components/ui/Button'
import DcInput from '@/components/ui/Input'
import { required, email } from 'vuelidate/lib/validators'
import { askResetPassword } from '@/services/user.service.js'

export default {
  name: 'ForgottenPassword',
  components: {
    TitleDeck,
    DcButton,
    DcInput
  },
  data () {
    return {
      email: ''
    }
  },
  methods: {
    async sendRequest () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      await askResetPassword(this.email)
      this.$notify({
        group: 'notification-group',
        type: 'success',
        duration: 10000,
        title: 'Email envoyé',
        text: 'Un email a été envoyé à votre addresse pour vous permettre de réinitialiser votre mot de passe'
      })
    }
  },
  validations: {
    email: {
      required,
      email
    }
  }
}
</script>

<style lang="scss">
.forgotten-password-view {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: $light-grey-blue;
  background: linear-gradient(90deg, $blue 45%, $light-grey-blue 45%);
  &__fields {
    width: 52.4rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  &__form {
    position: absolute;
    width: 73.4rem;
    padding: 1rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 1rem;
    box-shadow: -7px 12.1px 25.4px 1.6px rgba(0, 0, 0, 0.14);
    background-color: $light;
    padding-top: 12.4rem;
    padding-bottom: 12.4rem;
  }
  .dc-button {
    margin-top: 2.5rem;
  }
}
</style>
