<template>
  <div class="slide-box" :class="{'slide-box--true': chkValue}">
      <input type="checkbox"
            class="slide-box__checkbox"
            :id="id"
            v-model="chkValue"
            :name="id"
						@change="$emit('change')"
            :disabled="disabled"
      />
      <label :for="id"/>
  </div>
</template>

<script>
export default {
  name: 'DcSwicth',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    chkValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  mounted () {
    this.chkValue = this.value
  }
}
</script>

<style lang="scss">
.slide-box {
    position: relative;
    width: 7.5rem;
    height: 3.1rem;
    background-color: $dark-grey;
    border-radius: 999px;
    transition: background-color 300ms ease;
    &--true {
        background-color: $green;
    }
    label {
        color: #ffffff;
        position: absolute;
        width: 2.1rem;
        height: 2.1rem;
        top: 50%;
        transform: translateY(-50%);
        right: 4.8rem;
        background-color: $light;
        cursor: pointer;
        transition: all 300ms ease;
        border-radius: 999px;
        box-shadow: 3.2px 5.1px 8.5px 0.5px rgba(0, 0, 0, 0.08);
    }
    input[type=checkbox] {
        visibility: hidden;
        &:checked + label {
            right: .7rem;
        }
    }
}
</style>
