<template>
  <div class="title-deck">
    <h3 class="title-deck__title">
      <slot></slot>
    </h3>
  </div>
</template>

<script>
export default {
  name: 'TitleDeck'
}
</script>

<style lang="scss">

@mixin card-conf-front {
    width: 25.3rem;
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../../assets/img/title-card-front.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

@mixin card-conf-back {
    width: 25.3rem;
    height: 16rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../../assets/img/title-card-back.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.title-deck {
    position: absolute;
    top: -5.6rem;
    left: -8.3rem;
    @include card-conf-back;
    &::before {
        content: '';
        position: absolute;
        @include card-conf-front;
        transform: rotate(0deg) translateX(-1rem);
    }
    &__title {
        font-size: 2.45rem;
        font-weight: $font-weight-bold;
        letter-spacing: 0.74px;
        text-align: center;
        color: $light;
        transform: rotate(-14deg) translate(-1rem, 1rem);
    }
}
</style>
