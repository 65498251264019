<template>
  <div class="dc-stepper">
    <template v-for="(step, index) in steps">
      <dc-step
        :key="`step-${index}`"
        :step="step"
        @is-active="setActive"
      />
    </template>
  </div>
</template>

<script>
import DcStep from '@/components/ui/Stepper/step.vue'
export default {
  name: 'DcStepper',
  components: {
    DcStep
  },
  props: {
    steps: {
      type: Array,
      required: true
    }
  },
  methods: {
    setActive (index) {
      this.$emit('active-step', index)
    }
  }
}
</script>

<style lang="scss">
.dc-stepper {
  height: 100vh;
  width: $stepper-left;
  background: $blue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
</style>
