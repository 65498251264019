<template>
  <div class="adil-view">
    <div class="adil-view__list">
      <title-deck>Adil</title-deck>
      <dc-table :columns="cols" :rows="rows" rowItemName="permanence">

        <template v-slot:cell-name="{permanence}">
          {{ permanence.name }}
        </template>

        <template v-slot:cell-postalCode="{permanence}">
          {{ permanence.postalCode }}
        </template>

        <template v-slot:cell-city="{permanence}">
          {{ permanence.city }}
        </template>

        <template v-slot:cell-users="{permanence}">
          {{ permanence.nbUsers }}
        </template>
        <template v-slot:cell-edit="{permanence}">
          <dc-button
            @click="editAdil(permanence.id)"
            variant="secondary-small"
          >
            <md-icon>create</md-icon>
          </dc-button>
        </template>
        <template v-slot:footer  v-if="currentUser.role === 'SYSADMIN'">
          <div class="adil-view__table-footer-buttons">
            <dc-button @click="addAdil()" variant="primary-small">Ajouter</dc-button>
          </div>
        </template>
      </dc-table>
      <template v-if="rows">
        <div class="hr hr--no-mb"></div>
        <span class="table-total">
          {{ rows.length }} Total
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DcTable from '@/components/ui/Table'
import TitleDeck from '@/components/ui/TitleDeck'
import DcButton from '@/components/ui/Button'
import { getPlacesByType } from '@/services/place.service.js'

export default {
  name: 'AdilView',
  components: {
    DcTable,
    TitleDeck,
    DcButton
  },
  data () {
    return {
      cols: [
        { name: 'name', label: 'Nom', sortOrder: 'ASC' },
        { name: 'postalCode', label: 'Code postal', sortOrder: 'ASC' },
        { name: 'city', label: 'Ville', sortOrder: 'ASC' },
        { name: 'users', label: 'Inscrit(s)', sortOrder: 'ASC' },
        { name: 'edit', label: '', sortOrder: 'ASC' }
      ],
      rows: []
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'user'
    })
  },
  methods: {
    async refreshList () {
      try {
        const adils = await getPlacesByType('adil')
        this.rows = [...adils]
      } catch (error) {
        // console.error('refreshList()', error)
      }
    },
    addAdil () {
      this.$router.push({ name: 'createAdil' })
    },
    editAdil (id) {
      this.$router.push({
        name: 'editAdil',
        params: {
          id: id
        }
      })
    }
  },
  mounted () {
    this.refreshList()
  }
}
</script>

<style lang="scss" scoped>
.adil-view {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: $light-grey-blue;
  background: linear-gradient(90deg, $blue 38%, $light-grey-blue 38%);
  padding-top: calc(#{$header-height} + 1rem);
  overflow-y: auto;
  &__list {
    position: absolute;
    z-index: 2;
    border-radius: 1rem;
    left: 50%;
    top: 14rem;
    transform: translateX(-50%);
    width: 123.5rem;
    background-color: $light;
    box-shadow: 4.8px 7.6px 12.2px 0.8px rgba(0, 0, 0, 0.07);
    padding: 5rem;
    padding-top: 9rem;
    margin-bottom: 12rem;
  }

  &__table-footer-buttons {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
