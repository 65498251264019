import Vue from 'vue'
import store from '@/store'
import JsPDF from 'jspdf'
import { toPng } from 'html-to-image'
import dayjs from 'dayjs'

let styleDisplays = []
export async function download (elementRef, documentName, excludes, newWindow) {
  store.dispatch('SET_LOADING', true)
  styleDisplays = []
  let openInNewWindow = newWindow
  if (!openInNewWindow) {
    openInNewWindow = false
  }
  const doc = new JsPDF({
    orientation: 'l',
    unit: 'mm',
    format: 'a4'
  })

  if (excludes) {
    excludeElements(excludes, true)
  }

  try {
    const dataUrl = await toPng(elementRef)
    const imgProps = doc.getImageProperties(dataUrl)
    const pdfWidth = doc.internal.pageSize.getWidth()
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
    doc.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight)

    if (openInNewWindow) {
      const string = doc.output('datauristring')
      var link = document.createElement('a')
      link.href = string
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      doc.save(`${documentName}.pdf`)
    }

    Vue.prototype.$notify({
      group: 'notification-group',
      type: 'success',
      title: 'Succès',
      duration: 10000,
      text: 'Votre document est en cours de téléchargement est en cours.'
    })
  } catch (error) {
    Vue.prototype.$notify({
      group: 'notification-group',
      type: 'error',
      title: 'Erreur',
      duration: 10000,
      text: 'Votre demande à échouée, merci d\'essayer à nouveau, plus tard.'
    })
    throw Error('ERROR download pdf : ' + error)
  } finally {
    if (excludes) {
      excludeElements(excludes, false)
    }
    store.dispatch('SET_LOADING', false)
  }
}

function excludeElements (elements, hide) {
  elements.forEach(el => {
    const htmlElements = document.getElementsByClassName(el)
    if (htmlElements) {
      htmlElements.forEach((i, idx) => {
        let display = 'none'
        if (hide) {
          styleDisplays.push(i.style.display)
        } else {
          display = styleDisplays[idx]
        }
        i.style.display = display
      })
    }
  })
}

function createHeaders (keys, colWidth) {
  if (!colWidth) colWidth = 65
  var result = []
  for (var i = 0; i < keys.length; i += 1) {
    result.push({
      id: keys[i],
      name: keys[i],
      prompt: keys[i],
      width: colWidth,
      align: 'center',
      padding: 0
    })
  }
  return result
}

export async function createPlanningPdf (data, date) {
  if (!data) return
  if (!date) return
  try {
    const doc = new JsPDF({
      orientation: 'l',
      unit: 'mm',
      format: 'a4'
    })
    const planningDate = dayjs(date).format('DD/MM/YYYY')

    // Titre du pdf
    let textYPosition = 20
    doc.setFontStyle('bold')
    doc.text(20, textYPosition, `Planning du ${planningDate}`)

    // Liste des juristes
    const dispos = data.appJurists.filter(d => {
      return d.appointments &&
        d.appointments.length > 0 &&
        (d.appointments.findIndex(a => a.user !== null && a.user.id !== a.jurist.id) > -1)
    })

    const tableData = []
    const headers = createHeaders(['Juriste', 'Date', 'Client', 'Thematique'])
    if (dispos) {
      textYPosition += 20
      dispos.forEach(jurist => {
        jurist.appointments.forEach(appointment => {
          if (appointment.user) {
            const startDate = dayjs(appointment.startDate).format('DD/MM/YYYY')
            const startTime = appointment.startTime
            tableData.push({
              Juriste: `${appointment.jurist.firstName} ${appointment.jurist.lastName}`,
              Date: `${startDate} ${startTime}`,
              Client: `${appointment.user.firstName} ${appointment.user.lastName}`,
              Thematique: appointment.thematic.name,
              startDate: startDate,
              startTime: startTime
            })
          }
        })
      })
      tableData.sort(sortAppointment)
      doc.table(20, textYPosition, tableData, headers, { autoSize: false })
    } else {
      doc.setFontStyle('normal')
      textYPosition += 30
      doc.text(20, textYPosition, 'Aucun rendez-vous a cette date')
    }

    doc.save(`planning-${dayjs(date).format('DD-MM-YYYY')}.pdf`)
  } catch (error) {
    throw Error(error)
  }
}

function sortAppointment (a, b) {
  const dateA = a.startDate.split('/')
  const dateB = b.startDate.split('/')
  const timeA = a.startTime
  const timeB = b.startTime
  const dateTimeA = dayjs(`${dateA[2]}-${dateA[1]}-${dateA[0]}T${timeA}:00`)
  const dateTimeB = dayjs(`${dateB[2]}-${dateB[1]}-${dateB[0]}T${timeB}:00`)
  if (dateTimeA.isBefore(dateTimeB)) {
    return -1
  } else {
    return 1
  }
}

export async function exportChartsToPdf (data, elementRef) {
  try {
    const doc = new JsPDF({
      orientation: 'l',
      unit: 'mm',
      format: 'a4'
    })
    const exportDate = dayjs().format('DD/MM/YYYY')

    // Titre du pdf
    let textYPosition = 20
    doc.setFontStyle('bold')
    doc.text(20, textYPosition, `Statistiques du ${exportDate}`)

    // graphique
    textYPosition += 20
    const dataUrl = await toPng(elementRef.$refs.canvas)
    const imgProps = doc.getImageProperties(dataUrl)
    const pdfWidth = doc.internal.pageSize.getWidth()
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
    doc.addImage(dataUrl, 'PNG', 0, textYPosition, pdfWidth, pdfHeight)

    textYPosition += pdfHeight

    const tableData = []
    const colWidth = 57
    const headers = [
      { id: 0, name: 'startDate', prompt: 'Début', width: colWidth, align: 'center', padding: 0 },
      { id: 2, name: 'jurist', prompt: 'Juriste', width: colWidth, align: 'center', padding: 0 },
      { id: 3, name: 'thematic', prompt: 'Thématique', width: colWidth, align: 'center', padding: 0 },
      { id: 4, name: 'place', prompt: 'Lieu', width: colWidth, align: 'center', padding: 0 },
      { id: 5, name: 'user', prompt: 'Client', width: colWidth, align: 'center', padding: 0 },
      { id: 6, name: 'status', prompt: 'Statut', width: colWidth, align: 'center', padding: 0 }
    ]

    if (data) {
      textYPosition += 70
      data.forEach(appointment => {
        if (appointment.user) {
          const startDate = dayjs(appointment.startDate).format('DD/MM/YYYY')
          const startTime = appointment.startTime
          tableData.push({
            startDate: startDate + ' ' + startTime,
            jurist: `${appointment.jurist.firstName} ${appointment.jurist.lastName}`,
            thematic: appointment.thematic ? appointment.thematic.name : '',
            place: appointment.place ? appointment.place.name : '',
            user: `${appointment.user.firstName} ${appointment.user.lastName}`,
            status: appointment.status
          })
        }
      })
      tableData.sort(sortAppointment)
      doc.table(20, textYPosition, tableData, headers, { autoSize: false, printHeaders: true })
    } else {
      doc.setFontStyle('normal')
      textYPosition += 30
      doc.text(20, textYPosition, 'Aucun rendez-vous a cette date')
    }

    doc.save(`export-${dayjs().format('DD-MM-YYYY')}.pdf`)
  } catch (error) {
    throw Error(error)
  }
}
