<template>
  <div class="juristes-view">
    <h1>Juristes</h1>
    <div class="juristes-view__list">
      <template v-for="(juriste, index) in juristes">
        <div
          :key="`juriste-${index}}`"
          class="juristes-view__item"
        >
          <dc-switch
            :id="`switch-${index}`"
            v-model="juristes[index].used"
          />
          <label
            class="juristes-view__text"
            :for="`switch-${index}`"
          >
            {{ juriste.firstName }}
          </label>
        </div>
      </template>
    </div>
    <div class="hr"></div>
    <div class="actions-section">
      <div>
        <template v-if="isAdil">
          <dc-button variant="primary" @click.prevent="next(false)">
            Suivant
          </dc-button>
          <div class="spacer"></div>
        </template>
      </div>
      <div>
        <dc-button
        variant="secondary"
        @click.prevent="next(true)"
        >
        <template v-if="editionPlace !== null">
          Modifier {{ isAdil ? 'l\'Adil' : 'la permanence'}}
        </template>
        <template v-else>
          Créer {{ isAdil ? 'l\'Adil' : 'la permanence'}}
        </template>
        </dc-button>
      </div>
      <div class="spacer"></div>
      <div>
        <dc-button
          variant="warning"
          @click.prevent="cancel"
        >
        Annuler
        </dc-button>
      </div>
    </div>
  </div>
</template>

<script>
import DcSwitch from '@/components/ui/Switch'
import DcButton from '@/components/ui/Button'
import { getUserByRole } from '@/services/user.service.js'

export default {
  name: 'JuristeView',
  components: {
    DcSwitch,
    DcButton
  },
  data () {
    return {
      juristes: []
    }
  },
  props: {
    isAdil: {
      type: Boolean,
      default: false
    },
    editionPlace: {
      type: Array,
      default: null
    }
  },
  methods: {
    next (save) {
      const values = this.juristes
        .filter(t => t.used === true)
        .map(j => {
          return { id: j.id }
        })
      this.$emit('next', {
        model: values,
        save: save
      })
    },
    cancel () {
      if (this.isAdil) {
        this.$router.push({
          name: 'adil'
        })
      } else {
        this.$router.push({
          name: 'permanences'
        })
      }
    },
    loadExistingData () {
      if (this.editionPlace == null || this.editionPlace.length === 0) {
        return
      }

      this.editionPlace.forEach(t => {
        const juristesIdx = this.juristes.findIndex(th => th.id === t.id)
        if (juristesIdx > -1 && this.juristes[juristesIdx] !== 'undefined') {
          this.juristes[juristesIdx].used = true
        }
      })
    }
  },
  async mounted () {
    try {
      const allJurists = await getUserByRole(['JURISTE', 'JURISTESUP'])
      if (allJurists == null) {
        this.$notify({
          group: 'notification-group',
          type: 'error',
          duration: 10000,
          title: 'Erreur lors de la récupération des juristes',
          text: 'Sauvegarder le formulaire risque d\'effacer des données'
        })
      } else {
        this.juristes = allJurists.map(t => {
          t.used = false
          return t
        })
        this.loadExistingData()
      }
    } catch (error) {
      // console.error(error)
    }
  }
}
</script>

<style lang="scss">
.juristes-view {
  &__list {
    margin-top: 5rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;
  }
  &__item {
    display: flex;
    flex-direction: row;
  }
  &__text {
    margin-left: 1rem;
  }
  .actions-section {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    background-color: $light;
    width: 100%;
    .dc-button {
      margin: 1rem;
    }
  }
}
</style>
