export const getters = {
  search: state => state.search,
  isLoading: state => state.isLoading > 0,
  previousRoute: state => state.previousRoute,
  editionPlace: state => state.editionPlace,
  appointmentsHistory: state => state.appointmentsHistory,
  appointmentsNext: state => state.appointmentsNext,
  user: state => state.user,
  token: state => state.token,
  users: state => state.users,
  appointments: state => state.appointments,
  cities: state => state.cities,
  thematics: state => state.thematics,
  currentThematic: state => state.currentThematic,
  adilList: state => state.adilList,
  permanences: state => state.permanances,
  menuItems: state => {
    if (!state.menuItems) return null

    return state.menuItems.filter(m => {
      let access = false
      const roles = m.roles

      if (!roles || roles.length === 0) access = true
      else if (state.user && roles && roles.includes(state.user.role)) access = true

      return access
    })
  },
  banner: state => state.banner
}
