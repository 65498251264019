import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import HomeConnected from '../views/HomeConnected.vue'
import Login from '../views/Login.vue'
import ForgottenPassword from '../views/ForgottenPassword.vue'
import Logout from '../views/Logout.vue'
import Subscribe from '../views/Subscribe.vue'
import Adil from '../views/Admin/Adil'
import Appointments from '../views/Admin/Appointments'
import Permanences from '../views/Admin/Permanences'
import PermanenceAdd from '../views/Admin/Permanences/add.vue'
import Thematics from '../views/Admin/Thematics'
import Rtt from '../views/Admin/Rtt'
import Planning from '../views/Admin/Planning'
import Users from '../views/Admin/Users'
import UsersSearch from '../views/Admin/Users/search.vue'
import ChangePassword from '../views/ChangePassword.vue'
import ActivateAccount from '../views/ActivateAccount.vue'
import SubscriptionSuccess from '../views/SubscriptionSuccess.vue'
import ChartsView from '../views/Admin/Charts'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/home-connected',
    name: 'home-connected',
    component: HomeConnected
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/forgotten-password',
    name: 'forgottenPassword',
    component: ForgottenPassword
  },
  {
    path: '/change-password',
    name: 'changePassword',
    component: ChangePassword
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    component: Subscribe
  },
  {
    path: '/activate/:token',
    name: 'activate',
    component: ActivateAccount
  },
  {
    path: '/subscribe/:userId',
    name: 'editAccount',
    component: Subscribe
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout
  },
  {
    path: '/charts',
    name: 'charts',
    component: ChartsView
  },
  {
    path: '/adil',
    name: 'adil',
    component: Adil
  },
  {
    path: '/adil/create',
    name: 'createAdil',
    component: PermanenceAdd,
    props: { isAdil: true }
  },
  {
    path: '/adil/edit/:id',
    name: 'editAdil',
    component: PermanenceAdd,
    props: { isAdil: true }
  },
  {
    path: '/planning',
    name: 'planning',
    component: Planning
  },
  {
    path: '/appointments',
    name: 'appointments',
    component: Appointments
  },
  {
    path: '/permanences',
    name: 'permanences',
    component: Permanences
  },
  {
    path: '/permanences/create',
    name: 'createPermanence',
    component: PermanenceAdd
  },
  {
    path: '/permanences/edit/:id',
    name: 'editpermanences',
    component: PermanenceAdd
  },
  {
    path: '/thematics',
    name: 'thematics',
    component: Thematics
  },
  {
    path: '/users-search',
    name: 'usersSearch',
    component: UsersSearch
  },
  {
    path: '/users',
    name: 'users',
    component: Users
  },
  {
    path: '/subscription-success',
    name: 'subscriptionSuccess',
    component: SubscriptionSuccess
  },
  {
    path: '/rtt',
    name: 'rtt',
    component: Rtt
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// liste des noms de routes non connectées
const whiteList = ['activate', 'login', 'forgottenPassword', 'home', 'subscribe', 'appointments', 'changePassword', 'subscriptionSuccess']

router.beforeEach((to, from, next) => {
  const store = require('@/store')
  if (from && from.name &&
    from.name !== 'login' &&
    from.name !== 'subscribe' &&
    from.name !== 'changePassword' &&
    from.name !== 'forgottenPassword' &&
    from.name !== 'activate' &&
    from.name !== 'subscriptionSuccess') {
    store.default.dispatch('SET_PREVIOUS_ROUTE', {
      name: from.name,
      params: from.params
    })
  }

  if (!store.default.state.user) {
    const userStorage = sessionStorage.getItem('currentUser')
    if (userStorage != null && userStorage !== 'undefined') {
      store.default.dispatch('SET_USER', JSON.parse(userStorage))
    }
  }

  if (to.name === 'home' && store.default.state.user) {
    if (store.default.state.user.role === 'CLIENT') {
      next({ name: 'home-connected' })
    } else {
      next({ name: 'planning' })
    }
  } else if (whiteList.includes(to.name) || store.default.state.user) {
    next()
  } else {
    next({ name: 'home' })
  }
})

export default router
