<template>
  <div class="subscribe-view">
    <div class="subscribe-view__form">
      <title-deck>Inscription</title-deck>
      <div class="row">
        <div class="col-5">
          <label for="firstname">Prénom</label>
          <dc-input
            inputId="firstname"
            v-model.trim="$v.firstname.$model"
            :invalid="$v.firstname.$error"
          />
          <div
            class="error-message"
            v-if="$v.firstname.$error && !$v.firstname.required"
          >
            Votre prénom est obligatoire
          </div>
        </div>
        <div class="col-2"></div>
        <div class="col-5">
          <label for="lastname">Nom</label>
          <dc-input
            inputId="lastname"
            v-model.trim="$v.lastname.$model"
            :invalid="$v.lastname.$error"
          />
          <div
            class="error-message"
            v-if="$v.lastname.$error && !$v.lastname.required"
          >
            Votre nom est obligatoire
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <label for="phone">Téléphone mobile</label>
          <dc-input
            inputId="phone"
            type="tel"
            v-model.trim="$v.phone.$model"
            :invalid="$v.phone.$error"
          />
          <div
            class="error-message"
            v-if="$v.phone.$error && !$v.phone.required"
          >
            Votre numéro de téléphone mobile est obligatoire
          </div>
          <div
            class="error-message"
            v-if="$v.phone.$error && !$v.phone.phoneValidator"
          >
            Ceci n'est pas un numéro de téléphone valide
          </div>
        </div>
        <div class="col-2"></div>
        <div class="col-5">
          <label for="email">E-mail</label>
          <dc-input
            inputId="email"
            type="email"
            v-model.trim="$v.email.$model"
            :invalid="$v.email.$error"
          />
          <div
            class="error-message"
            v-if="$v.email.$error && !$v.email.required"
          >
            Votre adresse e-mail est obligatoire
          </div>
          <div
            class="error-message"
            v-if="$v.email.$error && !$v.email.email"
          >
            Ceci n'est pas un e-mail valide
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <label for="address1">Adresse</label>
          <dc-input
            inputId="address1"
            v-model.trim="$v.address1.$model"
            :invalid="$v.address1.$error && !$v.address1.required"
          />
          <div
            class="error-message"
            v-if="$v.address1.$error && !$v.address1.required"
          >
            Votre adresse est obligatoire
          </div>
        </div>
        <div class="col-2"></div>
        <div class="col-5">
          <label for="address2">Complément d'adresse</label>
          <dc-input
            inputId="address2"
            v-model.trim="$v.address2.$model"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <label for="address3">Complément d'adresse 2</label>
          <dc-input
            inputId="address3"
            v-model.trim="$v.address3.$model"
          />
        </div>
        <div class="col-2"></div>
        <div class="col-5">
          <label for="city">Ville</label>
          <dc-input
            inputId="city"
            v-model.trim="$v.city.$model"
            :invalid="$v.city.$error && !$v.city.required"
          />
          <div
            class="error-message"
            v-if="$v.city.$error && !$v.city.required"
          >
            Votre ville est obligatoire
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <label for="postalCode">Code postal</label>
          <dc-input
            inputId="postalCode"
            v-model.trim="$v.postalCode.$model"
            :invalid="$v.postalCode.$error && !$v.postalCode.required"
          />
          <div
            class="error-message"
            v-if="$v.postalCode.$error && !$v.postalCode.required"
          >
            Votre code postal est obligatoire
          </div>
        </div>
        <div class="col-7"></div>
      </div>
      <div class="row" v-if="!userId">
        <div class="col-5">
          <label for="password">Mot de passe</label>
          <dc-input
            type="password"
            inputId="password"
            v-model.trim="$v.password.$model"
          />
          <div
            class="error-message"
            v-if="$v.password.$error && !$v.password.required"
          >
            Votre mot de passe est obligatoire
          </div>
          <div
            class="error-message"
            v-if="$v.password.$error && !$v.password.password"
          >
            Vos mots de passe sont différents
          </div>
          <div
            class="error-message"
            v-if="$v.password.$dirty && !passwordStrengthValid && isOnSave"
          >
            Votre mot de passe n'est pas assez complexe
          </div>
          <dc-password-strength
            :password-to-check="$v.password.$model"
            @password-strength="setStrength"
          />
        </div>
        <div class="col-2"></div>
        <div class="col-5">
          <label for="password2">Confirmation</label>
          <dc-input
            type="password"
            inputId="password2"
            v-model.trim="$v.password2.$model"
          />
          <div
            class="error-message"
            v-if="$v.password2.$error && !$v.password2.password"
          >
            Vos mots de passe sont différents
          </div>
        </div>
      </div>
      <div class="row" v-if="errorMessage !== ''">
        <div class="col-12 error-message">
          {{ errorMessage }}
        </div>
      </div>
      <div class="row">
        <div class="'col-12'">
          <dc-button @click="register">
            <span v-if="userId">
              Modifier mon compte
            </span>
            <span v-else>
              Créer mon compte
            </span>
          </dc-button>
        </div>
        <div class="col-12"></div>
        <div class="col-12" v-if="userId">
          <dc-button
            variant="warning"
            @click="desactivate"
          >
            <span>
              Désactiver mon compte
            </span>
          </dc-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { password } from '@/validators/password.js'
import TitleDeck from '@/components/ui/TitleDeck'
import DcButton from '@/components/ui/Button'
import DcInput from '@/components/ui/Input'
import DcPasswordStrength from '@/components/ui/PasswordStrength'
import { phoneValidator } from '@/validators/phone.js'
import { required, email, requiredIf } from 'vuelidate/lib/validators'
import { register, update, desactivate, logout } from '@/services/user.service.js'
import { mapGetters } from 'vuex'

export default {
  name: 'SubscribeView',
  components: {
    TitleDeck,
    DcButton,
    DcInput,
    DcPasswordStrength
  },
  data () {
    return {
      userId: null,
      isOnSave: false,
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      address1: '',
      address2: '',
      address3: '',
      city: '',
      postalCode: '',
      password: '',
      password2: '',
      passwordStrengthValid: 0,
      errorMessage: ''
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'user'
    })
  },
  methods: {
    async desactivate () {
      if (confirm('Vous allez désactiver votre compte. Étes-vous certain de vouloir réaliser cette action ? Vous ne pourrez plus vous connecter avec les identifiants qui y sont associés.')) {
        try {
          await desactivate()
        } catch (error) {
          // console.error(error)
        }

        try {
          await logout()
          this.$router.push({ name: 'home' })
        } catch (error) {
          // console.error(error)
        }
      }
    },
    setStrength (value) {
      this.passwordStrengthValid = value
    },
    async register () {
      this.isOnSave = true
      this.$v.$touch()
      if (this.$v.$invalid || (!this.passwordStrengthValid && !this.userId)) {
        return
      }

      const newUser = {
        id: this.userId ? +this.userId : null,
        firstName: this.firstname,
        lastName: this.lastname,
        mobile: this.phone,
        email: this.email,
        address1: this.address1,
        address2: this.address2,
        address3: this.address3,
        city: this.city,
        postalCode: this.postalCode,
        password: this.password,
        role: this.currentUser ? this.currentUser.role : 'USER'
      }

      try {
        if (this.userId) {
          await update(newUser)
        } else {
          await register(newUser)
        }

        if (!this.userId) {
          this.$router.push({
            name: 'subscriptionSuccess'
          })
        }
      } catch (error) {
        this.errorMessage = error
        // console.error(error)
      }
    }
  },
  validations: {
    firstname: {
      required
    },
    lastname: {
      required
    },
    phone: {
      required,
      phoneValidator
    },
    email: {
      required,
      email
    },
    address1: {
      required
    },
    address2: {},
    address3: {},
    city: {
      required
    },
    postalCode: {
      required
    },
    password: {
      required: requiredIf(function () {
        return !this.userId
      }),
      password
    },
    password2: {
      required: requiredIf(function () {
        return !this.userId
      }),
      password
    }
  },
  mounted () {
    this.userId = this.$route.params.userId
    if (this.userId && this.currentUser) {
      this.firstname = this.currentUser.firstName
      this.lastname = this.currentUser.lastName
      this.phone = this.currentUser.mobile
      this.email = this.currentUser.email
      this.address1 = this.currentUser.address1
      this.address2 = this.currentUser.address2
      this.address3 = this.currentUser.address3
      this.city = this.currentUser.city
      this.postalCode = this.currentUser.postalCode
    }
  }
}
</script>

<style lang="scss">
.subscribe-view {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background: $light-grey-blue;
  background: linear-gradient(90deg, $blue 45%, $light-grey-blue 45%);
  &__error {
    color: $red;
    font-size: 1.1rem;
    font-weight: $font-weight-bold;
  }
  &__form {
    position: absolute;
    width: 73.4rem;
    margin-top: calc(#{$header-height} * 2);
    margin-bottom: calc(#{$header-height} * 2);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 1rem;
    box-shadow: -7px 12.1px 25.4px 1.6px rgba(0, 0, 0, 0.14);
    background-color: $light;
    padding: 12.4rem 9rem 0 9rem;
  }
}
</style>
