<template>
  <div class="dc-input" :class="{'dc-input--small': small}">
    <div class="dc-input__field" :class="{'dc-input__field--color': type === 'color','dc-input__field--small': small, 'dc-input__field--error': invalid, 'no-padding-right': !appendIcon, 'no-padding-left': !prependIcon}">
        <template v-if="prependIconCustom">
          <inline-svg
            :src="require(`@/assets/img/icons/${prependIcon}.svg`)"
          />
        </template>
        <template v-else-if="!prependIconCustom && prependIcon">
          <md-icon class="md-icon__prepend">{{ prependIcon }}</md-icon>
        </template>
        <input
          :id="inputId"
          v-bind="$attrs"
          :value="value"
          @input="update"
          @focus="$emit('focus', $event.target.value)"
          @blur="$emit('blur')"
          @keyup.down="goToList"
          @keyup.enter="$emit('enter')"
          :class="{'small': small}"
          :type="type"
        >
        <md-icon
          v-if="appendIcon"
          class="md-icon__append"
          :id="`icon-${inputId}`"
        >
          {{ appendIcon }}
        </md-icon>
    </div>
    <div
      v-if="innerAutoCompleteValues !== null && innerAutoCompleteValues.length > 0"
      v-click-outside="hideAutoComplete"
      @keyup.esc="hideAutoComplete"
      tabindex="0"
      class="dc-input__auto-complete"
    >
      <template v-for="(result, index) in autoCompleteValues">
        <div
          tabindex="0"
          class="dc-input__auto-complete-item"
          :key="`city-${index}`"
          @keyup.enter="selectItem(result)"
          @keyup.up="goToPrev"
          @keyup.down="goToNext"
          @click="selectItem(result)"
        >
          {{ result.label }}
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import ClickOutside from 'vue-click-outside'

export default {
  name: 'DcInput',
  directives: {
    ClickOutside
  },
  data () {
    return {
      innerAutoCompleteValues: null,
      currentIndex: 0,
      allowClickOutside: false
    }
  },
  props: {
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: String,
      default: null
    },
    inputId: {
      type: String,
      required: true
    },
    invalid: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    prependIcon: {
      type: String,
      required: false,
      default: null
    },
    prependIconCustom: {
      type: Boolean,
      default: false
    },
    appendIcon: {
      type: String,
      required: false,
      default: null
    },
    autoCompleteValues: {
      type: Array,
      default: null,
      required: false
    }
  },
  watch: {
    autoCompleteValues (value) {
      this.allowClickOutside = false
      this.innerAutoCompleteValues = value
      setTimeout(() => {
        this.allowClickOutside = true
      }, 100)
    }
  },
  methods: {
    iconClick () {
      this.$emit('icon-click')
    },
    goToList (event) {
      if (this.innerAutoCompleteValues) {
        const el = document.getElementsByClassName('dc-input__auto-complete-item')
        if (el) {
          el[this.currentIndex].focus()
        }
      }
    },
    goToNext (event) {
      if (this.innerAutoCompleteValues) {
        const el = document.getElementsByClassName('dc-input__auto-complete-item')
        if (el && this.currentIndex < el.length - 1) {
          el[++this.currentIndex].focus()
        }
      }
    },
    goToPrev (event) {
      if (this.innerAutoCompleteValues) {
        const el = document.getElementsByClassName('dc-input__auto-complete-item')
        if (el && this.currentIndex > 0) {
          el[--this.currentIndex].focus()
        }
      }
    },
    selectItem (result) {
      this.hideAutoComplete()
      this.$emit('value-selected', result)
    },
    hideAutoComplete () {
      if (this.allowClickOutside) {
        this.innerAutoCompleteValues = null
      }
    },
    update (event) {
      this.$emit('input', event.target.value)
    }
  },
  mounted () {
    const el = document.getElementById(`icon-${this.inputId}`)
    if (el) {
      el.addEventListener('click', () => this.iconClick())
    }
  }
}
</script>
<style lang="scss">
.dc-input {
  position: relative;
  width: 100%;
  height: $input-height-regular;
  background-color: transparent;
  &--small {
    height: calc( #{$input-height-regular} / 1.5);
  }
  &__field {
    width: 100%;
    height: $input-height-regular;
    border: solid .2rem $dark-grey;
    border-radius: 1rem;
    overflow-y: hidden;
    padding-left: 4.6rem;
    padding-right: 4.6rem;
    &:hover, &:focus-within {
      border: solid 2px lighten($dark-grey, 12%);
    }
    &--color {
      padding-left: 0 !important;
      padding-right: 0 !important;
      border: 0;
      border-radius: 0;
      overflow-y: hidden;
      &:hover, &:focus-within {
        border: 0;
      }
    }
    &--error {
      border: solid 2px $red;
    }
    &.no-padding-left {
      padding-left: .4rem;
    }
    &.no-padding-right {
      padding-right: .4rem;
    }
    svg {
      position: absolute;
      height: 2.2rem;
      font-size: 2rem !important;
      top: 50%;
      transform: translateY(-50%);
      fill: $blue !important;
      left: 2rem;
    }
    &--small {
      background-color: transparent;
      height: calc( #{$input-height-regular} / 1.5);
    }
  }
  &__auto-complete {
    position: absolute;
    width: 100%;
    max-height: 25rem;
    overflow-y: auto;
    z-index: 4;
    background-color: $light;
    border: 1px solid $blue;
    border-radius: 1rem;
    padding: 1rem;
    margin-top: .5rem;
		box-shadow: 3.2px 5.1px 6.6px 0.4px rgba(0, 0, 0, 0.21);
  }
  &__auto-complete-item {
    cursor: pointer;
    margin: .8rem 0 .8rem 0;
    font-size: 1.5rem;
    padding: .5rem;
    &:hover, &:focus {
      background-color: $dark-grey;
    }
  }
  input {
    width: 100%;
    height: $input-height-regular;
    border: 0;
    font-size: 2rem;
    padding-left: 1rem;
    &::placeholder {
      font-size: 2rem;
      color: $light-grey-3;
    }
    &.small {
      background-color: transparent;
      height: calc( #{$input-height-regular} / 1.5);
    }
  }
  input[type="color"] {
    width: 100%;
    border: 0;
    padding: 0;
  }
  .md-icon{
    position: absolute;
    width: 2rem;
    height: 2rem;
    font-size: 2rem !important;
    top: 50%;
    transform: translateY(-50%);
    color: $blue !important;
    &__prepend {
        left: 2rem;
    }
    &__append {
        cursor: pointer;
        right: 2rem;
    }
  }
}
</style>
