<template>
  <div class="users-view">
    <div class="users-view__list">
      <title-deck>Utilisateurs</title-deck>

      <dc-table :isExpendable="true" :columns="cols" :rows="rows" rowItemName="utilisateur">

        <template v-slot:cell-button-expend="{ utilisateur }">
          <div
            class="btn-expend"
            :class="{'btn-expend--open': openId === utilisateur.id}"
            :id="`btn-expend-${utilisateur.id}`"
            @click="expend(utilisateur)"
          >
            <md-icon>navigate_before</md-icon>
          </div>
        </template>

        <template v-slot:cell-button-new="{ utilisateur }">
          <dc-button
            @click="onSelect(utilisateur)"
            variant="secondary-small"
          >
            <inline-svg class="add-button" :src="require('@/assets/img/icons/ICONE-AJOUTER.svg')"></inline-svg>
          </dc-button>
        </template>

        <template v-slot:row-expend="{ utilisateur }">
          <tr class="table-expend-wrapper" :id="`table-expend-wrapper-${utilisateur.id}`" style="display: none;">
            <td :colspan="cols.length">
              <table class="table-expend">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Heure</th>
                    <th>Lieu</th>
                    <th>Juriste</th>
                    <th>Statut</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(appointment) in utilisateur.appointments" :key="`expend-${appointment.id}`">
                    <td>{{ appointment.startDate | date }}</td>
                    <td>{{ appointment.startTime }}</td>
                    <td>{{ appointment.place.name }}</td>
                    <td>{{ appointment.jurist.firstName + ' ' + appointment.jurist.lastName }}</td>
                    <td>{{ appointment.status }}</td>
                    <td>
                      <dc-button
                        @click="cancelAppointment(appointment.id)"
                        variant="secondary-small"
                      >
                        <inline-svg class="add-button" :src="require('@/assets/img/icons/ICONE-ANNULER.svg')"></inline-svg>
                      </dc-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </template>

      </dc-table>
      <template v-if="rows">
        <div class="hr hr--no-mb"></div>
        <span class="table-total">
          {{ rows.length }} Total
        </span>
      </template>
    </div>

    <dc-popin
      ref="appointmentEditFormPopin"
      :showButtons="false"
      :show="showPopin"
      @on-close="closePopin"
      :title="selectedItem && selectedItem.extendedProps && selectedItem.extendedProps.appointmentId ? 'Modification du rendez-vous' : 'Création d\'un rendez-vous'"
    >
      <appointment-create-form
        @save="onSave"
        @error="onError"
        @cancel="closePopin"
        @close="closePopin"
        :appointment-data="selectedItem"
      />
    </dc-popin>
  </div>
</template>

<script>
import DcTable from '@/components/ui/Table'
import { userQuery } from '@/services/user.service.js'
import TitleDeck from '@/components/ui/TitleDeck'
import DcButton from '@/components/ui/Button'
import DcPopin from '@/components/ui/Popin'
import AppointmentCreateForm from '@/components/appointments/appointment-create-form.vue'
import { mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
import { cancelAppointment } from '@/services/appointment.service.js'

export default {
  name: 'UsersSearchView',
  components: {
    TitleDeck,
    DcButton,
    DcPopin,
    AppointmentCreateForm,
    DcTable
  },
  data () {
    return {
      showPopin: false,
      rows: [],
      shallowRows: null,
      users: null,
      cols: [
        { name: 'button-expend', label: '', sortOrder: 'ASC' },
        { name: 'firstName', label: 'Nom', sortOrder: 'ASC' },
        { name: 'lastName', label: 'Prénom', sortOrder: 'ASC' },
        { name: 'mobile', label: 'Téléphone', sortOrder: 'ASC' },
        { name: 'email', label: 'E-mail', sortOrder: 'ASC' },
        { name: 'button-new', label: '', sortOrder: 'ASC' }
      ],
      selectedCol: null,
      itemByPage: 10,
      pages: [],
      selectedPage: 1,
      selectedItem: {},
      openId: null
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'user',
      search: 'search'
    })
  },
  watch: {
    search () {
      this.refreshList()
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'SET_LOADING'
    }),
    expend (user) {
      const id = user.id
      const el = document.getElementById(`table-expend-wrapper-${id}`)
      const elements = document.getElementsByClassName('table-expend-wrapper')
      if (elements) {
        elements.forEach(e => {
          e.style.display = 'none'
        })
      }

      if (this.openId !== id) {
        this.openId = id
        if (el) {
          el.style.display = 'table-row'
        }
      } else {
        this.openId = null
      }
    },
    onSave () {
      this.refreshList()
      this.closePopin()
      this.$notify({
        group: 'notification-group',
        type: 'success',
        title: 'L\'enregistrement a réussi',
        text: 'Les informations de l\'utilisateur sont enregistrées'
      })
    },
    onError (value) {
      this.closePopin()
    },
    closePopin () {
      this.showPopin = false
    },
    onSelect (utilisateur) {
      this.selectedItem = utilisateur
      this.showPopin = true
    },
    addUser () {
      if (this.currentUser.role !== 'CLIENT') {
        this.selectedItem = Object.assign({}, null)
        this.showPopin = true
      }
    },
    async refreshList () {
      this.setLoading(true)
      const query = this.search ? this.search.toLowerCase() : null
      let userList = null
      try {
        userList = await userQuery(query)
        this.users = userList
        this.rows = [...this.users]
        this.rows.forEach(r => {
          r.appointments.sort((a, b) => {
            return this.compareDate(a, b)
          })
        })
        this.goToPage(this.selectedPage)
        this.getPages()
      } catch (error) {
        // console.error('refreshList()', error)
      } finally {
        this.setLoading(false)
      }
    },
    async cancelAppointment (id) {
      try {
        if (window.confirm('Vous allez annuler ce rendez-vous')) {
          await cancelAppointment(id)
        }
        this.refreshList()
      } catch (error) {
        // console.error(error)
      }
    },
    compareDate (a, b) {
      const objA = dayjs(a.startDate + 'T' + a.startTime)
      const objB = dayjs(b.startDate + 'T' + b.startTime)

      let comparison = 0
      if (objB.isBefore(objA)) {
        comparison = -1
      } else if (objA.isBefore(objB)) {
        comparison = 1
      }
      return comparison
    },
    goToPage (page) {
      this.selectedPage = page
      this.shallowRows = this.rows.slice(((page - 1) * this.itemByPage), page * this.itemByPage)
    },
    getPages () {
      this.pages = []
      const nb = Math.ceil(this.rows.length / this.itemByPage)
      for (let index = 0; index < nb; index++) {
        this.pages.push(index + 1)
      }
    },
    sort (currentCol) {
      this.selectedCol = currentCol
      this.selectedCol.sortOrder = this.selectedCol.sortOrder === 'ASC' ? 'DESC' : 'ASC'
      this.rows.sort((a, b) => {
        return this.compare(a, b)
      })
      this.selectedPage = 1
      this.goToPage(this.selectedPage)
    },
    compare (a, b) {
      const objA = a[this.selectedCol.field].toUpperCase()
      const objB = b[this.selectedCol.field].toUpperCase()

      let comparison = 0
      if (objA > objB) {
        comparison = 1
      } else if (objA < objB) {
        comparison = -1
      }
      return this.selectedCol.sortOrder === 'ASC' ? comparison : (comparison * -1)
    }
  },
  mounted () {
    this.selectedCol = this.cols[0]
    this.refreshList()
  }
}
</script>

<style lang="scss" scoped>
.users-view {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: $light-grey-blue;
  background: linear-gradient(90deg, $blue 38%, $light-grey-blue 38%);
  padding-top: calc(#{$header-height} + 1rem);
  overflow-y: auto;
  .btn-expend {
    cursor: pointer;
    width: 2.2rem;
    &:hover {
      text-shadow: 1px 1px 5px #616161;
    }
    i {
      transform: rotate(90deg);
    }
    &--open {
      i {
        transform: rotate(-90deg);
      }
    }
  }
  .table-expend-wrapper {
    height: 0 !important;
    td {
      padding: 0 !important;
    }
    .table-expend {
      width: 90%;
      margin: auto;
      tr {
        th {
          color: $light-grey-3;
        }
      }
    }
  }
  .add-button {
    fill: $light;
    stroke: $light;
    height: 2rem;
  }
  &__buttons {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	&__list {
		position: absolute;
		z-index: 2;
		border-radius: 1rem;
		left: 50%;
		top: 14rem;
		transform: translateX(-50%);
		width: 123.5rem;
		background-color: $light;
		box-shadow: 4.8px 7.6px 12.2px 0.8px rgba(0, 0, 0, 0.07);
		padding: 5rem;
		padding-top: 9rem;
    margin-bottom: 12rem;
	}
	&__list-table {
		width: 100%;
	}
	&__table-head {
		font-size: 1.8rem;
		font-weight: $font-weight-bold;
		letter-spacing: 1.35px;
		text-align: left;
		color: $light-grey-3;
		th {
			height: $table-row-height;
			cursor: pointer;
			&:hover {
				color: lighten( $light-grey-3, 15%);
			}
		}
	}
	&__table-body {
		font-size: 1.8rem;
		font-weight: $font-weight-bold;
		letter-spacing: 1.35px;
		text-align: left;
		color: $dark;
		tr {
			height: $table-row-height;
			cursor: pointer;
			&:hover {
				background-color: darken( $light, 10%);
			}
		}
	}
	&__pagination {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 3rem 0;
	}
	&__page {
		width: 30px;
		height: 30px;
		font-size: 1.5rem;
		border-radius: 10px;
		text-align: center;
		line-height: 28px;
		margin: 0 .5rem;
		cursor: pointer;
		background-color: $light;
		box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
		&--current {
			background-color: darken($light, 12%);
			box-shadow: none;
			cursor: default;
		}
		&:hover {
			background-color: darken($light, 10%);
		}

	}
	.expand-icon {
		width: 100%;
		text-align: center;
	}
}
</style>
