<template>
  <div class="thematics-view">

    <div class="thematics-view__list">
      <title-deck>Thématiques</title-deck>
      <dc-table :columns="cols" :rows="rows" rowItemName="thematique">

        <template v-slot:cell-color="{thematique}">
          <div class="thematics-view__list__color-square" v-bind:style="{'background-color':thematique.color}"></div>
        </template>

        <template v-slot:cell-code="{thematique}">
          <div>
            {{ thematique.code ? 'Oui' : 'Non' }}
          </div>
        </template>

        <template v-slot:cell-active="{thematique}">
          <dc-switch :id="`active-${thematique.id}`" :value="thematique.active" @change="toggleActivation(thematique.id)"></dc-switch>
        </template>

        <template v-slot:cell-button-edit="{thematique}">
          <dc-button @click="editThematique(thematique)" variant="secondary-small">
            <md-icon>create</md-icon>
          </dc-button>
        </template>

        <template v-slot:cell-button-delete="{thematique}">
          <dc-button @click="deleteId=thematique.id;showPrompt = true;" variant="secondary-small">
            <inline-svg class="delete-icon-inline" :src="require('@/assets/img/icons/ICONE-ANNULER.svg')"></inline-svg>
          </dc-button>
        </template>

        <template v-slot:header>
          <div class="thematics-view__table-footer-buttons">
            <dc-button @click="addThematique()" variant="primary-small">
              Ajouter
            </dc-button>
          </div>
        </template>
      </dc-table>
      <template v-if="rows">
        <div class="hr hr--no-mb"></div>
        <span class="table-total">
          {{ rows.length }} Total
        </span>
      </template>
    </div>
    <md-dialog-confirm
      :md-active.sync="showPrompt"
      md-title="Voulez-vous vraiment supprimer cette thématique ?"
      md-confirm-text="Je confirme"
      md-cancel-text="J'annule"
      @md-cancel="onCancel"
      @md-confirm="onConfirm" />
    <dc-popin
      ref="thematiqueEditFormPopin"
      :showButtons="false"
      :show="showPopin"
      @on-close="closePopin"
      :title="editedThematic == null ? 'Ajout d\'une thématique' : 'Modification de la thématique'"
    >
      <thematic-edit-form
        @save="onSave"
        @cancel="closePopin"
        :thematic-data="editedThematic"
      />
    </dc-popin>

  </div>
</template>

<script>
import DcTable from '@/components/ui/Table'
import TitleDeck from '@/components/ui/TitleDeck'
import DcPopin from '@/components/ui/Popin'
import DcButton from '@/components/ui/Button'
import DcSwitch from '@/components/ui/Switch'
import ThematicEditForm from '@/components/thematics/edit-form'

import { getAllThematics, toggleThematicActivation, deleteThematic } from '@/services/thematic.service.js'

export default {
  name: 'ThematicsView',
  components: {
    DcTable,
    DcPopin,
    TitleDeck,
    DcButton,
    ThematicEditForm,
    DcSwitch
  },
  data () {
    return {
      cols: [
        { name: 'name', label: 'Nom', sortOrder: 'ASC' },
        { name: 'color', label: 'Couleur', sortOrder: 'ASC' },
        { name: 'duration', label: 'Durée', sortOrder: 'ASC' },
        { name: 'code', label: 'Code dossier', sortOrder: 'ASC' },
        { name: 'active', label: 'Active', sortOrder: 'ASC' },
        { name: 'button-edit', label: '', sortOrder: 'ASC' },
        { name: 'button-delete', label: '', sortOrder: 'ASC' }
      ],
      rows: [],
      showPopin: false,
      showPrompt: false,
      deleteId: null,
      editedThematic: null
    }
  },
  methods: {
    async refreshList () {
      try {
        const thematiques = await getAllThematics()
        this.rows = [...thematiques]
      } catch (error) {
        // console.error('refreshList()', error)
      }
    },
    addThematique () {
      this.showPopin = true
    },
    editThematique (thematic) {
      this.editedThematic = thematic
      this.showPopin = true
    },
    onCancel () {
      this.showPrompt = false
    },
    async onConfirm () {
      this.showPrompt = false
      try {
        await deleteThematic(this.deleteId)
        await this.refreshList()
      } catch (error) {
        // console.error('ERROR cancelAppointment', error)
      }
    },
    closePopin () {
      this.showPopin = false
      this.editedThematic = null
    },
    onSave () {
      this.closePopin()
      this.refreshList()
      this.$notify({
        group: 'notification-group',
        type: 'success',
        duration: 10000,
        title: 'L\'enregistrement a réussi',
        text: 'Les informations de la thématique sont enregistrées'
      })
    },
    async toggleActivation (thematicId) {
      await toggleThematicActivation(thematicId)
      this.refreshList()
    }
  },
  mounted () {
    this.refreshList()
  }
}
</script>

<style lang="scss" scoped>
.thematics-view {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: $light-grey-blue;
  background: linear-gradient(90deg, $blue 38%, $light-grey-blue 38%);
  padding-top: calc(#{$header-height} + 1rem);
  overflow-y: auto;
  &__list {
    position: absolute;
    z-index: 2;
    border-radius: 1rem;
    left: 50%;
    top: 14rem;
    transform: translateX(-50%);
    width: 123.5rem;
    background-color: $light;
    box-shadow: 4.8px 7.6px 12.2px 0.8px rgba(0, 0, 0, 0.07);
    padding: 5rem;
    padding-top: 9rem;
    margin-bottom: 12rem;

    &__color-square {
      width: 2.8rem;
      height: 2.8rem;
      border-radius: 1rem;
    }

    .md-icon {
      color: $light !important;
    }
  }

  svg {
    stroke: #ffffff !important;
  }

  &__table-footer-buttons {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
