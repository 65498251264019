<template>
  <div class="horaires-view">
    <h1 class="horaires-view_title">Horaires</h1>
    <div class="container">
      <template v-for="(day, index) in days">
      <div
        class="horaires-view__day"
        :key="`day-${index}`"
      >
        <div class="horaires-view__label">
          <dc-switch
            :id="`switch-${day.label}-${index}`"
            v-model="day.active"
            @change="activeDay(day)"
          />
          <label
            class="horaires-view__text"
            :for="`switch-${day.label}-${index}`"
          >
            {{ day.label }}
          </label>
        </div>
        <div class="horaires-view__times">
          <div class="horaires-view__times-am">
            <dc-select
              :selectId="`select-am-${day.label}-1-${index}`"
              :options="timesStart"
              placeholder="De"
              v-model="day.am.start"
              :disabled="!day.active"
            />
            <dc-select
              :selectId="`select-am-${day.label}-2-${index}`"
              :options="timesStart"
              placeholder="À"
              v-model="day.am.end"
              :disabled="!day.active"
            />
          </div>
          <div class="horaires-view__times-pm">
            <dc-select
              :selectId="`select-pm-${day.label}-1-${index}`"
              :options="timesEnd"
              placeholder="Et de"
              v-model="day.pm.start"
              :disabled="!day.active"
            />
            <dc-select
              :selectId="`select-pm-${day.label}-2-${index}`"
              :options="timesEnd"
              placeholder="À"
              v-model="day.pm.end"
              :disabled="!day.active"
            />
          </div>
          <div class="horaires-view__times-chk">
            <template v-for="(item, dayOfMonthIndex) in day.whichOfTheMonth">
              <div
                class="horaires-view__times-switches"
                :key="`which-of-the-month-${index}-${dayOfMonthIndex}`"
              >
                <dc-switch
                  :id="`switch-${day.label}-${index}-${dayOfMonthIndex}`"
                  v-model="day.whichOfTheMonth[dayOfMonthIndex]"
                  :disabled="!day.active"
                />
                <label
                  class="horaires-view__text"
                  :for="`switch-${day.label}-${index}-${dayOfMonthIndex}`"
                >
                  Le {{ dayOfMonthIndex + 1 }}° de chaque mois
                </label>
              </div>
            </template>
          </div>
        </div>
      </div>
      </template>
      <div class="hr col-12"></div>
    </div>

    <div class="actions-section">
      <div>
        <dc-button
          variant="primary"
          @click.prevent="next(false)"
        >
        Suivant
        </dc-button>
      </div>
      <div class="spacer"></div>
      <div>
        <dc-button
        variant="secondary"
        @click.prevent="next(true)"
        >
        <template v-if="editionPlace !== null">
          Modifier {{ isAdil ? 'l\'Adil' : 'la permanence'}}
        </template>
        <template v-else>
          Créer {{ isAdil ? 'l\'Adil' : 'la permanence'}}
        </template>
        </dc-button>
      </div>
      <div class="spacer"></div>
      <div>
        <dc-button
          variant="warning"
          @click.prevent="cancel"
        >
        Annuler
        </dc-button>
      </div>
    </div>
  </div>
</template>

<script>
import DcSwitch from '@/components/ui/Switch'
import DcButton from '@/components/ui/Button'
import DcSelect from '@/components/ui/Select'

export default {
  name: 'HourView',
  components: {
    DcSelect,
    DcSwitch,
    DcButton
  },
  data () {
    return {
      days: [],
      timesStart: [],
      timesEnd: [],
      daysList: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi']
    }
  },
  props: {
    isAdil: {
      type: Boolean,
      default: false
    },
    editionPlace: {
      type: Array,
      default: null
    }
  },
  methods: {
    activeDay (day) {
      if (!day.active) {
        day.am.start = null
        day.am.end = null
        day.pm.start = null
        day.pm.end = null
        day.whichOfTheMonth.forEach((d, idx) => {
          day.whichOfTheMonth[idx] = false
        })
      }
    },
    mapDay (day) {
      const result = {}
      result.dayOfWeek = day.index
      result.day = day.active
      result.startTime = day.am.start === '' ? null : day.am.start
      result.endMiddleTime = day.am.end === '' ? null : day.am.end
      result.startMiddleTime = day.pm.start === '' ? null : day.pm.start
      result.endTime = day.pm.end === '' ? null : day.pm.end
      result.firstDay = day.whichOfTheMonth[0]
      result.secondDay = day.whichOfTheMonth[1]
      result.thirdDay = day.whichOfTheMonth[2]
      result.fourthDay = day.whichOfTheMonth[3]
      result.fifthDay = day.whichOfTheMonth[4]
      return result
    },
    next (save) {
      const values = this.days
        .map(d => {
          return this.mapDay(d)
        })
      this.$emit('next', {
        model: values,
        save: save
      })
    },
    cancel () {
      if (this.isAdil) {
        this.$router.push({
          name: 'adil'
        })
      } else {
        this.$router.push({
          name: 'permanences'
        })
      }
    },
    populate () {
      let current = null
      this.editionPlace.forEach(p => {
        current = this.days[p.dayOfWeek - 1]
        current.index = p.dayOfWeek
        current.label = this.daysList[p.dayOfWeek - 1]
        current.active = p.day
        current.am = {}
        current.am.start = p.startTime
        current.am.end = p.endMiddleTime
        current.pm = {}
        current.pm.start = p.startMiddleTime
        current.pm.end = p.endTime
        current.whichOfTheMonth[0] = p.firstDay
        current.whichOfTheMonth[1] = p.secondDay
        current.whichOfTheMonth[2] = p.thirdDay
        current.whichOfTheMonth[3] = p.fourthDay
        current.whichOfTheMonth[4] = p.fifthDay
      })
    }
  },
  validations: {},
  created () {
    for (let index = 9; index <= 13; index++) {
      this.timesStart.push({
        label: `${index}h00`,
        value: `${index}:00`
      })
      for (let mn = 15; mn <= 45; mn = mn + 15) {
        this.timesStart.push({
          label: `${index}h${mn}`,
          value: `${index}:${mn}`
        })
      }
    }

    for (let index = 13; index <= 18; index++) {
      this.timesEnd.push({
        label: `${index}h00`,
        value: `${index}:00`
      })
      for (let mn = 15; mn <= 45; mn = mn + 15) {
        this.timesEnd.push({
          label: `${index}h${mn}`,
          value: `${index}:${mn}`
        })
      }
    }

    this.daysList.forEach((d, i) => {
      this.days.push({
        index: i + 1,
        label: d,
        active: false,
        am: {
          start: null,
          end: null
        },
        pm: {
          start: null,
          end: null
        },
        whichOfTheMonth: [
          false,
          false,
          false,
          false,
          false
        ]
      })
    })

    if (this.editionPlace && this.editionPlace.length > 0) {
      this.populate()
    }
  }
}
</script>

<style lang="scss">
.horaires-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &_title{
    margin-bottom: 20px;
  }

  &__day {
    margin-top: 3rem;
    width: 100%;
  }
  &__label {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
  }
  &__text {
    padding: 0;
    padding-top: .5rem;
  }
  &__times {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
    margin: 1rem 0 1rem 0;
  }
  &__times-am {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-column: 1 / span 2;
    grid-row: 1 / 1;
  }
  &__times-pm {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-column: 1 / span 2;
    grid-row: 2 / 2;
  }
  &__times-chk {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-column: 3;
    grid-row: 1 / span 2;
  }
  &__times-switches {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: .5rem 0 .5rem 0;
  }
  .actions-section {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    background-color: $light;
    width: 100%;
    .dc-button {
      margin: 1rem;
    }
  }
}
</style>
