/* eslint-disable indent */
<template>
  <div>
    <form @submit.prevent="search()" autocomplete="off">
      <div
        class="search-appointment"
        :class="{'search-appointment--horizontal': horizontal,
        'search-appointment--mobile': $mq === 'sm'}"
      >
        <dc-input
          inputId="location"
          prependIcon="ICONE-LIEU"
          :prependIconCustom="true"
          v-debounce:100ms="onLocationChange"
          @focus="onLocationChange"
          placeholder="Lieu"
          v-model.trim="$v.location.$model"
          :invalid="$v.location.$anyDirty && !$v.location.required"
          :auto-complete-values="locations"
          @value-selected="selectLocation"
          :auto-complete-on-click="autoCompleteOnClick"
        />
        <dc-input
          inputId="category"
          prependIcon="ICONE-THEMATIQUE"
          :prependIconCustom="true"
          v-debounce:100ms="onCategoryChange"
          @focus="onCategoryChange"
          placeholder="Précisez"
          v-model.trim="$v.category.$model"
          :disabled="!$v.location.$model"
          :invalid="$v.category.$anyDirty && !$v.category.required"
          :auto-complete-values="categoriesFiltered"
          @value-selected="selectCategory"
          :auto-complete-on-click="autoCompleteCategoryOnClick"
        />
        <dc-input
          inputId="thematic"
          prependIcon="ICONE-RECHERCHE"
          :prependIconCustom="true"
          v-debounce:100ms="onThematicChange"
          @focus="onThematicChange"
          placeholder="Thématique"
          v-model.trim="$v.thematic.$model"
          :disabled="!$v.location.$model && !$v.category.$model"
          :invalid="$v.thematic.$anyDirty && !$v.thematic.required"
          :auto-complete-values="thematicsFiltered"
          @value-selected="selectThematic"
          :auto-complete-on-click="autoCompleteThematicOnClick"
        />
        <dc-button type="submit" :disabled="$v.$invalid">RECHERCHER</dc-button>
      </div>
    </form>
  </div>
</template>
<script>
import DcInput from '@/components/ui/Input'
import DcButton from '@/components/ui/Button'
import { required } from 'vuelidate/lib/validators'
import { placeQuery } from '@/services/place.service.js'

export default {
  name: 'SearchAppointmentForm',
  components: {
    DcInput,
    DcButton
  },
  data () {
    return {
      autoCompleteOnClick: false,
      autoCompleteThematicOnClick: false,
      autoCompleteCategoryOnClick: false,
      locations: null,
      thematics: null,
      categories: null,
      thematicsFiltered: null,
      categoriesFiltered: null,
      location: null,
      selectedLocation: null,
      thematic: null,
      category: null,
      selectedThematic: null,
      selectedCategory: null,
      nbCharAutoComplete: 2
    }
  },
  props: {
    horizontal: {
      type: Boolean,
      default: false
    }
  },
  validations: {
    location: {
      required
    },
    thematic: {
      required
    },
    category: {
      required
    }
  },
  methods: {
    selectLocation (value) {
      this.selectedLocation = value
      this.location = value.label
      this.selectedThematic = null
      this.selectedCategory = null
      this.thematics = value.value.thematics
      this.categories = value.value.thematics.map(x => x.category)
      this.categoriesFiltered = this.categories
        .filter((x, idx, self) => x !== null && self.findIndex(y => y !== null && y.name === x.name) === idx)
        .map(c => {
          return {
            label: c.name.trim(),
            value: c
          }
        })
    },
    selectThematic (value) {
      this.selectedThematic = value
      this.thematic = value.label
    },
    selectCategory (value) {
      this.selectedCategory = value
      this.category = value.label
      this.thematicsFiltered = this.thematics
        .filter(x => x.category?.id === value.value.id)
        .map(c => {
          return {
            label: c.name.trim(),
            value: c
          }
        })
    },
    async onLocationChange (event) {
      let nbChars = this.nbCharAutoComplete
      if (!event || event === '') {
        nbChars = 0
        this.autoCompleteOnClick = true
      } else {
        this.autoCompleteOnClick = false
      }

      if (event.length >= nbChars) {
        try {
          const response = await placeQuery(event)
          this.locations = response
            .map(c => {
              return {
                label: c.name.trim() + '  (' + c.postalCode + ')',
                value: c
              }
            })
            .sort((a, b) => {
              if (a.label > b.label) {
                return 1
              } else {
                return -1
              }
            })
        } catch (error) {
          // console.error(error)
        }
      }
    },
    onThematicChange (event) {
      let nbChars = this.nbCharAutoComplete
      if (event === '') {
        nbChars = 0
        this.autoCompleteThematicOnClick = true
      } else {
        this.autoCompleteThematicOnClick = false
      }
      if (this.thematics && event.length >= nbChars) {
        this.thematicsFiltered = this.thematics
          .map(c => {
            return {
              label: c.name.trim(),
              value: c
            }
          })
          .filter(c => {
            return c.label.toLowerCase().includes(event.toLowerCase()) || nbChars === 0
          })
      }
    },
    onCategoryChange (event) {
      let nbChars = this.nbCharAutoComplete
      if (event === '') {
        nbChars = 0
        this.autoCompleteCategoryOnClick = true
      } else {
        this.autoCompleteCategoryOnClick = false
      }
      if (this.categories && event.length >= nbChars) {
        this.categoriesFiltered = this.categories
          .map(c => {
            return {
              label: c.name.trim(),
              value: c
            }
          })
          .filter(c => {
            return c.label.toLowerCase().includes(event.toLowerCase()) || nbChars === 0
          })
      }
    },
    search () {
      this.$router.push({
        name: 'appointments',
        params: {
          postalCode: this.selectedLocation.value.id,
          thematic: this.selectedThematic.value.id
        }
      })
    }
  }
}
</script>
<style lang="scss">
.search-appointment-error {
	text-align: center;
	font-size: 1.5rem;
}
.search-appointment {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	position: absolute;
	width: 40rem;
	top: 35%;
	left: 50%;
	opacity: 0;
	transform: translateX(-50%);
  animation: fadeIn .3s ease forwards .8s;
  &--mobile {
    position: relative;
    width: 100%;
    margin-top: 5rem;
  }
  .dc-input {
    margin: 1rem 0 1rem 0;
  }
  &--horizontal {
    position: relative;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 4rem;
    .dc-input {
      margin-right: 2rem;
    }
  }
	.mat-form-field {
		width: 100%;
		height: 6.65rem;
		font-size: 1.5rem;
	}
	.mat-raised-button.mat-primary {
		background-color: $blue;
		&:hover {
			background-color: lighten($blue, 10%);
		}
  }
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>
