<template>
  <div class="permanences-view">

    <div class="permanences-view__list">
      <title-deck>Permanences</title-deck>
      <dc-table :columns="cols" :rows="rows" rowItemName="permanence">

        <template v-slot:header>
          <div class="permanences-view__table-footer-buttons">
            <dc-button @click="addPermanence()" variant="primary-small">Ajouter</dc-button>
          </div>
        </template>

        <template v-slot:cell-name="{permanence}">
          {{ permanence.name }}
        </template>

        <template v-slot:cell-postalCode="{permanence}">
          {{ permanence.postalCode }}
        </template>

        <template v-slot:cell-city="{permanence}">
          {{ permanence.city }}
        </template>

        <template v-slot:cell-users="{permanence}">
          {{ permanence.nbUsers }}
        </template>

        <template v-slot:cell-status="{permanence}">
          <dc-switch
            :id="`perm-active-${permanence.id}`"
            v-model="permanence.isActive"
            @change="toggleStatus(permanence.id)"
          />
        </template>

        <template v-slot:cell-edit="{permanence}">
          <dc-button
            @click="editPermanence(permanence.id)"
            variant="secondary-small"
          >
            <md-icon>create</md-icon>
          </dc-button>
        </template>

        <template v-slot:cell-delete="{permanence}">
          <dc-button
            @click="deletePermanence(permanence.id)"
            variant="secondary-small"
          >
            <inline-svg class="delete-icon-inline" :src="require('@/assets/img/icons/ICONE-ANNULER.svg')"></inline-svg>
          </dc-button>
        </template>
      </dc-table>
      <template v-if="rows">
        <div class="hr hr--no-mb"></div>
        <span class="table-total">
          {{ rows.length }} Total
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import DcSwitch from '@/components/ui/Switch'
import DcTable from '@/components/ui/Table'
import TitleDeck from '@/components/ui/TitleDeck'
import DcButton from '@/components/ui/Button'

import { getPlacesByType, changePlaceStatus, deletePlace } from '@/services/place.service.js'

export default {
  name: 'PermanencesView',
  components: {
    DcSwitch,
    DcTable,
    TitleDeck,
    DcButton
  },
  data () {
    return {
      cols: [
        { name: 'name', label: 'Nom', sortOrder: 'ASC' },
        { name: 'postalCode', label: 'Code postal', sortOrder: 'ASC' },
        { name: 'city', label: 'Ville', sortOrder: 'ASC' },
        { name: 'users', label: 'Inscrit(s)', sortOrder: 'ASC' },
        { name: 'status', label: 'Statut', sortOrder: 'ASC' },
        { name: 'edit', label: '', sortOrder: 'ASC' },
        { name: 'delete', label: '', sortOrder: 'ASC' }
      ],
      rows: [],
      showPopin: false
    }
  },
  methods: {
    async refreshList () {
      try {
        const permanences = await getPlacesByType('permanence')
        this.rows = [...permanences]
      } catch (error) {
        // console.error('refreshList()', error)
      }
    },
    async toggleStatus (permanenceId) {
      try {
        await changePlaceStatus(permanenceId)
        this.refreshList()
      } catch (error) {
        // console.error(error)
      }
    },
    addPermanence () {
      this.$router.push({ name: 'createPermanence' })
    },
    async deletePermanence (id) {
      try {
        if (window.confirm('Vous allez annuler ce rendez-vous')) {
          await deletePlace(id)
          this.refreshList()
        }
      } catch (error) {
        // console.error(error)
      }
    },
    editPermanence (id) {
      this.$router.push({
        name: 'editpermanences',
        params: {
          id: id
        }
      })
    }
  },
  mounted () {
    this.refreshList()
  }
}
</script>

<style lang="scss" scoped>
.permanences-view {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: $light-grey-blue;
  background: linear-gradient(90deg, $blue 38%, $light-grey-blue 38%);
  padding-top: calc(#{$header-height} + 1rem);
  overflow-y: auto;
  &__list {
    position: absolute;
    z-index: 2;
    border-radius: 1rem;
    left: 50%;
    top: 14rem;
    transform: translateX(-50%);
    width: 123.5rem;
    background-color: $light;
    box-shadow: 4.8px 7.6px 12.2px 0.8px rgba(0, 0, 0, 0.07);
    padding: 5rem;
    padding-top: 9rem;
    margin-bottom: 12rem;
  }

  &__table-footer-buttons {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
