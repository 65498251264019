<template>
  <div class="dc-dropdown-menu" v-click-outside="clickOutside">
    <button
      class="dc-dropdown-menu__button"
      @click="openClose()"
    >
      <slot name="menu-label"></slot>
    </button>
    <div
      class="dc-dropdown-menu__menu"
      :id="'menu-' + randomId"
      :class="{'dc-dropdown-menu__menu--show': showMenu}"
    >
      <template v-if="items && items.length > 0">
        <template v-for="(item, index) in items">
            <dc-button
                v-if="item.label !== ''"
                :key="`link-menu-${index}`"
                :variant="item.route === $route.path ? 'link-menu-current' : 'link-menu'"
                @click="goTo(item.route)"
            >
                <template v-if="item.label === 'Déconnexion'">
                    <strong>{{ item.label }}</strong>
                </template>
                <template v-if="item.label !== 'Déconnexion'">
                    {{ item.label }}
                </template>
            </dc-button>
            <div
              v-else
              :key="`separator-menu-${index}`"
              class="dc-dropdown-menu__separator"
            >
        </div>
        </template>
      </template>
      <template v-if="!items">
        <slot name="menu__items-list"></slot>
      </template>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import DcButton from '@/components/ui/Button'

export default {
  name: 'DropDownMenu',
  directives: {
    ClickOutside
  },
  components: {
    DcButton
  },
  data () {
    return {
      showMenu: false
    }
  },
  props: {
    items: {
      type: Array,
      default: null
    }
  },
  methods: {
    randomId () {
      return Math.floor(Math.random() * Math.floor(1024))
    },
    openClose () {
      this.showMenu = !this.showMenu
    },
    clickOutside () {
      this.showMenu = false
    },
    goTo (route) {
      this.$router.push(route)
    }
  }
}
</script>

<style lang="scss">
.dc-dropdown-menu {
    position: relative;
    display: inline-block;
    border:0;
    font-size: 1.8rem;
    font-weight: $font-weight-bold;
    letter-spacing: 1.35px;
    text-align: left;
    border-radius: 1rem;
    transition: all .3s ease;
    background-color: transparent;
    height: 3.4rem;
    color: $blue;
    box-shadow: none;
    &__separator {
        width: 100%;
        height: 4.1rem;
    }
    .dc-button {
      color: inherit;
      &:hover {
          background-color: transparent;
          color: darken($blue, 10%);
      }
    }
    button {
        cursor: pointer;
        border:0;
        background-color: inherit;
        color: inherit;
        font-size: inherit;
        letter-spacing: inherit;
        text-align: inherit;
    }
    &__menu {
        position: absolute;
        background-color: $light;
        border-radius: 10px;
        top: 4rem;
        left: 2%;
        opacity: 0;
        transform: scale(0);
        box-shadow: 4.8px 7.6px 12.2px 0.8px rgba(0, 0, 0, 0.06);
        border: solid 1px $light-grey;
        background-color: $light;
        padding: 1.5rem;
        &--show {
            animation: open .3s ease forwards;
        }
    }
}

@keyframes open {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    80% {
        opacity: 1;
        transform: scale(1.2);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
</style>
