<template>
  <div class="planning-view">
    <planning
      :adils="adils || []"
      :permanences="permanences || []"
      :hours="hours"
      :date="currentDate"
      @next="dayNext"
      @prev="dayBefore"
      @refresh="refreshList"
      @date-changed="changeDate"
    />
  </div>
</template>

<script>
import { listAdilJuristAppointmentWithAvailability } from '@/services/appointment.service.js'
import Planning from '@/components/planning'

export default {
  name: 'PlanningView',
  components: {
    Planning
  },
  data () {
    return {
      adils: [],
      permanences: [],
      currentDate: null,
      hours: ['9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h']
    }
  },
  methods: {
    changeDate (date) {
      this.currentDate = date
      this.refreshList()
    },
    incrementDay (delta) {
      this.currentDate = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        this.currentDate.getDate() + delta)
      this.refreshList()
    },
    dayBefore () {
      this.incrementDay(-1)
    },
    dayNext () {
      this.incrementDay(1)
    },
    async refreshList () {
      this.localJurists = null // Corto : Où est utilisée cette variable ???
      this.permanence = null // Corto : Où est utilisée cette variable ???
      try {
        const planning = await listAdilJuristAppointmentWithAvailability(this.currentDate)
        if (planning != null) {
          this.adils = planning.filter(l => l.isAdil === true)
          this.permanences = planning.filter(l => l.isAdil !== true)
        }
      } catch (error) {
        // console.error(error)
      }
    }
  },
  mounted () {
    this.currentDate = new Date()
    this.refreshList()
  }
}
</script>

<style lang="scss" scoped>
.planning-view {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: $light-grey-blue;
  background: linear-gradient(90deg, $blue 38%, $light-grey-blue 38%);
  padding-top: calc(#{$header-height} + 1rem);
  overflow-y: auto;
}
</style>
