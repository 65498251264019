<template>
  <div
    class="dc-step"
    tabindex="0"
    :class="{'dc-step--active': step.active, 'dc-step--disabled': step.disabled }"
    @click="setStep"
  >
    <span
      class="dc-step__label"
      :class="{'dc-step__label--active': step.active}"
    >
      {{ step.label }}
    </span>
    <span class="dc-step__icon">
      <md-icon :md-src="require(`@/assets/img/icons/${step.icon}.svg`)" />
    </span>
  </div>
</template>

<script>
export default {
  name: 'DcStep',
  props: {
    step: {
      type: Object,
      required: true
    }
  },
  methods: {
    setStep (index) {
      if (!this.step.disabled) {
        this.$emit('is-active', this.step.index)
      }
    }
  }
}
</script>

<style lang="scss">
.dc-step {
  position: relative;
  height: 12rem;
  width: 100%;
  margin: 1rem 0 1rem 0;
  cursor: pointer;
  &--disabled {
    cursor: not-allowed;
  }
  &__label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY( -50%);
    display: inline-block;
    font-size: 2.5rem;
    font-weight: $font-weight-bold;
    letter-spacing: 1.88px;
    text-align: right;
    color: $light;
    width: 80%;
    transition: all 300ms ease;
    &--active {
      width: 70%;
      font-size: 4rem;
    }
  }
  $icon-size: 6.6rem;
  $icon-size-by-2: calc(#{$icon-size} / 2);
  &__icon {
    position: absolute;
    display: inline-flex;
    top: 50%;
    right: calc(0rem - #{$icon-size-by-2});
    transform: translateY( -50%);
    width: $icon-size;
    height: $icon-size;
    border-radius: 999px;
    box-shadow: 3.9px 4.6px 6.6px 0.4px rgba(0, 0, 0, 0.21);
    background-color: $light;
    transition: all 300ms ease;
    transform-origin: center;
    .md-icon {
      svg {
        fill: $blue !important;
      }
    }
  }
  &--active {
    .dc-step__icon {
      transform: scale(2) translateY( -25%);
      border: .2rem solid $orange;
    }
  }
  &:hover {
    .dc-step__icon {
      transform: scale(2) translateY( -25%);
    }
    .dc-step__label {
      width: 70%;
      font-size: 4rem;
    }
  }
  // &:focus {
  //   .dc-step__icon {
  //     transform: scale(2) translateY( -25%);
  //     border: .2rem solid $orange;
  //   }
  // }
}
</style>
