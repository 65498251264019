<template>
  <div class="dc-planning">
    <div class="planning-wrapper" ref="planning">
      <div class="planning-wrapper__header">
        <div
          @click="$emit('prev')"
          class="planning-wrapper__header-btn-prev hide-for-pdf"
        >
          <md-icon>navigate_before</md-icon>
        </div>
        <div class="planning-wrapper__header-time">
          <div class="planning-wrapper__header-time-download">
          </div>
          <div class="planning-wrapper__header-time-text">
            {{ currentDate }}
          </div>
          <md-datepicker v-model="selectedDate"/>
        </div>
        <div
          @click="$emit('next')"
          class="planning-wrapper__header-btn-next hide-for-pdf"
        >
          <md-icon>navigate_next</md-icon>
        </div>
      </div>
      <div>
        <template v-if="adils && adils.length > 0">
          <template v-for="(item, index) in adils">
            <div :key="`row-siege-${index}`" class="planning-wrapper__section">
              <span>Siège</span>
              <button @click="createPlanningPdf(item, date)" v-if="adils && adils.length > 0">
              <inline-svg :src="require('@/assets/img/icons/ICONE-PDF.svg')" />
              </button>
            </div>
            <template v-if="item.appJurists && item.appJurists.length > 0">
              <template v-for="(jurist, index2) in item.appJurists">
                <div class="planning-wrapper__row" :key="`row-jurist-${index}-${index2}-1`">
                  <dc-row @refresh="$emit('refresh')" :item="jurist" :hours="hours" :place="item"/>
                </div>
              </template>
            </template>
            <template v-else>
              <div :key="`row-siege-${index}--0`" class="planning-wrapper__row-message">
                Aucun juriste n'est disponible a cette date
              </div>
            </template>
          </template>
        </template>

        <template v-if="permanences && permanences.length > 0">
          <template v-for="(item, index) in permanences">
          <div :key="`row-permanence-${index}`" class="planning-wrapper__section">
            <span>Permanence : {{item.name}}</span>
          </div>
            <template v-for="(jurist, index2) in item.appJurists">
              <div
                class="planning-wrapper__row"
                :key="`row-jurist-${index}-${index2}`"
              >
                <dc-row @refresh="$emit('refresh')" :item="jurist" :hours="hours" :place="item"/>
              </div>
            </template>
          </template>
        </template>
        <template v-else>
          <div :key="`row-permanence-0`" class="planning-wrapper__section">
            <span>Permanence</span>
          </div>
          <div
            class="planning-wrapper__row-message"
          >
            Aucun juriste n'est disponible a cette date
          </div>
        </template>

      </div>
    </div>
  </div>
</template>

<script>
import DcRow from './Row.vue'
import dayjs from 'dayjs'
import { download, createPlanningPdf } from '@/services/pdf.service.js'

export default {
  name: 'DcPlanning',
  components: {
    DcRow
  },
  data () {
    return {
      createPlanningPdf,
      selectedDate: null
    }
  },
  props: {
    adils: {
      type: Array,
      required: true
    },
    permanences: {
      type: Array,
      required: true
    },
    hours: {
      type: Array,
      required: true
    },
    date: {
      type: Date
    }
  },
  computed: {
    currentDate () {
      return dayjs(this.date).format('dddd DD MMMM YYYY')
    },
    currentDateForDocument () {
      return dayjs(this.date).format('DD-MM-YYYY')
    }
  },
  methods: {
    async download () {
      try {
        download(this.$refs.planning, `planning-${this.currentDateForDocument}`, ['md-icon', ' hide-for-pdf'], false)
      } catch (error) {
        // console.error(error)
      }
    }
  },
  watch: {
    selectedDate (value) {
      this.$emit('date-changed', value)
    }
  }
}
</script>

<style lang="scss">
.dc-planning {
  position: absolute;
  z-index: 2;
  border-radius: 1rem;
  left: 50%;
  top: 14rem;
  transform: translateX(-50%);
  width: 94%;
  background-color: $light;
  box-shadow: 4.8px 7.6px 12.2px 0.8px rgba(0, 0, 0, 0.07);
  padding-bottom: 5rem;
  padding-top: 9rem;
  height: 80%;
  overflow-y: scroll;
  .planning-wrapper {
    .md-field.md-theme-default:after {
      background-color: transparent !important;
    }
    .md-datepicker {
      border: 0;
      input, button {
        display: none;
      }
    }
    &__section {
      button {
        border: 0;
        cursor: pointer;
        margin-left: 1rem;
        background-color: transparent;
        svg {
          width: 1.8rem;
          fill: white;
        }
      }
    }
    &__row-message {
      font-size: 1.5rem;
      color: $dark;
      font-weight: $font-weight-bold;
      padding: 1rem;
      text-align: center;
    }
    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 7rem;
    }
    &__header-time {
      font-size: 18px;
      font-weight: $font-weight-bold;
      letter-spacing: 1.35px;
      text-align: center;
      color: $dark;
      display: grid;
      grid-template-columns: 0.5fr 1fr 0.5fr;
      .md-datepicker {
        i {
          svg {
            fill: $dark;
          }
        }
      }
    }
    &__header-time-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &__header-time-download {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      button {
        border: 0;
        cursor: pointer;
        svg {
          width: 1.8rem;
        }
      }
    }
    &__header-btn-prev, &__header-btn-next {
      width: 2.2rem;
      height: 2.2rem;
      border: 1px solid $dark;
      border-radius: .5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .md-icon {
        width: 2rem;
        height: 2rem;
        color: $dark !important;
      }
    }
    &__time {
      align-items: flex-start;
      font-size: 1.4rem;
      letter-spacing: 0.28px;
      color: $dark;
      // margin: 0 .2rem 0 .2rem;
    }
    &__section {
      height: 6.1rem;
      background-color: #006f9e;
      padding-left: 2.2rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &:first-child{
        background-color: $dark-grey;
      }
      span {
        font-size: 1.6rem;
        font-weight: $font-weight-bold;
        text-align: left;
        color: $light;
      }
    }
  }
}
</style>
