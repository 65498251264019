import axios from 'axios'
import store from '@/store'

export async function getAllThematics () {
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.get(process.env.VUE_APP_API_URL + '/thematic')
    return response.data
  } catch (error) {
    throw Error('ERROR getAllThematics : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function getAllActiveThematics () {
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.get(process.env.VUE_APP_API_URL + '/thematic/active')
    return response.data
  } catch (error) {
    throw Error('ERROR getAllThematics : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function getThematic (thematicId) {
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.get(process.env.VUE_APP_API_URL + '/thematic/' + thematicId)
    return response.data
  } catch (error) {
    throw Error('ERROR getThematic(' + thematicId + ') : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function addThematic (thematic) {
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.post(process.env.VUE_APP_API_URL + '/thematic', thematic)
    return response.data
  } catch (error) {
    throw Error('ERROR addThematic() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function updateThematic (thematic) {
  try {
    store.dispatch('SET_LOADING', true)
    await axios.post(process.env.VUE_APP_API_URL + '/thematic/update', thematic)
  } catch (error) {
    throw Error('ERROR updateThematic() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function uploadCsv (file, thematicId) {
  try {
    store.dispatch('SET_LOADING', true)
    const formData = new FormData()
    formData.append('file', file)
    await axios.post(process.env.VUE_APP_API_URL + '/thematic/fileCode/' + thematicId,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
  } catch (error) {
    throw Error('ERROR updateThematic() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function deleteThematic (thematicId) {
  try {
    store.dispatch('SET_LOADING', true)
    await axios.delete(process.env.VUE_APP_API_URL + '/thematic/' + thematicId)
  } catch (error) {
    throw Error('ERROR deleteThematic() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function toggleThematicActivation (thematicId) {
  try {
    await axios.post(process.env.VUE_APP_API_URL + '/thematic/' + thematicId + '/toggle-activation', null)
  } catch (error) {
    throw Error('ERROR addThematic() : ' + error)
  }
}
