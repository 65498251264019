<template>
  <div :class="{'home': $mq !== 'sm', 'home-mobile': $mq === 'sm'}">
    <template v-if="$mq !== 'sm'">
      <div class="home__content">
        <h1>
          Prenez un rendez-vous <br/> avec l'<span class="font-orange">ADIL</span>!
        </h1>
        <div class="home__mission">
          <span class="home__mission-id">1</span>
          <span class="home__mission-text">Inscrivez-vous</span>
        </div>
        <div class="home__mission">
          <span class="home__mission-id">2</span>
          <span class="home__mission-text">Choisissez le lieu</span>
        </div>
        <div class="home__mission">
          <span class="home__mission-id">3</span>
          <span class="home__mission-text">Choisissez le thème qui correspond à votre demande</span>
        </div>
      </div>
      <div class="home__circle-city"></div>
      <div class="home__circle-container">
        <h2>Prise de<br/>rendez-vous</h2>
        <search-appointment-form></search-appointment-form>
      </div>
    </template>
    <template v-else>
      <div class="home-mobile__content">
        <h1>
          Bienvenue<br/>
          sur l’application <span class="font-orange">Adil</span> !
        </h1>
        <div class="home-mobile__mission">
          <span class="home-mobile__mission-id">1</span>
          <span class="home-mobile__mission-text">Pour toutes questions, vous pouvez nous contacter.</span>
        </div>
        <div class="home-mobile__mission">
          <span class="home-mobile__mission-id">2</span>
          <span class="home-mobile__mission-text">Formulaire d’inscription à votre disposition sur le site.</span>
        </div>
        <div class="home-mobile__mission">
          <span class="home-mobile__mission-id">3</span>
          <span class="home-mobile__mission-text">Si vous avez des problèmes, vous pouvez nous contacter à tout moment.</span>
        </div>
      </div>
      <div class="home-mobile__container">
        <h2>Prise de<br/>rendez-vous</h2>
        <search-appointment-form></search-appointment-form>
      </div>
    </template>
  </div>
</template>

<script>
import SearchAppointmentForm from '@/components/appointments/search-appointments.vue'

export default {
  name: 'Home',
  components: {
    SearchAppointmentForm
  }
}
</script>

<style lang="scss">
$circle-container-left: 66.4%;
.font-orange {
  color: $orange;
}
.home {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: $light-grey-blue;
  background: linear-gradient(90deg, $blue $circle-container-left, $light-grey-blue $circle-container-left);
  @media only screen and (max-width: 768px) {
    background-color: $blue;
  }
	// &__left-part {
	// 	background-color: $blue;
	// 	height: 100vh;
	// 	width: $circle-container-left;
	// 	animation: widthUp .8s ease forwards ;
	// 	@media only screen and (max-width: 768px) {
	// 		display: none;
	// 	}
	// }
	&__content {
		position: absolute;
		top: 50%;
		left: 17.9rem;
		opacity: 1;
		transform: translateY(-50%);
		width: 86.2rem;
		animation: fromLeft .8s ease backwards .8s;
		@media only screen and (max-width: 768px) {
			position: relative;
			padding: 10rem 2rem 2rem 2rem;
			width: 100%;
			animation: none;
			top: 0;
			left: 0;
			opacity: 1;
			transform: translateY(0);
		}
		h1 {
			font-size: 5.3rem;
			font-weight: $font-weight-black;
			line-height: 1.2;
			letter-spacing: 0.158rem;
			text-align: left;
			color: $light;
			margin-bottom: 4rem;
			@media only screen and (max-width: 768px) {
				margin-bottom: 8rem;
			}
		}
	}
	&__mission {
		display: grid;
		grid-template-columns: 1fr 53rem;
		width: 60rem;
		height: 8.81rem;
		color: $light;
		font-family: Lato;
		font-size: 2.34rem;
		font-weight: $font-weight-light;
		line-height: 1.2;
		letter-spacing: 0.07rem;
		text-align: left;
		@media only screen and (max-width: 768px) {
			grid-template-columns: 1fr 9fr;
			width: 100%;
			height: 12rem;
		}
		span {
			display: inline-block;
			&:first-child {
				position: relative;
				font-weight: $font-weight-black;
				font-size: 3.98rem;
				letter-spacing: 0.299rem;
			}
		}
		&:nth-child(-n + 3) {
			span.home__mission-id {
				&::after {
					content: '';
					position: absolute;
					width: 2px;
					height: 40%;
					background-color: $light;
					top: 58%;
					left: 17%;
					@media only screen and (max-width: 768px) {
						top: 50%;
						left: 23%;
					}

				}
			}
		}
	}
	&__circle-container {
		position: absolute;
		top: 50%;
		left: $circle-container-left;
		transform: translate(-50%, -50%) scale(0);
		width: 69.1rem;
		height: 69.1rem;
		background-color: $light;
		border: 1rem solid $orange;
		border-radius: 1000px;
		animation: scaleUp .8s ease forwards;
		@media only screen and (max-width: 768px) {
			position: relative;
			transform: none;
			padding: 2rem 2rem 2rem 2rem;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			background-color: $light-grey-blue;
			border: 0;
			border-radius: 0;
			animation: none;
		}
		h2 {
			margin: auto;
			margin-top: 13.1rem;
			width: 40rem;
			font-size: 4.1rem;
			font-weight: $font-weight-black;
			line-height: 1.2;
			letter-spacing: 0.158rem;
			text-align: left;
			color: $dark-blue;
			opacity: 0;
			animation: fadeIn .3s ease forwards .5s;
		}
	}

	&__circle-city {
		position: absolute;
		top: 50%;
		left: $circle-container-left;
		transform: translate( -72%, -61%) scale(1.2);
		transform-origin: center;
		width: 70rem;
		height: 70rem;
		background-image: url('../assets/img/city.svg');
		background-repeat: no-repeat;
		background-position: center;
		border-radius: 1000px;
		animation-delay: .8s;
		animation: scaleUp2 .8s ease forwards;
		@media only screen and (max-width: 768px) {
			display: none;
			animation: none;
		}
	}

}

.home-mobile {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: $blue;
  &__container {
    position: relative;
    padding: 2.4rem 2.4rem 2.4rem 2.4rem;
    width: 100vw;
    height: 100vh;
    background-color: $light-grey-blue;
		h2 {
			margin: auto;
			margin-top: 10rem;
      width: 100%;
			font-size: 4.1rem;
			font-weight: $font-weight-black;
			line-height: 1.2;
			letter-spacing: 0.158rem;
			text-align: left;
			color: $dark-blue;
			opacity: 0;
			animation: fadeIn .3s ease forwards .5s;
		}
	}
  &__content {
    position: relative;
    padding: 12.4rem 2.4rem 2.4rem 2.4rem;
    width: 100vw;
    height: 100vh;
		h1 {
			font-size: 5.3rem;
			font-weight: $font-weight-black;
			line-height: 1.2;
			letter-spacing: 0.158rem;
			text-align: left;
			color: $light;
			margin-bottom: 8rem;
		}
	}
	&__mission {
		display: grid;
		color: $light;
		font-size: 2.34rem;
		font-weight: $font-weight-light;
		line-height: 1.2;
		letter-spacing: 0.07rem;
		text-align: left;
    grid-template-columns: 1fr 9fr;
    width: 100%;
    height: 12rem;
		span {
			display: inline-block;
			&:first-child {
				position: relative;
				font-weight: $font-weight-black;
				font-size: 3.98rem;
				letter-spacing: 0.299rem;
			}
		}
		&:nth-child(-n + 3) {
			span.home-mobile__mission-id {
				&::after {
					content: '';
					position: absolute;
					width: 2px;
					height: 40%;
					background-color: $light;
          top: 50%;
          left: 23%;
				}
			}
		}
	}
}

@keyframes widthUp {
	0% {
		width: 0;
	}
	80% {
		width: 70%;
	}
	100% {
		width: $circle-container-left;
	}
}

@keyframes fromLeft {
	0% {
		opacity: 0;
		left: -40rem;
	}
	80% {
		opacity: 1;
		left: 25rem;
	}
	100% {
		opacity: 1;
		left: 17.9rem;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes scaleUp2 {
	0% {
		opacity: 0;
		transform: translate( -72%, -61%)  scale(0);
	}
	80% {
		transform: translate( -72%, -61%)  scale(1.3);
	}
	100% {
		opacity: 1;
		transform: translate( -72%, -61%)  scale(1.2);
	}
}

@keyframes scaleUp {
  0% {
		transform: translate(-50%, -50%)  scale(0);
	}
	80% {
		transform: translate(-50%, -50%)  scale(1.2);
	}
	100% {
		transform: translate(-50%, -50%)  scale(1);
	}
}
</style>
