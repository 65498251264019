<template>
  <div class="thematiques-view">
    <h1>Thématiques</h1>
    <div class="thematiques-view__list">
      <template v-for="(thematic, index) in thematics">
        <div
          :key="`thematic-${index}}`"
          class="thematiques-view__item"
        >
          <dc-switch
            :key="`thematic-${index}}`"
            :id="`switch-thematic-${index}`"
            v-model="thematics[index].used"
          />
          <label
            class="thematiques-view__text"
            :for="`switch-thematic-${index}`"
          >
            {{ thematics[index].name }}
          </label>
        </div>
      </template>
    </div>
    <div class="hr col-12"></div>
    <div class="actions-section">
      <div>
        <dc-button
          variant="primary"
          @click.prevent="next(false)"
        >
        Suivant
        </dc-button>
      </div>
      <div class="spacer"></div>
      <div>
        <dc-button
        variant="secondary"
        @click.prevent="next(true)"
        >
        <template v-if="editionPlace !== null">
          Modifier {{ isAdil ? 'l\'Adil' : 'la permanence'}}
        </template>
        <template v-else>
          Créer {{ isAdil ? 'l\'Adil' : 'la permanence'}}
        </template>
        </dc-button>
      </div>
      <div class="spacer"></div>
      <div>
        <dc-button
          variant="warning"
          @click.prevent="cancel"
        >
        Annuler
        </dc-button>
      </div>
    </div>
  </div>
</template>

<script>
import DcSwitch from '@/components/ui/Switch'
import DcButton from '@/components/ui/Button'
import { getAllActiveThematics } from '@/services/thematic.service.js'

export default {
  name: 'ThematicView',
  components: {
    DcSwitch,
    DcButton
  },
  data () {
    return {
      thematics: []
    }
  },
  props: {
    isAdil: {
      type: Boolean,
      default: false
    },
    editionPlace: {
      type: Array,
      default: null
    }
  },
  methods: {
    next (save) {
      const values = this.thematics
        .filter(t => t.used === true)
        .map(t => {
          return { id: t.id }
        })
      this.$emit('next', {
        model: values,
        save: save
      })
    },
    cancel () {
      if (this.isAdil) {
        this.$router.push({
          name: 'adil'
        })
      } else {
        this.$router.push({
          name: 'permanences'
        })
      }
    },
    loadExistingData () {
      if (this.editionPlace == null || this.editionPlace.length === 0) {
        return
      }

      this.editionPlace.forEach(t => {
        const thematicIdx = this.thematics.findIndex(th => th.id === t.id)
        if (thematicIdx > -1 && this.thematics[thematicIdx] !== 'undefined') {
          this.thematics[thematicIdx].used = true
        }
      })
    }
  },
  async mounted () {
    try {
      const allThematics = await getAllActiveThematics()
      if (allThematics == null) {
        this.$notify({
          group: 'notification-group',
          type: 'error',
          duration: 10000,
          title: 'Erreur lors de la récupération des thématiques',
          text: 'Sauvegarder le formulaire risque d\'effacer des données'
        })
      } else {
        this.thematics = allThematics.map(t => {
          t.used = false
          return t
        })
        this.loadExistingData()
      }
    } catch (error) {
      // console.error(error)
    }
  }
}
</script>

<style lang="scss">
.thematiques-view {
  &__list {
    margin-top: 5rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;
  }
  &__item {
    display: flex;
    flex-direction: row;
  }
  &__text {
    margin-left: 1rem;
  }
  .actions-section {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    background-color: $light;
    width: 100%;
    .dc-button {
      margin: 1rem;
    }
  }
}
</style>
