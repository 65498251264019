<template>
  <div class="banniere-view">
    <h1>Bannière</h1>
    <div class="container">
      <div class="preview">
        <template v-if="banner !== null">
          <img :src="banner" />
        </template>
      </div>
      <div class="actions-section">
        <div>
          <input type="file" ref="file" @change="onChange" style="display: none" accept="image/*">
          <dc-button variant="primary" @click.prevent="$refs.file.click()" >
            Choisir une image
          </dc-button>
        </div>
        <div class="spacer"></div>
        <div>
          <dc-button variant="secondary" @click.prevent="next(true)" >
            Supprimer
          </dc-button>
        </div>
      </div>
    </div>
    <div class="hr col-12"></div>
    <div class="actions-section">
      <div>
        <dc-button variant="secondary" @click.prevent="next(true)" >
          <template v-if="editionPlace !== null">
            Modifier {{ isAdil ? 'l\'Adil' : 'la permanence'}}
          </template>
          <template v-else>
            Créer {{ isAdil ? 'l\'Adil' : 'la permanence'}}
          </template>
        </dc-button>
      </div>
      <div class="spacer"></div>
      <div>
        <dc-button variant="warning"  @click.prevent="cancel" >
          Annuler
        </dc-button>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
import DcButton from '@/components/ui/Button'

export default {
  name: 'BannerView',
  components: {
    DcButton
  },
  props: {
    isAdil: {
      type: Boolean,
      default: false
    },
    editionPlace: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      preview: null,
      banner: null
    }
  },
  methods: {
    onChange (e) {
      const files = e.target.files || e.dataTransfer.files

      if (!files.length) {
        return
      }

      const file = files[0]
      const reader = new FileReader()

      reader.onload = e => {
        const dataURI = e.target.result
        if (dataURI) {
          this.banner = dataURI
        }
      }

      // read blob url from file data
      reader.readAsDataURL(file)
    },
    next (save) {
      const values = this.banner

      this.$emit('next', {
        model: values,
        save: save
      })
    },
    cancel () {
      if (this.isAdil) {
        this.$router.push({
          name: 'adil'
        })
      } else {
        this.$router.push({
          name: 'permanences'
        })
      }
    }
  },
  mounted () {
    if (this.editionPlace && this.editionPlace !== '') {
      this.banner = this.editionPlace
    }
  }
}
</script>
<style lang="scss">
.banniere-view {
  .preview {
    height: 90px;
    width: 728px;
    margin-left: 1rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }
  .actions-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    background-color: $light;
    width: 100%;
    .dc-button {
      margin: 1rem;
    }
  }
}
</style>
