<template>
  <div class="appointment-info">
    <div class="row" v-if="appointment">
      <div class="col-12">
        <div>
          <div>Lieu : </div>
          <div>{{ appointment.place.name }}</div>
          <div>{{ appointment.place.address1 }}</div>
          <div v-if="appointment.place.address2">{{ appointment.place.address2 }}</div>
          <div v-if="appointment.place.address3">{{ appointment.place.address3 }}</div>
          <div>{{ appointment.place.postalCode }} {{ appointment.place.city }}</div>
        </div>
        <div>
          <div>Thématique : </div>
          <div>{{ appointment.thematic.name }}</div>
          <div>Documents nécessaires : </div>
          <div>{{ appointment.thematic.neededDocuments }}</div>
        </div>
      </div>
    </div>
    <div class="row actions-section">
      <div class="col-8"></div>
      <div class="col-3">
        <dc-button @click="$emit('close')">Fermer</dc-button>
      </div>
      <div class="col-1"></div>
    </div>
  </div>
</template>

<script>
import DcButton from '@/components/ui/Button'

export default {
  name: 'AppointmentInfoForm',
  components: {
    DcButton
  },
  props: {
    appointment: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.appointment-info {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  .row {
    &.actions-section {
      margin:0;
      position: fixed;
      bottom: 0;
      left: 0;
      background-color: $light;
      .col-2 {
        padding: 2rem;
      }
    }
  }
}
</style>
