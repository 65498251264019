import axios from 'axios'
import store from '@/store'

export async function getAllStatus () {
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.get(process.env.VUE_APP_API_URL + '/appointment/statusList')
    return response.data
  } catch (error) {
    throw Error('ERROR getAllStatus() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function getAppointmentStats (thematicId, userId, placeId, appointmentStatus, startDate, endDate) {
  try {
    const querySrings = []
    let query = ''
    if (thematicId) querySrings.push(`thematic=${thematicId}`)
    if (userId) querySrings.push(`advisor=${userId}`)
    if (placeId) querySrings.push(`place=${placeId}`)
    if (appointmentStatus) querySrings.push(`appointmentStatus=${appointmentStatus}`)
    if (startDate) querySrings.push(`startDate=${startDate}`)
    if (endDate) querySrings.push(`endDate=${endDate}`)

    let separator = '?'
    querySrings.forEach(q => {
      query += separator + q
      if (separator === '?') {
        separator = '&'
      }
    })
    store.dispatch('SET_LOADING', true)
    const response = await axios.get(process.env.VUE_APP_API_URL + `/appointment/stats${query}`)
    return response.data
  } catch (error) {
    throw Error('ERROR getAppointmentStats() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function listAvailableAppointments (postalCode, thematic) {
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.get(process.env.VUE_APP_API_URL + `/appointment/listAvailable/${postalCode}/${thematic}`)
    return response.data
  } catch (error) {
    throw Error('ERROR listAvailableAppointments() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function addAppointment (appointment) {
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.post(process.env.VUE_APP_API_URL + '/appointment', appointment)
    return response.data
  } catch (error) {
    throw Error('ERROR addAppointment() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function updateAppointment (appointment) {
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.post(process.env.VUE_APP_API_URL + '/appointment/update', appointment)
    return response.data
  } catch (error) {
    throw Error('ERROR updateAppointment() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function listAppointmentNextForUser (id) {
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.get(process.env.VUE_APP_API_URL + `/appointment/listNextForUser/${id}`)
    return response.data
  } catch (error) {
    throw Error('ERROR listAppointmentNextForUser() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function listAppointmentHistoryForUser (id) {
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.get(process.env.VUE_APP_API_URL + `/appointment/listHistoryForUser/${id}`)
    return response.data
  } catch (error) {
    throw Error('ERROR listAppointmentHistoryForUser() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function cancelAppointment (appointmentId) {
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.post(process.env.VUE_APP_API_URL + `/appointment/${appointmentId}/cancel`)
    return response.data
  } catch (error) {
    throw Error('ERROR cancelAppointment() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function listAdilJuristAppointmentWithAvailability (date) {
  try {
    store.dispatch('SET_LOADING', true)
    const formatedDate = formatDate(date)
    const response = await axios.get(process.env.VUE_APP_API_URL + `/appointment/listAdilJuristAppointmentWithAvailability/${formatedDate}`)
    if (response.data && response.data.code && response.data.code === 'error') {
      throw response.data.label
    }
    return response.data
  } catch (error) {
    throw Error('ERROR listAdilJuristAppointmentWithAvailability : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

const formatDate = (date) => {
  return date.getFullYear() +
  '-' + ((date.getMonth() + 1) < 10 ? '0' : '') +
  (date.getMonth() + 1) + '-' +
  (date.getDate() < 10 ? '0' : '') +
  date.getDate()
}
