import Vue from 'vue'
import VueRx from 'vue-rx'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import vueDebounce from 'vue-debounce'
import Notifications from 'vue-notification'
import Vuelidate from 'vuelidate'
import '@/services/interceptors.js'
import 'dayjs/locale/fr'
import dayjs from 'dayjs'
import InlineSvg from 'vue-inline-svg'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    sm: 768,
    lg: Infinity
  },
  defaultBreakpoint: 'lg' // customize this for SSR
})

Vue.component('inline-svg', InlineSvg)

dayjs.locale('fr')
// dayjs().utcOffset(2)

Vue.use(Vuelidate)
Vue.use(vueDebounce)
Vue.use(Notifications)
Vue.use(VueRx)
Vue.use(VueMaterial)

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('date', function (value) {
  if (!value) return ''
  value = dayjs(value.toString())
  return value.format('DD/MM/YYYY')
})

Vue.filter('time', function (value) {
  if (!value) return ''
  value = dayjs(value.toString())
  return value.format('HH:mm')
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
