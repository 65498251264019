<template>
  <button
    class="dc-button"
    :disabled="disabled"
    :class="getClass()"
    type="button"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </button>
</template>
<script>
export default {
  name: 'DcButton',
  props: {
    variant: {
      type: String,
      default: 'default',
      description:
        'valeurs : default, link, link-menu, primary-small, secondary-small'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getClass () {
      let result = null
      switch (this.variant) {
      case 'link':
        result = 'dc-button--link'
        break
      case 'link-menu':
        result = 'dc-button--link-menu'
        break
      case 'link-menu-inactive':
        result = 'dc-button--link-menu-inactive'
        break
      case 'link-menu-current':
        result = 'dc-button--link-menu-current'
        break
      case 'primary-small':
        result = 'dc-button--small'
        break
      case 'secondary':
        result = 'dc-button--secondary'
        break
      case 'secondary-small':
        result = 'dc-button--secondary-small'
        break
      case 'outline':
        result = 'dc-button--outline'
        break
      case 'warning':
        result = 'dc-button--warning'
        break
      default:
        result = null
      }
      return result
    }
  }
}
</script>
<style lang="scss">
.dc-button {
  display: inline-block;
  background-color: $blue;
  height: 6.8rem;
  // min-width: 17.9rem;
  width: 100%;
  border: 0;
  font-size: 1.6rem;
  font-weight: $font-weight-bold;
  letter-spacing: 1.35px;
  text-align: center;
  color: $light-grey-2;
  border-radius: 1rem;
  transition: all 0.3s ease;
  padding: 2.5rem;
  cursor: pointer;
  // box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  //   0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  &:hover {
    background-color: lighten($blue, 4%);
  }
  &:disabled {
    background-color: lighten($blue, 10%);
    box-shadow: none;
    cursor: not-allowed;
  }
  &--outline {
    background-color: $light;
    border: solid 2px $blue;
    color: $blue;
    box-shadow: none;
    width: 100%;
    &:hover {
      background-color: $light;
      color: lighten($blue, 12%);
      border: solid 2px lighten($blue, 12%);
    }
    &:disabled {
      color: $light-grey-3;
      background-color: lighten($light-grey-3, 30%);
      border: solid 2px $light-grey-3;
    }
  }
  &--small {
    height: 3rem;
    max-width: 10.3rem;
    font-size: 1.2rem;
  }
  &--secondary-small {
    height: 3rem;
    // max-width: 10.3rem;
    font-size: 1.2rem;
    background-color: $orange;
    min-width: 2.7rem;
    padding: 0 1rem 0 1rem;
    &:hover {
      background-color: lighten($orange, 10%);
    }
    .md-icon {
      color: $light !important;
      font-weight: $font-weight-regular;
    }
  }
  &--secondary {
    background-color: $orange;
    &:hover {
      background-color: lighten($orange, 10%);
    }
  }
  &--warning {
    background-color: $light-grey-3;
    &:hover {
      background-color: lighten($light-grey-3, 10%);
    }
  }
  &--link {
    background-color: transparent;
    height: 3.4rem;
    width: 17.9rem;
    border: 0;
    font-size: 1.8rem;
    font-weight: $font-weight-bold;
    letter-spacing: 1.35px;
    text-align: center;
    color: $blue;
    box-shadow: none;
    @media only screen and (max-width: 768px) {
      color: $light;
    }
    &:hover {
      background-color: transparent;
      color: darken($blue, 10%);
    }
  }
  &--link-menu {
    font-size: 1.6rem;
    font-weight: $font-weight-regular;
    letter-spacing: 1.2px;
    text-align: left;
    color: $dark !important;
    background-color: transparent;
    height: 3.4rem;
    width: 17.9rem;
    border: 0;
    box-shadow: none;
    &:hover {
      background-color: transparent;
      color: lighten($dark, 10%);
    }
  }
  &--link-menu-current {
    font-size: 1.6rem;
    font-weight: $font-weight-regular;
    letter-spacing: 1.2px;
    text-align: left;
    color: $blue;
    background-color: transparent;
    height: 3.4rem;
    width: 17.9rem;
    border: 0;
    box-shadow: none;
    &:hover {
      background-color: transparent;
      color: darken($blue, 10%);
    }
  }
}
</style>
