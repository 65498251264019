import axios from 'axios'
import store from '@/store'

export async function getRtt (userId, startDate, endDate) {
  try {
    store.dispatch('SET_LOADING', true)

    const querySrings = []
    if (userId) querySrings.push(`adviser=${userId}`)
    if (startDate) querySrings.push(`startDate=${startDate}`)
    if (endDate) querySrings.push(`endDate=${endDate}`)

    let separator = '?'
    let query = ''
    querySrings.forEach(q => {
      query += separator + q
      if (separator === '?') {
        separator = '&'
      }
    })

    const response = await axios.get(`${process.env.VUE_APP_API_URL}/report/getRtt${query}`)
    return response.data
  } catch (error) {
    throw Error('ERROR reportRtt : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}
