<template>
  <div class="permanence-create-view">
    <dc-stepper
      :steps="steps"
      @active-step="setActiveStep"
    />
    <template v-if="editionPlace || !placeId">
      <div class="permanence-create-view__form">
        <component
          ref="childForm"
          :is="activeStep.component"
          :is-adil="isAdil"
          :editionPlace="steps[currentstep].model"
          @next="nextStep"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DcStepper from '@/components/ui/Stepper'
import Horaires from '@/views/Admin/Permanences/steps/horaires.vue'
import Informations from '@/views/Admin/Permanences/steps/informations.vue'
import Juristes from '@/views/Admin/Permanences/steps/juristes.vue'
import Thematiques from '@/views/Admin/Permanences/steps/thematiques.vue'
import Banniere from '@/views/Admin/Permanences/steps/banniere.vue'
import { addPlace, updatePlace, getPlace } from '@/services/place.service.js'

export default {
  name: 'PermanenceCreateView',
  components: {
    DcStepper
  },
  props: {
    isAdil: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      placeId: null,
      place: null,
      currentstep: 0,
      steps: [
        { index: 0, label: 'Informations', icon: 'ICONE-INFO', component: Informations, active: true, valid: false, disabled: false, model: null },
        { index: 1, label: 'Horaires', icon: 'ICONE-HORAIRE', component: Horaires, active: false, valid: false, disabled: true, model: null },
        { index: 2, label: 'Thématique', icon: 'ICONE-THEMATIQUE', component: Thematiques, active: false, valid: false, disabled: true, model: null },
        { index: 3, label: 'Juriste', icon: 'ICONE-JURISTE', component: Juristes, active: false, valid: false, disabled: true, model: null }
      ]
    }
  },
  computed: {
    ...mapGetters({
      editionPlace: 'editionPlace'
    }),
    activeStep () {
      return this.steps.find(s => s.active === true)
    }
  },
  methods: {
    ...mapActions({
      setEditionPlace: 'SET_EDITION_PLACE',
      clearEditionPlace: 'CLEAR_EDITION_PLACE'
    }),
    nextStep (model) {
      this.steps[this.currentstep].valid = true
      this.steps[this.currentstep].model = model.model
      if (this.steps[this.currentstep + 1] && !model.save) {
        this.steps[this.currentstep + 1].disabled = false
        this.setActiveStep(this.currentstep + 1)
      } else {
        this.save()
      }
    },
    setActiveStep (index) {
      this.steps.forEach(s => {
        s.active = false
      })
      this.steps[index].active = true
      this.currentstep = index
    },
    async save () {
      if (
        this.steps[0].model &&
        this.steps[0].valid
      ) {
        const model = {
          id: this.placeId,
          name: this.steps[0].model.name,
          address1: this.steps[0].model.address1,
          address2: this.steps[0].model.address2,
          address3: this.steps[0].model.address3,
          postalCode: this.steps[0].model.postalCode,
          city: this.steps[0].model.city,
          duration: this.steps[0].model.duration,
          isActive: true,
          isAdil: this.isAdil,
          rttBonus: this.steps[0].model.rtt,
          users: this.steps[3] ? this.steps[3].model : null,
          openHours: this.steps[1] ? this.steps[1].model : null,
          thematics: this.steps[2] ? this.steps[2].model : null,
          phone: this.steps[0].model.phone,
          publicDisplay: this.steps[0].model.publicDisplay,
          banner: this.isAdil ? this.steps[4].model : null
        }
        try {
          if (this.placeId) {
            await updatePlace(model)
          } else {
            await addPlace(model)
          }
          if (this.isAdil) {
            this.$router.push({
              name: 'adil'
            })
          } else {
            this.$router.push({
              name: 'permanences'
            })
          }
        } catch (error) {
          // console.error(error)
        }
      }
    }
  },
  async mounted () {
    this.placeId = +this.$route.params.id
    if (this.isAdil) {
      this.steps.push({ index: 4, label: 'Banniere', icon: 'ICONE-BANNIERE', component: Banniere, active: false, valid: false, disabled: true, model: null })
    }

    if (this.placeId) {
      try {
        this.place = await getPlace(this.placeId)
        this.setEditionPlace(this.place)
        this.steps[0].model = this.place
        this.steps[1].model = this.place.openHours
        this.steps[2].model = this.place.thematics
        this.steps[3].model = this.place.users
        if (this.isAdil) {
          // '' pour ne pas avoir de null afin de savoir si c'est une modification ou un ajout
          this.steps[4].model = this.place.banner || ''
        }
      } catch (error) {
        // console.error(error)
      }
    }
  },
  beforeDestroy: function () {
    this.clearEditionPlace()
  }
}
</script>

<style lang="scss">
.permanence-create-view {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: $light-grey-blue;
  background: linear-gradient(90deg, $blue $stepper-left, $light-grey-blue $stepper-left);
  &__form {
    position: absolute;
    top: 50%;
    right: 2%;
    transform: translateY(-50% );
    width: 110.3rem;
    max-height: 80%;
    overflow-y: auto;
    border-radius: 1rem;
    padding: 8rem;
    box-shadow: -7px 12.1px 25.4px 1.6px rgba(0, 0, 0, 0.14);
    background-color: $light;
    h1 {
      font-size: 5rem;
      font-weight: $font-weight-bold;
      letter-spacing: 3.75px;
      color: $dark;
    }
  }
}
</style>
