
import axios from 'axios'
import store from '@/store'

export async function getAllPlaces () {
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.get(process.env.VUE_APP_API_URL + '/place?type=')
    return response.data
  } catch (error) {
    throw Error('ERROR getAllPlaces() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function getPlacesByType (type) {
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.get(process.env.VUE_APP_API_URL + `/place?type=${type}`)
    return response.data
  } catch (error) {
    throw Error('ERROR getPlacesByType() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function getPlace (placeId) {
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.get(process.env.VUE_APP_API_URL + `/place/${placeId}`)
    return response.data
  } catch (error) {
    throw Error('ERROR getPlace() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function placeQuery (query) {
  try {
    const response = await axios.get(process.env.VUE_APP_API_URL + `/place/autocomplete?query=${query}`)
    return response.data
  } catch (error) {
    throw Error('ERROR placeQuery(' + query + ') : ' + error)
  }
}

export async function placeQueryAuth (query) {
  try {
    const response = await axios.get(process.env.VUE_APP_API_URL + `/place/authautocomplete?query=${query}`)
    return response.data
  } catch (error) {
    throw Error('ERROR placeQueryAuth(' + query + ') : ' + error)
  }
}

export async function deletePlace (placeId) {
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.delete(process.env.VUE_APP_API_URL + `/place/${placeId}`)
    return response.data
  } catch (error) {
    throw Error('ERROR deletePlace() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function addPlace (place) {
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.post(process.env.VUE_APP_API_URL + '/place', place)
    return response.data
  } catch (error) {
    throw Error('ERROR addPlace() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function updatePlace (place) {
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.post(process.env.VUE_APP_API_URL + '/place/update', place)
    return response.data
  } catch (error) {
    throw Error('ERROR updatePlace() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function changePlaceStatus (placeId) {
  try {
    const response = await axios.post(process.env.VUE_APP_API_URL + `/place/${placeId}/toggle-activation`)
    return response.data
  } catch (error) {
    throw Error('ERROR changePlaceStatus() : ' + error)
  }
}
