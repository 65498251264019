<template>
  <div class="dc-select">
    <div class="dc-select__field" :class="{'dc-select__field--error': invalid}">
        <select
          :id="selectId"
          v-bind="$attrs"
          :value="value"
          @input="update"
        >
          <option :value="null">{{ placeholder }}</option>
          <template v-for="(option, index) in options">
            <option
              :key="`option-${index}`"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </template>
        </select>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DcSelect',
  props: {
    value: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    selectId: {
      type: String,
      required: true
    },
    invalid: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  methods: {
    update (event) {
      this.$emit('input', event.target.value)
    }
  }
}
</script>
<style lang="scss">
.dc-select {
  position: relative;
  width: 100%;
  height: $input-height-regular;
  background-color: transparent;
  &__field {
    width: 100%;
    height: $input-height-regular;
    border: solid 2px $dark-grey;
    border-radius: 1rem;
    overflow-y: hidden;
    &:hover, &:focus-within {
      border: solid 2px lighten($dark-grey, 12%);
    }
    &--error {
      border: solid 2px $red;
    }
  }
  select {
    width: 100%;
    height: $input-height-regular;
    border: 0;
    font-size: 2rem;
    padding-left: 1rem;
    &::placeholder {
      font-size: 2rem;
      color: $light-grey-3;
    }
  }
}
</style>
