<template>
  <div>
    Déconnexion
  </div>
</template>
<script>
import { logout } from '@/services/user.service.js'

export default {
  name: 'Logout',
  async mounted () {
    await logout()
    this.$router.push({
      name: 'home'
    })
  }
}
</script>
