<template>
  <form class="thematic-edit-form" autocomplete="off">
    <div class="row">
      <div class="col-5">
        <label for="active">Active</label>
        <dc-switch id="active" v-model="$v.active.$model"></dc-switch>
      </div>
      <div class="col-1"></div>
      <div class="col-5">
        <label for="color">Couleur</label>
        <dc-input
          inputId="color" type="color"
          v-model.trim="$v.color.$model"
          :invalid="$v.color.$error" />
      </div>
    </div>
    <div class="row">
     <div class="col-11">
        <label for="category">Categorie</label>
        <dc-input
          inputId="category"
          append-icon="add"
          v-model="searchCategory"
          v-debounce:100ms="onChange"
          @icon-click="openPopin"
          :auto-complete-values="filteredCategory"
          @value-selected="addCategory"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <label for="name">Nom</label>
        <dc-input
          inputId="name"
          v-model.trim="$v.name.$model"
          :invalid="$v.name.$error" />
      </div>
      <div class="col-1"></div>
      <div class="col-5">
        <label for="duration">Durée</label>
        <dc-input
          inputId="duration"
          v-model.trim="$v.duration.$model"
          :invalid="$v.duration.$error" />
      </div>
    </div>
    <div class="row">
      <div class="col-11">
        <label for="mandatory-documents">Documents obligatoires</label>
        <textarea id="mandatory-documents" v-model.trim="$v.neededDocuments.$model" />
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <label for="file" class="label-file">
          Importer les codes
        </label>
        <input type="file" id="file" ref="file" @change="handleFileUpload()"/>
      </div>
    </div>
    <template v-if="code">
      <div class="row">
        <div class="col-12">
          <label class="label-collapse-codes" @click="showCodes = !showCodes">
            Afficher les codes
            <md-icon v-if="!showCodes">keyboard_arrow_down</md-icon>
            <md-icon v-if="showCodes">keyboard_arrow_up</md-icon>
          </label>
        </div>
      </div>
      <div class="row" v-if="showCodes">
        <div class="col-12">
          <ul>
            <li v-for="(cde, cdeIdx) in code.split(';')" :key="`code-${cdeIdx}}`">
              {{ cde }}
            </li>
          </ul>
        </div>
      </div>
    </template>
    <div class="hr"></div>
    <div class="actions-section">
      <div>
        <dc-button variant="primary" @click.prevent="save">
          Enregistrer
        </dc-button>
      </div>
      <div class="spacer"></div>
      <div>
        <dc-button variant="secondary" @click.prevent="cancel">Annuler</dc-button>
      </div>
    </div>
    <dc-popin
      ref="categoryAddCategoryFormPopin"
      :showButtons="false"
      :show="showCategoryPopin"
      @on-close="closePopin"
      title="Création d'une nouvelle categorie"
    >
      <category-edit-form
        @save="onSaveCategory"
        @error="onErrorCategory"
        @cancel="closePopin"
      />
    </dc-popin>
  </form>
</template>

<script>
import DcButton from '@/components/ui/Button'
import DcInput from '@/components/ui/Input'
import DcSwitch from '@/components/ui/Switch'
import DcPopin from '@/components/ui/Popin'
import CategoryEditForm from '@/components/category/edit-form'

import { required } from 'vuelidate/lib/validators'
import { addThematic, updateThematic, uploadCsv } from '@/services/thematic.service.js'
import { getCategories } from '@/services/category.service.js'

export default {
  name: 'ThematicsEditForm',
  components: {
    DcInput,
    DcButton,
    DcSwitch,
    DcPopin,
    CategoryEditForm
  },
  data () {
    return {
      showCodes: false,
      nbCharAutoComplete: 2,
      listCategories: null,
      filteredCategory: null,
      selectedCategory: null,
      searchCategory: null,
      showCategoryPopin: false,
      name: null,
      color: '#000000',
      duration: '15',
      neededDocuments: null,
      code: null,
      active: true,
      file: null
    }
  },
  props: {
    thematicData: {
      type: Object,
      default: null
    }
  },
  methods: {
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      try {
        let thematicId = null
        if (this.thematicData == null) {
          const newThematic = await addThematic({
            category: this.selectedCategory,
            name: this.name,
            color: this.color,
            duration: this.toNumber(this.duration),
            neededDocuments: this.neededDocuments,
            code: this.code,
            active: this.active
          })
          thematicId = newThematic.id
        } else {
          await updateThematic({
            id: this.thematicData.id,
            category: this.selectedCategory,
            name: this.name,
            color: this.color,
            duration: this.toNumber(this.duration),
            neededDocuments: this.neededDocuments,
            code: this.code,
            active: this.active
          })
          thematicId = this.thematicData.id
        }
        if (this.$refs.file.files && this.$refs.file.files.length > 0) {
          await uploadCsv(this.file, thematicId)
        }
        this.$emit('save')
      } catch (error) {
        // console.error(error)
        this.$emit('error', error)
      }
    },
    cancel () {
      this.$emit('cancel')
    },
    handleFileUpload () {
      this.file = this.$refs.file.files[0]
    },
    toNumber (value) {
      if (value == null || value === '') {
        return null
      }

      var num = Number.parseInt(value)
      if (isNaN(num)) {
        return null
      } else {
        return num
      }
    },
    async loadCategories () {
      let categories = null
      try {
        categories = await getCategories()
      } catch (error) {
        // console.error(error)
      }
      return categories
    },
    onChange (event) {
      if (this.listCategories && event && event.length >= this.nbCharAutoComplete) {
        this.filteredCategory = this.listCategories
          .map(c => {
            return {
              label: c.name.trim(),
              value: c.id
            }
          })
          .filter(c => {
            if (c.label.toLowerCase().includes(event.toLowerCase())) {
              return true
            } else {
              return false
            }
          })
      }
    },
    openPopin () {
      this.showCategoryPopin = true
    },
    addCategory (event) {
      this.selectedCategory = this.listCategories.find(u => u.id === event.value)
      this.searchCategory = event.label
    },
    closePopin () {
      this.showCategoryPopin = false
    },
    async onSaveCategory (newCategory) {
      this.searchCategory = newCategory.name
      this.selectedCategory = newCategory
      this.closePopin()
    },
    onErrorCategory (value) {
      this.$notify({
        group: 'notification-group',
        type: 'error',
        duration: 10000,
        title: 'Echec',
        text: 'L\'enregistrement n\'a pas abouti'
      })
      this.closePopin()
    }
  },
  validations: {
    name: {
      required
    },
    color: {
      required
    },
    duration: {},
    neededDocuments: {},
    code: {},
    active: {}
  },
  async mounted () {
    if (this.thematicData != null) {
      this.listCategories = await this.loadCategories()
      this.name = this.thematicData.name
      this.color = this.thematicData.color
      this.duration = this.thematicData.duration != null ? this.thematicData.duration.toString() : null
      this.neededDocuments = this.thematicData.neededDocuments
      this.code = this.thematicData.code
      this.active = this.thematicData.active
      this.selectedCategory = this.thematicData.category
      this.searchCategory = this.selectedCategory.name
    }
  }
}
</script>

<style lang="scss">
.thematic-edit-form {
  ul {
    li {
      font-size: 1.3rem;
    }
  }
  .label-collapse-codes {
    cursor: pointer;
    .md-icon {
      color: $dark !important;
    }
  }
  #file {
    display: none;
  }
  .label-file {
    display: inline-block;
    background-color: $orange;
    height: 6.8rem;
    width: 100%;
    border: 0;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 1.35px;
    text-align: center;
    color: $light-grey-2;
    border-radius: 1rem;
    transition: all 0.3s ease;
    padding: 2.5rem;
    cursor: pointer;
  }
  .actions-section {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    background-color: $light;
    width: 100%;
    .dc-button {
      margin: 1rem;
    }
  }
}
</style>
