<template>
  <form class="category-edit-form" autocomplete="off">
    <div class="row">
      <div class="col-11">
        <label for="name">Nom</label>
        <dc-input
          inputId="color" type="text"
          v-model.trim="$v.name.$model"
          :invalid="$v.name.$error" />
      </div>
    </div>
    <div class="hr"></div>
    <div class="actions-section">
      <div>
        <dc-button variant="primary" @click.prevent="save">
          Enregistrer
        </dc-button>
      </div>
      <div class="spacer"></div>
      <div>
        <dc-button variant="secondary" @click.prevent="cancel">Annuler</dc-button>
      </div>
    </div>
  </form>
</template>

<script>
import DcButton from '@/components/ui/Button'
import DcInput from '@/components/ui/Input'

import { required } from 'vuelidate/lib/validators'
import { addCategory } from '@/services/category.service.js'

export default {
  name: 'CategoryEditForm',
  components: {
    DcInput,
    DcButton
  },
  data () {
    return {
      name: null
    }
  },
  props: {
    categoryData: {
      type: Object,
      default: null
    }
  },
  methods: {
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      try {
        if (this.categoryData == null) {
          const category = await addCategory({
            name: this.name
          })
          this.$emit('save', category)
        }
      } catch (error) {
        // console.error(error)
        this.$emit('error', error)
      }
    },
    cancel () {
      this.$emit('cancel')
    }
  },
  validations: {
    name: {
      required
    }
  },
  async mounted () {
    if (this.categoryData != null) {
      this.name = this.categoryData.name
    }
  }
}
</script>

<style lang="scss">
.category-edit-form {
  &__error {
    color: $red;
    font-size: 1.1rem;
    font-weight: $font-weight-bold;
  }
  .actions-section {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    background-color: $light;
    width: 100%;
    .dc-button {
      margin: 1rem;
    }
  }
}
</style>
