<template>
  <full-calendar class=".fc .fc-timegrid-col.fc-day-today"
    ref="appointmentsCalendar"
    v-on="$listeners"
    v-bind="$attrs"
    :options="calendarOptions"
  />
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr'

export default {
  name: 'DcFullcalendar',
  components: {
    FullCalendar
  },
  data () {
    return {
      calendarOptions: {
        plugins: [timeGridPlugin, interactionPlugin],
        initialView: 'timeGridWeek',
        businessHours: {
          daysOfWeek: [1, 2, 3, 4, 5],
          startTime: '09:00',
          endTime: '19:00'
        },
        slotMinTime: '09:00',
        slotMaxTime: '19:00',
        titleFormat: { year: 'numeric', month: 'long', day: 'numeric' },
        locale: frLocale,
        height: '100%',
        allDaySlot: false,
        weekends: false,
        headerToolbar: {
          left: 'prev',
          center: 'title',
          right: 'next'
        },
        slotDuration: '00:30:00',
        eventBackgroundColor: '#d5edf9',
        eventBorderColor: '#d5edf9',
        eventTextColor: '#868686',
        expandRows: true,
        eventClick: this.eventSelect
      }
    }
  },
  props: {
    events: {
      type: Array,
      required: true
    }
  },
  methods: {
    getValidRange () {
      const now = new Date()
      const end = new Date()
      end.setDate(end.getDate() + 15)
      return {
        start: now.getFullYear() + '-' + ((now.getMonth() + 1) < 10 ? '0' : '') + (now.getMonth() + 1) +
          '-' + (now.getDate() < 10 ? '0' : '') + now.getDate(),
        end: end.getFullYear() + '-' + ((end.getMonth() + 1) < 10 ? '0' : '') + (end.getMonth() + 1) +
          '-' + (end.getDate() < 10 ? '0' : '') + end.getDate()
      }
    },
    eventSelect (event) {
      this.$emit('selectAppointment', event)
    }
  },
  created () {
    const validRange = this.getValidRange()
    this.calendarOptions.validRange = validRange
    this.calendarOptions.events = this.events
  },
  mounted () {
    const calendarApi = this.$refs.appointmentsCalendar.getApi()
    calendarApi.next()
    calendarApi.gotoDate(this.events[0].start)
  }
}
</script>

<style lang="scss">
.fc-timegrid-axis {
  font-size: 1.6rem;
  font-weight: $font-weight-regular;
  letter-spacing: 0.32px;
  text-align: center;
  color: $grey2;
}
.fc-col-header-cell-cushion  {
  font-size: 1.6rem !important;
  font-weight: $font-weight-regular !important;
  letter-spacing: 0.32px !important;
  text-align: center;
  color: $grey2;
}
.fc {
  .fc-timegrid-slot-label {
    font-size: 1.6rem;
    font-weight: $font-weight-regular;
    letter-spacing: 0.32px;
    text-align: center;
    color: #a0a0a0;
    width: 50px;
  }
  .fc-toolbar {
    .fc-toolbar-chunk {
      .fc-button {
        padding: 0;
        margin: 2.3rem;
        &.fc-button-primary {
          background-color: transparent;
          border: 1px solid $blue;
          color: $blue;
          width: 2.2rem;
          height: 2.2rem;
        }
      }
    }
    h2 {
      color: #787878;
      font-size: 1.5rem;
    }
  }
  .fc-time-grid .fc-slats .fc-minor td {
    border: 0;
  }
  .fc-axis, .fc-day-header {
    font-size: 1.6rem;
    font-weight: $font-weight-regular;
    letter-spacing: 0.32px;
    text-align: left;
    color: $grey2;
  }
  .fc-day-header {
    text-align: center;
  }
  .fc-disabled-day, td.fc-today {
    background-color: $light;
  }
  .fc-event {
    cursor: pointer;
    text-align: center;
    color: $grey2;
    border: 0;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: darken($light-blue, 15%);
    }
  }
  .fc-event-main-frame {
    font-size:1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .fc-event-title-container{
      display: none;
    }
    .fc-event-time{
      font-size:inherit;
      font-weight: $font-weight-bold;
    }
  }
  table {
    tr {
      border: 0;
      height: 3rem;
      td {
        border: 0;
      }
    }
  }
}

.fc .fc-timegrid-col.fc-day-today{
  background-color: #FAFAFA;
}
</style>
