import Vue from 'vue'
import Vuex from 'vuex'
import { mutations } from '@/store/mutations'
import { actions } from '@/store/actions'
import { getters } from '@/store/getters'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    search: null,
    previousRoute: null,
    user: null,
    token: null,
    users: null,
    cities: null,
    thematics: null,
    currentThematic: null,
    menuItems: null,
    adilList: null,
    permanances: null,
    appointments: [],
    appointmentsHistory: null,
    appointmentsNext: null,
    editionPlace: null,
    isLoading: false,
    banner: null
  },
  mutations: mutations,
  actions: actions,
  getters: getters
})
