<template>
  <div>
    <div class="row">
      <slot name="header"></slot>
    </div>
    <table class="dc-table">
      <thead class="dc-table__head">
        <tr>
          <template v-for="(col, index) in columns">
            <th
              :key="`col-${index}`"
              @click="changeSorting(col)"
            >
              <slot :name="'header-' + col.name">{{col.label}}</slot>
            </th>
          </template>
        </tr>
      </thead>
      <tbody class="dc-table__body">
        <template v-for="(item, rowIndex) in pagedRows">
          <tr
            :key="`row-${rowIndex}`"
          >
            <template v-for="(col, colIndex) in columns">
              <td
                :key="`cell-${rowIndex}-${colIndex}`"
              >
                <slot :name="'cell-' + col.name" :[rowItemName]="item">
                  {{item[col.name]}}
                </slot>
              </td>
            </template>
          </tr>
            <slot :name="'row-expend'" :[rowItemName]="item">
              <template v-if="isExpendable">
                  {{item}}
              </template>
            </slot>
        </template>
      </tbody>
    </table>
    <div class="row">
      <slot name="footer"></slot>
    </div>
    <div class="dc-table__pagination" v-if="pages.length > 1">
      <template v-for="(page, index) of pages">
        <div
          :key="`page-${index}`"
          class="dc-table__page"
          :class="{'dc-table__page--current': page === currentPage}"
          @click="goToPage(page)"
        >
          {{ page }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DcTable',
  data () {
    return {
      isExpendable: false,
      sortColumn: null,
      sortedRows: null,
      currentPage: 1,
      pagedRows: null
    }
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    rows: {
      type: Array,
      default: () => []
    },
    rowItemName: {
      type: String,
      default: 'item'
    },
    itemsByPage: {
      type: Number,
      default: 10
    }
  },
  computed: {
    pages: function () {
      const pages = []
      if (this.rows != null) {
        const nb = Math.ceil(this.rows.length / this.itemsByPage)
        for (let index = 1; index <= nb; index++) {
          pages.push(index)
        }
      }
      return pages
    }
  },
  methods: {
    changeSorting (column) {
      if (column == null) {
        this.sortColumn = null
      } else {
        if (this.sortColumn === column) {
          column.sortOrder = column.sortOrder === 'ASC' ? 'DESC' : 'ASC'
        } else {
          this.sortColumn = column
        }
      }
      this.sort()
    },
    sort () {
      this.sortedRows = [...this.rows]
      if (this.sortColumn != null) {
        const comparisonFactor = this.sortColumn.sortOrder === 'ASC' ? 1 : -1
        this.sortedRows.sort((a, b) => {
          return this.compare(a[this.sortColumn.name], b[this.sortColumn.name]) * comparisonFactor
        })
      }
      this.paginate()
    },
    compare (a, b) {
      let comparison = 0
      if (a == null && b == null) {
        comparison = 0
      } else if (a == null) {
        comparison = -1
      } else if (b == null) {
        comparison = 1
      } else {
        const objA = a.toUpperCase()
        const objB = b.toUpperCase()

        if (objA > objB) {
          comparison = 1
        } else if (objA < objB) {
          comparison = -1
        }
      }
      return comparison
    },
    paginate () {
      if (this.sortedRows == null) {
        this.pagedRows = []
      } else {
        this.pagedRows = this.sortedRows.slice(((this.currentPage - 1) * this.itemsByPage), this.currentPage * this.itemsByPage)
      }
    },
    goToPage (page) {
      this.currentPage = page
      this.paginate()
    }
  },
  watch: {
    rows: {
      handler: function () {
        this.sort()
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
	.dc-table {
		width: 100%;
    border-collapse: collapse;

		&__head {
			font-size: 1.8rem;
			font-weight: $font-weight-bold;
			letter-spacing: 1.35px;
			text-align: left;
      color: $light-grey-3;
      border-bottom: 2px solid #e5e5e5;
			th {
				height: $table-row-height;
				cursor: pointer;
				&:hover {
					color: lighten( $light-grey-3, 15%);
				}
			}
		}

		&__body {
			font-size: 1.8rem;
			font-weight: $font-weight-bold;
			letter-spacing: 1.35px;
			text-align: left;
			color: $dark;
			tr {
				height: $table-row-height;
				cursor: pointer;
				&:hover {
					background-color: darken( $light, 10%);
				}
        td {
          padding: 1rem 1rem 1rem 0;
        }
			}
		}

    &__pagination {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3rem 0;
    }

    &__page {
      width: 30px;
      height: 30px;
      font-size: 1.5rem;
      border-radius: 10px;
      text-align: center;
      line-height: 28px;
      margin: 0 .5rem;
      cursor: pointer;
      background-color: $light;
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
      &--current {
        background-color: darken($light, 12%);
        box-shadow: none;
        cursor: default;
      }
      &:hover {
        background-color: darken($light, 10%);
      }
    }
	}
</style>
