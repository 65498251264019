<template>
  <div class="informations-view">
    <h1>Informations</h1>
    <div class="container">
      <div class="row">
        <div class="col-11">
          <label for="name">Désignation</label>
          <dc-input
            inputId="name"
            v-model="name"
            :invalid="$v.name.$error && !$v.name.required"
          />
          <div
            class="informations-view__error error-message"
            v-if="$v.name.$error && !$v.name.required"
          >
            Vous devez saisir une désignation
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <label for="address1">Adresse</label>
          <dc-input
            inputId="address1"
            v-model="address1"
            :invalid="$v.address1.$error && !$v.address1.required"
          />
          <div
            class="informations-view__error error-message"
            v-if="$v.address1.$error && !$v.address1.required"
          >
            Vous devez saisir une adresse
          </div>
        </div>
        <div class="col-1"></div>
        <div class="col-5">
          <label for="address2">Complément d'adresse</label>
          <dc-input
            inputId="address2"
            v-model="address2"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <label for="address3">Complément d'adresse (suite)</label>
          <dc-input
            inputId="address3"
            v-model="address3"
          />
        </div>
        <div class="col-1"></div>
        <div class="col-5">
          <label for="postalCode">Code postal</label>
          <dc-input
            inputId="postalCode"
            v-model="postalCode"
            :invalid="$v.postalCode.$error && !$v.postalCode.required"
          />
          <div
            class="informations-view__error error-message"
            v-if="$v.postalCode.$error && !$v.postalCode.required"
          >
            Vous devez saisir un code postal
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <label for="city">Ville</label>
          <dc-input
            inputId="city"
            v-model="city"
            :invalid="$v.city.$error && !$v.city.required"
          />
          <div
            class="informations-view__error error-message"
            v-if="$v.city.$error && !$v.city.required"
          >
            Vous devez saisir une ville
          </div>
        </div>
        <div class="col-7"></div>
      </div>
      <div class="row" v-if="!isAdil">
        <div class="col-5">
          <label for="duration">Durée (minutes)</label>
          <dc-input
            inputId="duration"
            type="number"
            v-model="duration"
            :invalid="$v.duration.$error && !$v.duration.required"
          />
          <div
            class="informations-view__error error-message"
            v-if="$v.duration.$error && !$v.duration.required"
          >
            Une durée doit être saisie
          </div>
        </div>
        <div class="col-7">
        </div>
      </div>
      <div class="row" v-if="!isAdil">
        <div class="col-5">
          <label for="rtt">Bonus temps RTT</label>
          <dc-input
            inputId="rtt"
            type="number"
            v-model="rtt"
          />
        </div>
        <div class="col-7"></div>
      </div>
      <div class="row">
        <div class="col-5" v-if="!isAdil">
          <label for="phone">Permanence Téléphonique</label>
          <dc-switch
          v-model="phone"
          id="phone"
        />
        </div>
        <div class="col-2" v-if="!isAdil"></div>
        <div class="col-5">
          <label for="publicDisplay">Afficher au grand public</label>
          <dc-switch
          v-model="publicDisplay"
          id="publicDisplay"
        />
        </div>
      </div>
      <div class="hr col-12"></div>
    </div>
    <div class="actions-section">
      <div>
        <dc-button
          variant="primary"
          @click.prevent="next(false)"
        >
        Suivant
        </dc-button>
      </div>
      <div class="spacer"></div>
      <div>
        <dc-button
        variant="secondary"
        @click.prevent="next(true)"
        >
        <template v-if="editionPlace !== null">
          Modifier {{ isAdil ? 'l\'Adil' : 'la permanence'}}
        </template>
        <template v-else>
          Créer {{ isAdil ? 'l\'Adil' : 'la permanence'}}
        </template>
        </dc-button>
      </div>
      <div class="spacer"></div>
      <div>
        <dc-button
          variant="warning"
          @click.prevent="cancel"
        >
        Annuler
        </dc-button>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
import DcButton from '@/components/ui/Button'
import DcInput from '@/components/ui/Input'
import DcSwitch from '@/components/ui/Switch'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'InformationsView',
  components: {
    DcInput,
    DcButton,
    DcSwitch
  },
  data () {
    return {
      name: null,
      address1: null,
      address2: null,
      address3: null,
      postalCode: null,
      city: null,
      duration: '60',
      rtt: null,
      isThematicDefined: false,
      phone: false,
      publicDisplay: true
    }
  },
  // computed: {
  //   ...mapGetters({
  //     editionPlace: 'editionPlace'
  //   })
  // },
  props: {
    isAdil: {
      type: Boolean,
      default: false
    },
    editionPlace: {
      type: Object,
      default: null
    }
  },
  methods: {
    next (save) {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      const values = {
        name: this.name,
        address1: this.address1,
        address2: this.address2,
        address3: this.address3,
        postalCode: this.postalCode,
        city: this.city,
        duration: this.toNumber(this.duration),
        rtt: this.rtt,
        isThematicDefined: this.isThematicDefined,
        phone: this.phone,
        publicDisplay: this.publicDisplay
      }
      this.$emit('next', {
        model: values,
        save: save
      })
    },
    cancel () {
      if (this.isAdil) {
        this.$router.push({
          name: 'adil'
        })
      } else {
        this.$router.push({
          name: 'permanences'
        })
      }
    },
    toNumber (value) {
      if (value == null || value === '') {
        return null
      }

      var num = Number.parseInt(value)
      if (isNaN(num)) {
        return null
      } else {
        return num
      }
    }
  },
  mounted () {
    if (this.editionPlace) {
      this.name = this.editionPlace.name
      this.address1 = this.editionPlace.address1
      this.address2 = this.editionPlace.address2
      this.address3 = this.editionPlace.address3
      this.postalCode = this.editionPlace.postalCode
      this.city = this.editionPlace.city
      this.duration = this.editionPlace.duration == null ? null : this.editionPlace.duration.toString()
      this.rtt = this.editionPlace.rttBonus
      this.phone = this.editionPlace.phone ? this.editionPlace.phone : false
      this.publicDisplay = this.editionPlace.publicDisplay
    }
  },
  validations: {
    name: {
      required
    },
    address1: {
      required
    },
    address2: {},
    address3: {},
    postalCode: {
      required
    },
    city: {
      required
    },
    duration: {
      required
    },
    rtt: {},
    isThematicDefined: {},
    phone: {},
    publicDisplay: {}
  }
}
</script>
<style lang="scss">
.informations-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .actions-section {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    background-color: $light;
    width: 100%;
    .dc-button {
      margin: 1rem;
    }
  }
}
</style>
