<template>
  <div class="appointment-select">
    <template v-if="place">
      <dc-title-deck>{{place.name}}</dc-title-deck>
      <div class="appointment-select__left">
        <div class="appointment-select__address">
          <inline-svg
            :src="require('@/assets/img/icons/ICONE-LIEU.svg')"
          />
          <div class="appointment-select__address-detail">
            <div>{{place.address1}}</div>
            <div v-if="place.address2">{{place.address2}}</div>
            <div v-if="place.address3">{{place.address3}}</div>
            <div>{{place.postalCode}} {{place.city}}</div>
          </div>
        </div>
      </div>
      <div class="appointment-select__right">
        <div class="appointment-select__calendar-wrapper">
          <dc-calendar
            :events="events"
            @selectAppointment="eventSelected"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import DcTitleDeck from '@/components/ui/TitleDeck'
import DcCalendar from '@/components/ui/Calendar'

export default {
  name: 'AppointmentSelect',
  components: {
    DcTitleDeck,
    DcCalendar
  },
  props: {
    place: {
      type: Object,
      required: true
    },
    events: {
      type: Array,
      required: true
    }
  },
  methods: {
    eventSelected (event) {
      this.$emit('selectAppointment', event)
    }
  }
}
</script>

<style lang="scss">

.appointment-select {
	position: relative;
  width: 122.1rem;
  min-height: 72rem;
  background-color: $light;
  margin-bottom: 4.8rem;
  animation: fromRight2 .8s ease backwards .3s;
	border-radius: 1rem;
	box-shadow: 4.8px 7.6px 12.2px 0.8px rgba(0, 0, 0, 0.07);
	&__left, &__right {
		display: inline-block;
		min-height: 72rem;
	}
	&__left {
		background-color: $light-grey;
		width: 29rem;
		border-radius: 1rem 0 0 1rem;
	}
	&__right {
		position: relative;
		width: calc(100% - 29rem);
		border-radius: 0 1rem 1rem 0;
	}
	&__calendar-wrapper {
		position: absolute;
		width: 100%;
		height: 100%;

	}
	&__address {
		position: absolute;
		top: 15rem;
    left: 5.8rem;
    width: 30rem;
    overflow-wrap: break-word;
		svg {
			width: 3.1rem;
      fill:$blue;
		}
	}
	&__address-detail {
		margin-top: 1.6rem;
		font-size: 2rem;
		font-weight: $font-weight-regular;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: 0.6px;
		text-align: left;
		color: $blue;
		width: 80%;
	}
}

@keyframes fromRight2 {
	0% {
		transform: translateX(100vw);
	}
	80% {
		transform: translateX(-10vw);
	}
	100% {
		transform: translateX(0);
	}
}
</style>
