<template>
  <div class="home-connected">
    <div class="home-connected__form">
      <template v-if="banner">
        <div class="home-connected__banner">
          <img v-bind:src="banner" />
        </div>
      </template>
      <h1>Prise de rendez-vous</h1>
      <search-appointments-form :horizontal="true"/>
      <h2>Rendez-vous actuel</h2>
      <template v-if="appointmentsNext && appointmentsNext.length > 0">
        <div v-for="(item, index) in appointmentsNext" :key="`appointment-next-${index}}`">
          <appointments-details-form
            :appointmentInfos="item"
            @show-infos="showInfos"
            @cancelAppointment="cancelAppointment"
          />
        </div>
      </template>
      <template v-else>
        <div class="home-connected__no-rdv">
          Aucun rendez-vous à venir.
        </div>
      </template>
      <h2>Historique des rendez-vous</h2>
      <template v-if="appointmentsHistory && appointmentsHistory.length > 0">
        <div v-for="(item, index) in appointmentsHistory" :key="`appointment-history-${index}}`">
          <appointments-details-form
            :appointmentInfos="item"
            :is-history="true"
            @show-infos="showInfos"
          />
        </div>
      </template>
      <template v-else>
        <div class="home-connected__no-rdv">
          Aucun rendez-vous passé.
        </div>
      </template>
    </div>
    <dc-popin
      ref="appointmentInfoForm"
      :showButtons="false"
      :show="showPopin"
      @on-close="showPopin = false"
      title="Informations"
    >
      <dc-appointment-info
        :appointment="selectedItem"
        @close="showPopin = false"
      />
    </dc-popin>
  </div>
</template>

<script>
import DcPopin from '@/components/ui/Popin'
import DcAppointmentInfo from '@/components/appointments/appointment-info.vue'
import SearchAppointmentsForm from '@/components/appointments/search-appointments.vue'
import AppointmentsDetailsForm from '@/components/appointments/appointment-detail.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HomeConnected',
  components: {
    SearchAppointmentsForm,
    AppointmentsDetailsForm,
    DcAppointmentInfo,
    DcPopin
  },
  data () {
    return {
      showPopin: false,
      selectedItem: null
    }
  },
  computed: {
    ...mapGetters({
      appointmentsHistory: 'appointmentsHistory',
      appointmentsNext: 'appointmentsNext',
      currentUser: 'user',
      banner: 'banner'
    })
  },
  methods: {
    ...mapActions({
      getAppointmentsNext: 'GET_APPOINTMENT_NEXT',
      getAppointmentsHistory: 'GET_APPOINTMENT_HISTORY',
      getBanner: 'GET_BANNER'
    }),
    showInfos (event) {
      this.selectedItem = event
      this.showPopin = true
    },
    cancelAppointment (event) {
      this.getAppointmentsNext(this.currentUser.id)
    }
  },
  mounted () {
    if (this.currentUser) {
      this.getAppointmentsNext(this.currentUser.id)
      this.getAppointmentsHistory(this.currentUser.id)
      this.getBanner(this.currentUser.id)
    } else {
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss">
.home-connected {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: $light-grey-blue;
  background: linear-gradient(90deg, $blue 38%, $light-grey-blue 38%);
  overflow-y: auto;
  &__no-rdv {
    width: 100%;
    padding: 4rem;
    font-size: 2rem;
    text-align: center;
  }
  &__form {
		position: absolute;
		z-index: 2;
		border-radius: 1rem;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 123.5rem;
		background-color: $light;
		box-shadow: 4.8px 7.6px 12.2px 0.8px rgba(0, 0, 0, 0.07);
		padding: 5rem;
    h1 {
      font-size: 4.1rem;
      font-weight: $font-weight-bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.23px;
      text-align: left;
      color: $blue;
    }
    h2 {
      font-size: 2.5rem;
      font-weight: $font-weight-bold;
      letter-spacing: 1.88px;
      text-align: left;
      color: $grey2;
    }
	}
  &__banner{
    width: 100%;
    text-align: center;
    padding-bottom: 2em;
    img{
      max-height: 90px;
      max-width: 728px;
    }
  }
}
</style>
