<template>
  <div id="app">
    <header class="header">
      <span class="header__logo">
        <router-link to="/home" tag="a">
          <img src="@/assets/img/LOGO-ADIL-SIMPLE.svg" title="Adil" alt="Adil">
        </router-link>
      </span>
      <span
        class="header__search"
        v-if="currentUser && currentUser.role !== 'CLIENT'"
      >
        <dc-input
          prependIcon="ICONE-RECHERCHE"
          :prependIconCustom="true"
          :small="true"
          v-model.trim="user"
          inputId="searchUser"
          @enter="sendRequest"
        ></dc-input>
      </span>
      <span class="header__button-group">

        <router-link to="/login">
          <dc-button
            v-if="!currentUser"
            class="header__button"
            variant="link"
          >
            Connexion
          </dc-button>
        </router-link>

        <router-link to="/subscribe">
          <dc-button
            v-if="!currentUser"
            class="header__button"
            variant="link"
          >
            Inscription
          </dc-button>
        </router-link>

        <dc-drop-down-menu
          v-if="currentUser && getMenuItems"
          :items="getMenuItems"
        >
          <span slot="menu-label">
            <img
              class="dc-dropdown-menu__label-icon"
              :src="require('@/assets/img/icons/ICONE-IDENT-PRIMARY.svg')"
            />
            <span class="dc-dropdown-menu__label-text">
              {{ currentUser.firstName | capitalize }} {{ currentUser.lastName | capitalize }}
            </span>
          </span>
        </dc-drop-down-menu>
        <span
          v-if="currentUser && getMenuItems"
          class="header__edit-user-icon"
        >
          <router-link
            :to="{ name: 'editAccount', params: { userId: currentUser.id }}"
          >
            <md-icon>create</md-icon>
          </router-link>
        </span>
      </span>
    </header>
    <transition name="slide">
      <router-view :key="$route.fullPath"/>
    </transition>
    <notifications
      group="notification-group"
      position="bottom right"
    />
    <div class="dc-loader" v-if="isLoading">
      <div>
        <img :src="require('@/assets/loading.gif')" alt="Chargement"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DcButton from '@/components/ui/Button'
import DcDropDownMenu from '@/components/ui/DropDownMenu'
import DcInput from '@/components/ui/Input'
import { userQuery } from '@/services/user.service.js'

export default {
  name: 'AppPriseDeRdv',
  components: {
    DcButton,
    DcDropDownMenu,
    DcInput
  },
  data () {
    return {
      nbCharAutoComplete: 2,
      autoCompleteOnClick: false,
      users: null,
      selectedUser: null,
      user: null,
      menuItems: [
        { route: '/logout', order: 9, label: 'Déconnexion', roles: [] },
        { route: '', order: 8, label: '' },
        { route: '/users', order: 3, label: 'Utilisateurs', roles: ['ADMIN'] },
        { route: '/planning', order: 1, label: 'Planning', roles: ['ADMIN', 'JURISTE', 'JURISTESUP', 'MAIRIE', 'PARTENAIRE'] },
        { route: '/adil', order: 4, label: 'Adil', roles: ['ADMIN'] },
        { route: '/permanences', order: 5, label: 'Permanences', roles: ['ADMIN', 'JURISTESUP'] },
        { route: '/thematics', order: 2, label: 'Thématiques', roles: ['ADMIN', 'JURISTESUP'] },
        { route: '/home', order: 0, label: 'Accueil', roles: ['CLIENT'] },
        { route: '/charts', order: 6, label: 'Statistiques', roles: ['ADMIN', 'JURISTESUP'] },
        { route: '/rtt', order: 7, label: 'RTT', roles: ['ADMIN', 'JURISTESUP'] }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading',
      currentUser: 'user',
      getMenuItems: 'menuItems'
    }),
    isMobile () {
      return !!this.$isMobile
    }
  },
  methods: {
    ...mapActions({
      setUser: 'SET_USER',
      setMenuItems: 'SET_MENU_ITEMS',
      setSearch: 'SET_SEARCH'
    }),
    sendRequest () {
      this.setSearch(this.user)
      if (this.$route.name !== 'usersSearch') {
        if (this.user) {
          this.$router.push({
            name: 'usersSearch'
          })
        }
      }
    },
    selectUser (value) {
      this.selectedUser = value
      this.user = value.label
      this.sendRequest()
    },
    async onChange (event) {
      let nbChars = this.nbCharAutoComplete
      if (!event || event === '') {
        nbChars = 0
        this.autoCompleteOnClick = true
      } else {
        this.autoCompleteOnClick = false
      }

      if (event.length >= nbChars) {
        try {
          const response = await userQuery(event)
          this.users = response
            .map(c => {
              return {
                label: c.firstName.trim() + '  ' + c.lastName,
                value: c
              }
            })
            .sort((a, b) => {
              if (a.lastName > b.lastName) {
                return 1
              } else {
                return -1
              }
            })
        } catch (error) {
          // console.error(error)
        }
      }
    }
  },
  created () {
    this.$material.locale.dateFormat = 'dd/MM/yyyy'
    this.$material.locale.days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
    this.$material.locale.shortDays = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
    this.$material.locale.shorterDays = ['D', 'L', 'M', 'M', 'J', 'V', 'S']
    this.$material.locale.months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre']
    this.$material.locale.shortMonths = ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aoû', 'Sept', 'Oct', 'Nov', 'Dec']
    this.$material.locale.shorterMonths = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'Se', 'O', 'N', 'D']
    this.$material.locale.firstDayOfAWeek = 1
  },
  mounted () {
    this.setMenuItems(this.menuItems.sort((a, b) => a.order - b.order))
  }
}
</script>

<style lang="scss">
#app {
  position: relative;
  .dc-loader {
    background-color: rgba($light, 0.5);
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(8px);
  }
  .header {
    position: absolute;
    top: -100px;
    left: 0;
    width: 100vw;
    height: $header-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 4;
    background-color: transparent;
    padding-right: 6.9rem;
    padding-left: 6.9rem;
    padding-top: 2.3rem;
    animation: moveDown .5s ease forwards;
    &__edit-user-icon {
      .md-icon {
        color: $grey2;
        font-size: 2rem !important;
        cursor: pointer;
        vertical-align: top !important;
        height: inherit !important;
        transition: all .3s ease;
        margin-left:0.8rem;
        &:hover {
          color: lighten($grey2, 12%);
        }
      }
    }
    &__button-group {
      padding-top: 1rem;
    }
    &__search {
      width: 75%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .dc-input {
        width: 30rem;
        &__field {
          border-radius: 0;
          border: 0;
          svg {
            height: 1.4rem;
            fill: $dark !important;
          }
          input {
            height: 3.5rem;
            border-bottom: 1px solid $dark;
          }
        }
      }
    }
    &__logo {
      img {
        height: 4.2rem;
      }
    }
    @media only screen and (max-width: 768px) {
      padding-right: 2rem;
      padding-left: 2rem;
    }
  }
  .dc-dropdown-menu__label-icon {
    height: 1.3rem;
    margin-right: 1rem;
  }
}

@keyframes moveDown {
  0% {
    top: -100px;
  }
  80% {
    top: 10px;
  }
  100% {
    top: 0;
  }
}

// page transition animations
.slide-enter {
  animation: slideIn .3s;
}
.slide-leave-to {
  animation: slideOut .3s;
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

</style>
