
import { getAllThematics } from '@/services/thematic.service.js'
import { login, getUserList, getBannerForUser } from '@/services/user.service.js'
import { addAppointment, listAppointmentNextForUser, listAppointmentHistoryForUser, updateAppointment } from '@/services/appointment.service.js'

export const actions = {
  SET_SEARCH ({ commit }, search) {
    commit('SET_SEARCH', search)
  },
  SET_LOADING ({ commit }, isLoading) {
    commit('SET_LOADING', isLoading)
  },
  SET_PREVIOUS_ROUTE ({ commit }, route) {
    commit('SET_PREVIOUS_ROUTE', route)
  },
  CLEAR_EDITION_PLACE ({ commit }) {
    commit('CLEAR_EDITION_PLACE')
  },
  SET_EDITION_PLACE ({ commit }, place) {
    commit('SET_EDITION_PLACE', place)
  },
  SET_MENU_ITEMS ({ commit }, menuItems) {
    commit('SET_MENU_ITEMS', menuItems)
  },
  async LOGOUT ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_USER', null)
      commit('SET_PREVIOUS_ROUTE', null)
      resolve()
    })
  },
  async LOGIN ({ commit }, loginData) {
    try {
      const response = await login(loginData.email, loginData.password)
      commit('SET_USER', response.user)
      commit('SET_TOKEN', response.token)
    } catch (error) {
      throw Error('ERROR ACTION LOGIN : ' + error)
    }
  },
  SET_USER ({ commit }, user) {
    commit('SET_USER', user)
  },
  async SET_USERS ({ commit }) {
    let users = null
    try {
      users = await getUserList()
      commit('SET_USERS', users)
    } catch (error) {
      throw Error('ERROR ACTION SET_USERS : ' + error)
    }
  },
  async SET_ALL_THEMATICS ({ commit }) {
    let thematics = null
    try {
      thematics = await getAllThematics()
      commit('SET_THEMATICS', thematics)
    } catch (error) {
      throw Error('ERROR ACTION SET_ALL_THEMATICS : ' + error)
    }
  },
  SET_CURRENT_THEMATIC ({ commit }, thematicId) {
    commit('SET_CURRENT_THEMATIC', thematicId)
  },
  async SAVE_APPOINTMENT ({ commit }, appointment) {
    try {
      await addAppointment(appointment)
      commit('SAVE_APPOINTMENT', appointment)
    } catch (error) {
      throw Error('ERROR SAVE_APPOINTMENT : ' + error)
    }
  },
  async UPDATE_APPOINTMENT ({ commit }, appointment) {
    try {
      await updateAppointment(appointment)
      commit('UPDATE_APPOINTMENT', appointment)
    } catch (error) {
      throw Error('ERROR UPDATE_APPOINTMENT : ' + error)
    }
  },
  async GET_APPOINTMENT_NEXT ({ commit }, userId) {
    let appointments = null
    try {
      appointments = await listAppointmentNextForUser(userId)
      commit('SET_APPOINTMENT_NEXT', appointments)
    } catch (error) {
      throw Error('ERROR GET_APPOINTMENT_NEXT : ' + error)
    }
  },
  async GET_APPOINTMENT_HISTORY ({ commit }, userId) {
    let appointments = null
    try {
      appointments = await listAppointmentHistoryForUser(userId)
      commit('SET_APPOINTMENT_HISTORY', appointments)
    } catch (error) {
      throw Error('ERROR GET_APPOINTMENT_HISTORY : ' + error)
    }
  },
  async GET_BANNER ({ commit }, userId) {
    let img = null
    try {
      img = await getBannerForUser(userId)
      commit('SET_BANNER', img)
    } catch (error) {
      throw Error('ERROR GET_BANNER : ' + error)
    }
  }
}
