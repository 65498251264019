const errorDic = {
  UNAUTHORIZED_OPERATION: 'Opération non autorisée',
  INVALID_THEMATIC_PARAMS: 'Paramètre(s) thématique incorrect',
  INVALID_PLACE_PARAMS: 'Paramètre(s) lieu incorrect',
  INVALID_USER_PARAMS: 'Paramètre(s) utilisateur incorrect',
  DUPLICATE_USER_MAIL_OR_PHONE: 'Email ou téléphone déjà existant',
  INVALID_APPOINTMENT_PARAMS: 'Paramètre(s) rendez-vous incorrect',
  EXISTING_APPOINTMENT_WITH_SAME_THEMATIC: 'Vous possédez déjà un rendez-vous avec la même thématique',
  EXPIRED_TOKEN: 'Lien expiré',
  INVALID_APPOINTMENT_DATE: 'Date de rendez-vous incorrect',
  INVALID_IDENTIFIERS: 'Identifiants incorrect'
}

export function getErrorMessage (errorCode) {
  if (errorDic[errorCode] != null && errorDic[errorCode] !== undefined) {
    return errorDic[errorCode]
  }

  return 'Merci d\'essayer à nouveau ultérieurement.'
}
