<template>
  <form
    class="login-form"
    :class="{'login-form--horizontal': horizontal}"
    @submit.prevent="loginUser"
  >
    <dc-input
      inputId="email"
      name="email"
      placeholder="E-mail"
      v-model.trim="$v.email.$model"
      :invalid="$v.email.$error && (!$v.email.required || !$v.email.email)"
    />
    <dc-input
      inputId="password"
      type="password"
      name="password"
      placeholder="Mot de passe"
      v-model="$v.password.$model"
      :invalid="$v.password.$error && !$v.password.required"
    />
    <dc-button variant="outline" type="submit">
      Connexion
    </dc-button>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DcInput from '@/components/ui/Input'
import DcButton from '@/components/ui/Button'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'UserLoginForm',
  components: {
    DcInput,
    DcButton
  },
  data () {
    return {
      email: '',
      password: ''
    }
  },
  props: {
    horizontal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'user',
      previousRoute: 'previousRoute'
    })
  },
  methods: {
    ...mapActions({
      login: 'LOGIN'
    }),
    async loginUser () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      try {
        await this.login({
          email: this.email,
          password: this.password
        })
        this.$notify({
          group: 'notification-group',
          type: 'success',
          duration: 10000,
          title: 'Authentification réussie',
          text: 'Vous pouvez maintenant accèder au menu'
        })

        if (this.previousRoute && this.previousRoute.name) {
          this.$router.push({
            name: this.previousRoute.name,
            params: this.previousRoute.params
          })
        } else {
          if (this.currentUser && this.currentUser.role === 'CLIENT') {
            this.$router.push('/home-connected')
          } else {
            this.$router.push('/planning')
          }
        }
      } catch (error) {
        // console.error('ERROR loginUser', error)
      }
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    }
  }
}
</script>

<style lang="scss">
.login-form  {
  width: 52.4rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  &--horizontal {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
  .dc-input {
    margin-bottom: 2.5rem;
  }

  .dc-button{
    background-color: #f78d37;
    color: #fff !important;
  }
 @media only screen and (max-width: 768px) {
        width: 45rem;
 }
}
</style>
