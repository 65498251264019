<template>
  <div class="activate-account-view">
    <div class="activate-account-view__form">
      <h4 v-if="isActivated">
        Compte Activé
      </h4>
      <dc-button
        v-if="isActivated"
        variant="primary"
        @click.prevent="goTo"
      >
        Me connecter
      </dc-button>
      <h4 v-else>
        Impossible d'activer ce compte
      </h4>
    </div>

  </div>
</template>

<script>
import DcButton from '@/components/ui/Button'
import { activate } from '@/services/user.service.js'
import { mapActions } from 'vuex'

export default {
  name: 'ActivateAccount',
  components: {
    DcButton
  },
  data () {
    return {
      token: null,
      isActivated: false
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'SET_LOADING'
    }),
    goTo () {
      this.$router.push({ name: 'login' })
    }
  },
  async mounted () {
    this.setLoading(true)
    this.token = this.$route.params.token
    try {
      await activate(this.token)
      this.isActivated = true
    } catch (error) {
      // console.error(error)
    } finally {
      this.setLoading(false)
    }
  }
}
</script>

<style lang="scss">
.activate-account-view {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: $light-grey-blue;
  background: linear-gradient(90deg, $blue 45%, $light-grey-blue 45%);
  &__form {
    position: absolute;
    width: 73.4rem;
    height: 63.4rem;
    padding: 10rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 1rem;
    box-shadow: -7px 12.1px 25.4px 1.6px rgba(0, 0, 0, 0.14);
    background-color: $light;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
  }
  h4 {
    font-size: 2.3rem;
    letter-spacing: 0.69px;
    color: $grey2;
    text-align: center;
    margin-bottom: 4rem;
    font-weight: $font-weight-regular;
  }
}
</style>
