<template>
  <div class="login-view">
    <div class="login-view__form">
      <title-deck class="title">S'identifier</title-deck>
      <h4>
        Connectez-vous ou <router-link to="/subscribe" tag="a" class="login-view__register-link">créez votre compte.</router-link>
      </h4>
      <user-login-form/>
      <div class="login-view__forgotten">
        <router-link to="/forgotten-password" tag="a" class="login-view__forgotten-link">
          Mot de passe et identifiant oublié ?
        </router-link>
      </div>
    </div>
    <div class="login-view__register-display">
      <div class="login-view__register">
        <router-link to="/subscribe">
          <dc-button>Inscription</dc-button>
        </router-link>
      </div>
    </div>

    <dc-popin
      :showButtons="false"
      :show="showPopin"
      @on-close="closePopin"
      title="Vous devez modifier votre mot de passe" >
      <dc-change-password-form
        :token-value="'test'"
      />
    </dc-popin>

  </div>
</template>

<script>
import TitleDeck from '@/components/ui/TitleDeck'
import DcPopin from '@/components/ui/Popin'
import DcButton from '@/components/ui/Button'
import UserLoginForm from '@/components/user/login-form.vue'
import DcChangePasswordForm from '@/components/user/change-password-form.vue'

export default {
  name: 'Login',
  components: {
    TitleDeck,
    DcButton,
    DcPopin,
    UserLoginForm,
    DcChangePasswordForm
  },
  data () {
    return {
      showPopin: true
    }
  },
  methods: {
    closePopin () {
      this.showPopin = false
    }
  }
}
</script>

<style lang="scss">
.login-view {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: $light-grey-blue;
  background: linear-gradient(90deg, $blue 45%, $light-grey-blue 45%);
  .dc-button--outline {
    color: $orange;
    border: solid 2px $orange;
    &:hover {
      color: lighten($orange, 12%);
      border: solid 2px lighten($orange, 12%);
    }
  }
  &__form {
    position: absolute;
    width: 73.4rem;
    // height: 73.4rem;
    padding: 2rem;
    left: 50%;
    top: 35%;
    transform: translate(-50%, -50%);
    border-radius: 1rem;
    box-shadow: -7px 12.1px 25.4px 1.6px rgba(0, 0, 0, 0.14);
    background-color: $light;
    padding-top: 12.4rem;
     @media only screen and (max-width: 768px) {
       width: 48rem;
       padding-top: 5rem;
       padding-bottom: 5rem;
        .title{
          display: none;
        }
    }
  }
  &__register {
    width: 52.4rem;
    margin: 0 auto;
    @media only screen and (max-width: 768px) {
      width: 45rem;
    }
  }
  &__register-link {
    font-size: 2.3rem;
    letter-spacing: 0.69px;
    color: $grey2;
  }
  &__forgotten {
    font-size: 1.8rem;
    font-weight: $font-weight-light;
    letter-spacing: 1.35px;
    text-align: center;
    color: $grey2;
    margin: 3.7rem 0;
  }
  &__forgotten-link {
    font-size: 1.8rem;
    font-weight: $font-weight-light;
    letter-spacing: 1.35px;
    color: $grey2 !important;
  }
  h4 {
    font-size: 2.3rem;
    letter-spacing: 0.69px;
    color: $grey2;
    text-align: center;
    margin-bottom: 8.1rem;
    font-weight: $font-weight-regular;
  }

  &__register-display {
     position: absolute;
    width: 73.4rem;
    margin-top: 45rem;
    padding: 3rem;
    left: 50%;
    top: 35%;
    transform: translate(-50%, -50%);
    border-radius: 1rem;
    box-shadow: -7px 12.1px 25.4px 1.6px rgba(0, 0, 0, 0.14);
    background-color: $light;
     @media only screen and (max-width: 768px) {
      width: 48rem;
      margin-top: 40rem;
      padding-bottom: 5rem;
    }
  }
}
</style>
