<template>
  <md-dialog :md-active.sync="showPopin" v-bind="$attrs" @md-closed="$emit('on-close')">
    <div class="btn-close" @click="close">
      <md-icon>close</md-icon>
    </div>
    <md-dialog-title v-html="title"></md-dialog-title>
    <md-dialog-content>
      <slot></slot>
    </md-dialog-content>
    <md-dialog-actions v-if="showButtons">
      <dc-button variant="secondary-small" @click="cancel">{{ cancelBtnLabel }}</dc-button>
      <dc-button variant="primary-small" @click="validate">{{ okBtnLabel }}</dc-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import DcButton from '@/components/ui/Button'

export default {
  name: 'DcPopin',
  components: {
    DcButton
  },
  data () {
    return {
      showPopin: false,
      file: ''
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'specify a title with :title'
    },
    showButtons: {
      type: Boolean,
      default: false
    },
    okBtnLabel: {
      type: String,
      default: 'Valider'
    },
    cancelBtnLabel: {
      type: String,
      default: 'Annuler'
    }
  },
  watch: {
    show (value) {
      this.showPopin = value
    }
  },
  methods: {
    close () {
      this.showPopin = false
    },
    validate () {
      this.$emit('on-close')
      this.$emit('on-validation')
    },
    cancel () {
      this.close()
      this.$emit('on-close')
      this.$emit('on-cancel')
    }
  }
}
</script>

<style lang="scss">
.md-dialog {
  .btn-close {
    position: absolute;
    top: 0;
    left: 0;
    padding: 1rem;
    cursor: pointer;
    .md-icon {
      font-size: 3rem !important;
      color: $dark !important;
    }
  }
  .dc-button {
    margin:1rem;
  }
  .md-dialog-container {
    border-radius: 1rem;
  }
  .md-dialog-content {
    padding: 0 10rem 10rem 10rem;
  }
  .md-dialog-title{
    padding: 5rem 20rem 1rem 10rem;
    &.md-title {
      font-size: 3.5rem;
      font-weight: $font-weight-bold;
      letter-spacing: 3.75px;
      line-height: 7rem;
      text-align: left;
      color: $dark;
    }
  }
}
</style>
