<template>
  <div class="users-view">
    <div class="users-view__list">
      <title-deck>Utilisateurs</title-deck>

      <dc-table :columns="cols" :rows="rows" rowItemName="utilisateur">

        <template v-slot:cell-button-edit="{ utilisateur }">
          <dc-button
            @click="onSelect(utilisateur)"
            variant="secondary-small"
          >
            <md-icon>create</md-icon>
          </dc-button>
        </template>

        <template v-slot:header>
          <div class="users-view__buttons">
            <dc-button
              @click="addUser()"
              variant="primary-small"
            >
              Ajouter
            </dc-button>
          </div>
        </template>

      </dc-table>
      <template v-if="rows">
        <div class="hr hr--no-mb"></div>
        <span class="table-total">
          {{ rows.length }} Total
        </span>
      </template>
    </div>

    <dc-popin
      ref="userEditFormPopin"
      :showButtons="false"
      :show="showPopin"
      @on-close="closePopin"
      :title="selectedItem.id ?
        `Modifier l'utilisateur <strong>${selectedItem.firstName} ${selectedItem.lastName}</strong>` :
        'Création d\'un nouvel utilisateur'"
    >
      <user-edit-form
        @save="onSave"
        @cancel="closePopin"
        :user-data="selectedItem"
      />
    </dc-popin>
  </div>
</template>

<script>
import DcTable from '@/components/ui/Table'
import { getAllUsersList } from '@/services/user.service.js'
import TitleDeck from '@/components/ui/TitleDeck'
import DcButton from '@/components/ui/Button'
import DcPopin from '@/components/ui/Popin'
import UserEditForm from '@/components/user/edit-form'
import { mapGetters } from 'vuex'

export default {
  name: 'UsersView',
  components: {
    TitleDeck,
    DcButton,
    DcPopin,
    UserEditForm,
    DcTable
  },
  data () {
    return {
      showPopin: false,
      rows: [],
      shallowRows: null,
      users: null,
      cols: [
        { name: 'firstName', label: 'Prénom', sortOrder: 'ASC' },
        { name: 'lastName', label: 'Nom', sortOrder: 'ASC' },
        { name: 'role', label: 'Statut', sortOrder: 'ASC' },
        { name: 'button-edit', label: '', sortOrder: 'ASC' }
      ],
      selectedCol: null,
      itemByPage: 10,
      pages: [],
      selectedPage: 1,
      selectedItem: {}
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'user'
    })
  },
  methods: {
    onSave () {
      this.refreshList()
      this.closePopin()
      this.$notify({
        group: 'notification-group',
        type: 'success',
        duration: 10000,
        title: 'L\'enregistrement a réussi',
        text: 'Les informations de l\'utilisateur sont enregistrées'
      })
    },
    closePopin () {
      this.showPopin = false
    },
    onSelect (utilisateur) {
      this.selectedItem = utilisateur
      this.showPopin = true
    },
    addUser () {
      if (this.currentUser.role !== 'CLIENT') {
        this.selectedItem = Object.assign({}, null)
        this.showPopin = true
      }
    },
    async refreshList () {
      let userList = null
      try {
        userList = await getAllUsersList()
        this.users = userList.data
        this.rows = [...this.users]
        this.goToPage(this.selectedPage)
        this.getPages()
      } catch (error) {
        // console.error('refreshList()', error)
      }
    },
    goToPage (page) {
      this.selectedPage = page
      this.shallowRows = this.rows.slice(((page - 1) * this.itemByPage), page * this.itemByPage)
    },
    getPages () {
      this.pages = []
      const nb = Math.ceil(this.rows.length / this.itemByPage)
      for (let index = 0; index < nb; index++) {
        this.pages.push(index + 1)
      }
    },
    sort (currentCol) {
      this.selectedCol = currentCol
      this.selectedCol.sortOrder = this.selectedCol.sortOrder === 'ASC' ? 'DESC' : 'ASC'
      this.rows.sort((a, b) => {
        return this.compare(a, b)
      })
      this.selectedPage = 1
      this.goToPage(this.selectedPage)
    },
    compare (a, b) {
      const objA = a[this.selectedCol.field].toUpperCase()
      const objB = b[this.selectedCol.field].toUpperCase()

      let comparison = 0
      if (objA > objB) {
        comparison = 1
      } else if (objA < objB) {
        comparison = -1
      }
      return this.selectedCol.sortOrder === 'ASC' ? comparison : (comparison * -1)
    }
  },
  mounted () {
    this.selectedCol = this.cols[0]
    this.refreshList()
  }
}
</script>

<style lang="scss" scoped>
.users-view {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: $light-grey-blue;
  background: linear-gradient(90deg, $blue 38%, $light-grey-blue 38%);
  padding-top: calc(#{$header-height} + 1rem);
  overflow-y: auto;
  &__buttons {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	&__list {
		position: absolute;
		z-index: 2;
		border-radius: 1rem;
		left: 50%;
		top: 14rem;
		transform: translateX(-50%);
		width: 123.5rem;
		background-color: $light;
		box-shadow: 4.8px 7.6px 12.2px 0.8px rgba(0, 0, 0, 0.07);
		padding: 5rem;
		padding-top: 9rem;
    margin-bottom: 12rem;
	}
	&__list-table {
		width: 100%;
	}
	&__table-head {
		font-size: 1.8rem;
		font-weight: $font-weight-bold;
		letter-spacing: 1.35px;
		text-align: left;
		color: $light-grey-3;
		th {
			height: $table-row-height;
			cursor: pointer;
			&:hover {
				color: lighten( $light-grey-3, 15%);
			}
		}
	}
	&__table-body {
		font-size: 1.8rem;
		font-weight: $font-weight-bold;
		letter-spacing: 1.35px;
		text-align: left;
		color: $dark;
		tr {
			height: $table-row-height;
			cursor: pointer;
			&:hover {
				background-color: darken( $light, 10%);
			}
		}
	}
	&__pagination {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 3rem 0;
	}
	&__page {
		width: 30px;
		height: 30px;
		font-size: 1.5rem;
		border-radius: 10px;
		text-align: center;
		line-height: 28px;
		margin: 0 .5rem;
		cursor: pointer;
		background-color: $light;
		box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
		&--current {
			background-color: darken($light, 12%);
			box-shadow: none;
			cursor: default;
		}
		&:hover {
			background-color: darken($light, 10%);
		}

	}
	.expand-icon {
		width: 100%;
		text-align: center;
	}
}
</style>
