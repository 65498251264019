<template>
  <form class="create-appointment-form" autocomplete="off">
    <div class="row" v-if="
        (
          isAdmin ||
          isJurist ||
          isJuristSup ||
          isPartenaire
        ) &&
        $route.name !== 'appointments'"
      >
      <div class="col-5 column">
        <label for="status" class="col-12"> Type du rendez-vous </label>
        <div class="col-12">
          <dc-select
          class="col-12"
            selectId="status"
            :options="statusOptions"
            v-model="status"
            :disabled="appointmentId"
          />
        </div>
      </div>
      <div class="col-1"></div>
      <div class="col-5 flex-row" v-if="status == 'APPOINTMENT'">
        <label
          for="isHonored"
          class="padding-right"
        >
          Est honoré
        </label>
        <dc-switch
          v-model="isHonored"
          id="isHonored"
        />
      </div>
      <div class="col-5" v-if="status == 'UNAVAILABILITY'">
        <label for="unavailabilityLabel">Libellé de l'indisponibilité</label>
        <div class="row no-margin">
          <dc-input
            class="col-12"
            type="text"
            inputId="unavailabilityLabel"
            v-model="unavailabilityLabel"
            :invalid="$v.unavailabilityLabel.$dirty && !$v.unavailabilityLabel.required"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <label for="startDate">Date et heure de début</label>
        <div class="row no-margin">
          <dc-input
            class="col-12 input-top"
            type="date"
            inputId="startDate"
            v-model="$v.startDate.$model"
            :disabled="!isAdmin && !isJuristSup"
            :invalid="$v.startDate.$error && !$v.startDate.required"
          />
          <dc-input
            class="col-12 input-bottom"
            type="time"
            v-model="$v.startTime.$model"
            inputId="startTime"
            :disabled="!isAdmin && !isJuristSup"
            :invalid="$v.startTime.$error && !$v.startTime.required"
          />
        </div>
      </div>
      <div class="col-1"></div>
      <div class="col-5">
        <label for="endDate" style="margin-top: 2.5rem">Date et heure de fin</label>
        <div class="row no-margin">
          <dc-input
            class="col-12 input-top"
            type="date"
            inputId="endDate"
            v-model="$v.endDate.$model"
            :disabled="!isAdmin && !isJuristSup"
          />
          <dc-input
            class="col-12 input-bottom"
            type="time"
            v-model="$v.endTime.$model"
            inputId="endTime"
            :disabled="!isAdmin && !isJuristSup"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <template v-if="status == 'APPOINTMENT'">
          <label for="lieu">Lieu</label>
          <div
            v-if="place"
            class="row no-margin"
          >
            <div
              class="col-12 addresse"
              tabindex="0"
              id="lieu"
            >
              <span>{{ place.name }}</span>
              <span>{{ place.address1 }}</span>
              <span v-if="place.address2 !== ''">{{ place.address2 }}</span>
              <span v-if="place.address3 !== ''">{{ place.address3 }}</span>
              <span>{{ place.postalCode }} {{ place.city }}</span>
            </div>
          </div>
          <!-- <dc-input
            v-else
            inputId="location"
            prependIcon="location_on"
            v-debounce:100ms="onLocationChange"
            @focus="onLocationChange"
            placeholder="Lieu"
            v-model.trim="location"
            :auto-complete-values="locations"
            @value-selected="selectLocation"
            :auto-complete-on-click="autoCompleteOnClick"
          /> -->
        </template>
      </div>
      <div class="col-1"></div>
      <div class="col-5" v-if="status == 'APPOINTMENT'">
        <template v-if="$route.name !== 'appointments' && thematic != null">
          <label for="documents">
            Document nécessaires
          </label>
          <textarea
            name="documents"
            id="documents"
            v-model="thematic.neededDocuments"
            disabled
          >
          </textarea>
        </template>
        <template v-if="$route.name !== 'appointments' && thematic == null">
          <label for="documents">
            Document nécessaires
          </label>
          <textarea
            name="documents"
            id="documents"
            disabled
          >
          </textarea>
        </template>
      </div>
    </div>
    <div class="row" v-if="dispos">
      <div class="col-12 dispo-title">
        Disponibilités
      </div>
    </div>
    <template v-if="dispos">
      <div class="row disponibilites">
        <div @click="setDispo(dispo)" class="col-12 disponibilites__item" v-for="(dispo, dispoIdx) in dispos" :key="`dispo-${dispoIdx}`">
          {{ dispo.start | date }}
          {{ dispo.start | time }}
          {{ dispo.end | time }}
        </div>
      </div>
    </template>
    <div class="row" v-if="status == 'APPOINTMENT'">
      <div class="col-5">
        <template  v-if="isAdmin || isJurist || isJuristSup|| isMairie || isPartenaire">
          <label for="utilisateur">Utilisateur</label>
          <dc-input
            inputId="utilisateur"
            prepend-icon="person"
            append-icon="add"
            v-model="searchUser"
            v-debounce:100ms="onChange"
            @icon-click="openPopin"
            :auto-complete-values="filteredUser"
            @value-selected="addUser"
          />
        </template>
      </div>
      <div class="col-1"></div>
      <div class="col-5">
        <template v-if="status == 'APPOINTMENT' && (isAdmin || isJuristSup)">
          <label for="jurist">Juriste</label>
          <dc-input
            inputId="jurist"
            prepend-icon="person"
            v-model="searchJurist"
            v-debounce:100ms="onJuristChange"
            :auto-complete-values="filteredJurist"
            @value-selected="addJurist"
          />
        </template>
      </div>
    </div>
    <div class="row" v-if="status == 'APPOINTMENT'">
      <div class="col-5">
        <template v-if="(isAdmin|| isJurist || isJuristSup || isMairie || isPartenaire)">
          <label for="thematic">Thématique</label>
          <dc-input
            inputId="thematic"
            v-model="searchThematics"
            v-debounce:100ms="onThematicChange"
            :auto-complete-values="filteredThematics"
            @value-selected="addThematic"
          />
        </template>
      </div>
      <div class="col-1"></div>
      <div class="col-5">
        <template v-if="thematicCode != null">
          <label for="code">Code</label>
          <dc-input
            inputId="code"
            v-model="$v.code.$model"
            :invalid="$v.code.$error && !$v.required"
          />
          <div class="error-message" v-if="$v.code.$error && !$v.code.required">
            Ce champs est obligatoire
          </div>
        </template>
      </div>
    </div>
    <div class="row">
      <div class="col-11">
        <label for="commentaires">Commentaires</label>
        <textarea
          name="commentaires"
          id="commentaires"
          v-model="notes"
        >
        </textarea>
      </div>
    </div>
        <div class="row" v-if="isJurist || isJuristSup || isAdmin">
      <div class="col-11">
        <label for="commentaires">Commentaires privé</label>
        <textarea
          name="privateCommentaires"
          id="privateCommentaires"
          v-model="privateNotes"
        >
        </textarea>
      </div>
    </div>
    <div class="hr"></div>
    <div class="actions-section">
      <div>
        <dc-button
          v-if="isClient || (isEditMode && (isMyRdv || isAdmin || isJuristSup)) || (!isEditMode && (isAdmin || isJuristSup || isPartenaire || isMairie || (isJurist && isMyRdv)) )"
          variant="primary"
          @click.prevent="save"
        >
          Enregistrer
        </dc-button>
      </div>
      <div class="spacer"></div>
      <div v-if="(isEditMode && (isMyRdv || isAdmin || isJuristSup || isPartenaire))">
        <dc-button
          variant="secondary"
          @click.prevent="cancel"
        >
          Annuler le rendez-vous
        </dc-button>
      </div>
    </div>

    <dc-popin
      ref="userAddUserFormPopin"
      :showButtons="false"
      :show="showUserPopin"
      @on-close="closePopin"
      title="Création d'un nouvel utilisateur"
    >
      <user-edit-form
        @save="onSave"
        @error="onError"
        @cancel="closePopin"
      />
    </dc-popin>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DcButton from '@/components/ui/Button'
import DcSwitch from '@/components/ui/Switch'
import DcInput from '@/components/ui/Input'
import DcSelect from '@/components/ui/Select'
import DcPopin from '@/components/ui/Popin'
import UserEditForm from '@/components/user/edit-form'
import dayjs from 'dayjs'
import { getUserByRole } from '@/services/user.service.js'
import { cancelAppointment } from '@/services/appointment.service.js'
import { getAllActiveThematics } from '@/services/thematic.service.js'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { placeQueryAuth } from '@/services/place.service.js'

export default {
  name: 'AppointmentCreateForm',
  components: {
    DcInput,
    DcButton,
    DcSwitch,
    DcPopin,
    DcSelect,
    UserEditForm
  },
  props: {
    appointmentData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      selectedLocation: null,
      locations: null,
      location: null,
      showUserPopin: false,
      filteredUser: null,
      filteredJurist: null,
      filteredThematics: null,
      searchUser: null,
      searchJurist: null,
      searchThematics: null,
      nbCharAutoComplete: 2,
      autoCompleteOnClick: false,
      isAbsent: false,
      place: null,
      thematic: null,
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null,
      documents: '',
      selectedUser: null,
      selectedJurist: null,
      jurists: null,
      thematics: null,
      listUsers: null,
      appointmentId: null,
      notes: '',
      privateNotes: '',
      code: '',
      isHonored: true,
      dispos: null,
      thematicCode: null,
      status: 'APPOINTMENT',
      statusOptions: [
        { label: 'Rendez-vous', value: 'APPOINTMENT' },
        { label: 'Absence', value: 'ABSENCE' },
        { label: 'Indisponibilité', value: 'UNAVAILABILITY' }
      ],
      unavailabilityLabel: ''
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'user',
      users: 'users',
      currentThematic: 'currentThematic'
    }),
    isEditMode () {
      return this.appointmentId !== null && this.appointmentId !== 'undefined'
    },
    isMyRdv () {
      return this.appointmentData.extendedProps.jurist ? this.appointmentData.extendedProps.jurist.id === this.currentUser.id : false
    },
    isAdmin () {
      return this.currentUser && this.currentUser.role === 'ADMIN'
    },
    isJurist () {
      return this.currentUser && this.currentUser.role === 'JURISTE'
    },
    isJuristSup () {
      return this.currentUser && this.currentUser.role === 'JURISTESUP'
    },
    isMairie () {
      return this.currentUser && this.currentUser.role === 'MAIRIE'
    },
    isPartenaire () {
      return this.currentUser && this.currentUser.role === 'PARTENAIRE'
    },
    isClient () {
      return this.currentUser && this.currentUser.role === 'CLIENT'
    }
  },
  methods: {
    ...mapActions({
      setUsers: 'SET_USERS',
      saveAppointment: 'SAVE_APPOINTMENT',
      updateAppointment: 'UPDATE_APPOINTMENT'
    }),
    setDispo (dispo) {
      this.startDate = dayjs(dispo.start).format('YYYY-MM-DD')
      this.startTime = dayjs(dispo.start).format('HH:mm')
      this.endDate = dayjs(dispo.end).format('YYYY-MM-DD')
      this.endTime = dayjs(dispo.end).format('HH:mm')
    },
    selectLocation (value) {
      this.selectedLocation = value
      this.location = value.label
      this.selectedThematic = null
      this.thematics = value.value.thematics
      this.dispos = value.value.dispos
      this.filteredThematics = this.thematics
        .map(c => {
          return {
            label: c.name.trim(),
            value: c
          }
        })
    },
    async onLocationChange (event) {
      let nbChars = this.nbCharAutoComplete
      if (!event || event === '') {
        nbChars = 0
        this.autoCompleteOnClick = true
      } else {
        this.autoCompleteOnClick = false
      }

      if (event.length >= nbChars) {
        try {
          const response = await placeQueryAuth(event)
          this.locations = response
            .map(c => {
              return {
                label: c.name.trim() + '  (' + c.postalCode + ')',
                value: c
              }
            })
            .sort((a, b) => {
              if (a.label > b.label) {
                return 1
              } else {
                return -1
              }
            })
        } catch (error) {
          // console.error(error)
        }
      }
    },
    onChange (event) {
      if (this.listUsers && event && event.length >= this.nbCharAutoComplete) {
        this.filteredUser = this.listUsers
          .map(c => {
            return {
              label: c.firstName.trim() + ' ' + c.lastName.trim(),
              mobile: c.mobile != null ? c.mobile.trim() : '',
              value: c.id
            }
          })
          .filter(c => {
            if (c.label.toLowerCase().includes(event.toLowerCase()) || c.mobile.includes(event)) {
              return true
            } else {
              return false
            }
          })
      }
    },
    onJuristChange (event) {
      if (this.jurists && event && event.length >= this.nbCharAutoComplete) {
        this.filteredJurist = this.jurists
          .map(c => {
            return {
              label: c.firstName.trim() + ' ' + c.lastName.trim(),
              value: c.id
            }
          })
          .filter(c => {
            if (
              c.label.toLowerCase().includes(event.toLowerCase())
            ) {
              return true
            } else {
              return false
            }
          })
      }
    },
    onThematicChange (event) {
      if (this.thematics && event && event.length >= this.nbCharAutoComplete) {
        this.filteredThematics = this.thematics
          .map(c => {
            return {
              label: c.name.trim(),
              value: c.id
            }
          })
          .filter(c => {
            if (
              c.label.toLowerCase().includes(event.toLowerCase())
            ) {
              return true
            } else {
              return false
            }
          })
      }
    },
    addUser (event) {
      this.selectedUser = this.listUsers.find(u => u.id === event.value)
      this.searchUser = event.label
    },
    addJurist (event) {
      this.selectedJurist = this.jurists.find(u => u.id === event.value)
      this.searchJurist = event.label
    },
    addThematic (event) {
      this.selectedThematic = this.thematics.find(u => u.id === event.value)
      this.thematicCode = this.selectedThematic.code
      this.searchThematics = event.label
    },
    async onSave (newUser) {
      this.setUsers()
      this.listUsers = await this.loadUsers()
      this.selectedUser = this.listUsers.find(u => u.id === newUser.data.id)
      this.searchUser = newUser.data.firstName.trim() + ' ' + newUser.data.lastName.trim()
      this.closePopin()
      this.$notify({
        group: 'notification-group',
        type: 'success',
        duration: 10000,
        title: 'L\'enregistrement a réussi',
        text: 'Les informations du rendez-vous sont enregistrées'
      })
    },
    onError (value) {
      this.closePopin()
    },
    closePopin () {
      this.showUserPopin = false
    },
    openPopin () {
      this.showUserPopin = true
    },
    async cancel () {
      try {
        if (window.confirm('Vous allez annuler ce rendez-vous')) {
          await cancelAppointment(this.appointmentId)
        }
        this.$emit('cancel')
      } catch (error) {
        // console.error(error)
      }
    },
    close () {
      this.$emit('close')
    },
    async save () {
      if (this.currentUser.role === 'CLIENT') {
        this.$v.selectedUser.$model = this.currentUser
      }
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      if (this.status === 'UNAVAILABILITY') {
        this.selectedThematic = { color: '#000000', name: 'Indisponible' }
      }
      if (this.status === 'ABSENCE') {
        this.selectedThematic = { color: '#000000', name: 'Absence' }
      }
      const newAppointment = {
        id: this.appointmentId,
        startDate: this.startDate,
        endDate: this.endDate,
        thematic: this.selectedThematic,
        place: this.appointmentData.extendedProps ? this.appointmentData.extendedProps.place : this.currentUser.adil,
        user: !this.isAbsent &&
          (
            this.isAdmin ||
            this.isJurist ||
            this.isJuristSup ||
            this.isMairie ||
            this.isPartenaire
          ) ? this.selectedUser : this.currentUser,
        jurist: this.selectedJurist,
        origin: 'web',
        documents: this.documents,
        startTime: this.startTime,
        endTime: this.endTime,
        notes: this.notes,
        privateNotes: this.privateNotes,
        code: this.code,
        isHonored: this.isHonored,
        status: this.status,
        unavailabilityLabel: this.unavailabilityLabel
      }

      try {
        if (this.appointmentId) {
          await this.updateAppointment(newAppointment)
        } else {
          await this.saveAppointment(newAppointment)
        }
        this.$emit('save')
      } catch (error) {
        this.$emit('error', error)
        // console.error('ERROR save', error)
      }
    },
    async loadJurists () {
      let jurists = null
      try {
        jurists = await getUserByRole(['JURISTE', 'JURISTESUP'])
      } catch (error) {
        // console.error(error)
      }
      return jurists
    },
    async loadThematics () {
      let thematics = null
      try {
        thematics = await getAllActiveThematics()
      } catch (error) {
        // console.error(error)
      }
      return thematics
    },
    async loadUsers () {
      let users = null
      try {
        users = await getUserByRole(['CLIENT'])
      } catch (error) {
        // console.error(error)
      }
      return users
    }
  },
  async mounted () {
    this.setUsers()
    if (this.currentUser && (this.currentUser.role === 'ADMIN' || this.currentUser.role === 'JURISTE' || this.currentUser.role === 'JURISTESUP' || this.currentUser.role === 'MAIRIE' || this.currentUser.role === 'PARTENAIRE')) {
      this.jurists = await this.loadJurists()
      this.listUsers = await this.loadUsers()
      this.thematics = await this.loadThematics()
    }
    if (this.appointmentData.extendedProps) {
      this.isAbsent = this.appointmentData.extendedProps.isOff
      this.startDate = dayjs(this.appointmentData.start).format('YYYY-MM-DD')
      this.startTime = dayjs(this.appointmentData.extendedProps.appointmentStartTime).format('HH:mm')
      this.endDate = this.appointmentData.end ? dayjs(this.appointmentData.end).format('YYYY-MM-DD') : null
      this.endTime = this.appointmentData.end ? dayjs(this.appointmentData.extendedProps.appointmentEndTime).format('HH:mm') : null
      this.place = this.appointmentData.extendedProps.place
      this.thematic = this.appointmentData.extendedProps.thematic
      this.thematicCode = this.appointmentData.extendedProps.thematic ? this.appointmentData.extendedProps.thematic.code : null
      this.notes = this.appointmentData.extendedProps.notes
      this.privateNotes = this.appointmentData.extendedProps.privateNotes
      this.isHonored = this.appointmentData.extendedProps.isHonored
      this.documents = this.appointmentData.extendedProps.documents
      this.status = this.appointmentData.extendedProps.status ? this.appointmentData.extendedProps.status : 'APPOINTMENT'
      this.unavailabilityLabel = this.appointmentData.extendedProps.unavailabilityLabel
      this.code = this.appointmentData.extendedProps.code ? this.appointmentData.extendedProps.code : ''
      if (this.thematic && this.thematic.id) {
        this.selectedThematic = this.thematic
        this.searchThematics = this.thematic.name
      }
      if (this.appointmentData.extendedProps.user) {
        this.selectedUser = this.appointmentData.extendedProps.user
        this.searchUser = this.selectedUser.firstName + ' ' + this.selectedUser.lastName
      }
      if (this.appointmentData.extendedProps.jurist) {
        this.selectedJurist = this.appointmentData.extendedProps.jurist
        this.searchJurist = this.selectedJurist.firstName + ' ' + this.selectedJurist.lastName
      }
      this.appointmentId = this.appointmentData.extendedProps.appointmentId
    } else if (this.appointmentData) {
      this.selectedUser = this.appointmentData
      this.searchUser = this.appointmentData.firstName + ' ' + this.appointmentData.lastName
    }
  },
  validations: {
    isAbsent: {},
    place: {},
    startDate: {
      required
    },
    startTime: {
      required
    },
    status: {},
    unavailabilityLabel: {},
    endDate: {},
    endTime: {},
    documents: {},
    selectedUser: {},
    selectedJurist: {},
    isHonored: {},
    code: {
      required: requiredIf(function () {
        return (this.thematicCode !== null && this.thematicCode !== undefined)
      })
    }
  }
}
</script>

<style lang="scss">
.create-appointment-form {
  .input-top {
    .dc-input__field {
      border-radius: 1rem 1rem 0 0;
      border-bottom: solid 1px $dark-grey;
    }
  }
  .input-bottom {
    .dc-input__field {
      border-radius: 0 0 1rem 1rem;
      border-top: solid 1px $dark-grey;
    }
  }
  .addresse {
    font-size: 1.6rem;
    font-weight: $font-weight-bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.2px;
    text-align: left;
    color: $light-grey-3;
  }
  .actions-section {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    background-color: $light;
    width: 100%;
    .dc-button {
      margin: 1rem;
    }
  }
  .dispo-title {
    font-size: 1.6rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.2px;
    text-align: left;
    color: #000;
    padding-bottom: 1.3rem;
  }
  .disponibilites {
    height: 20rem;
    overflow-y: auto;
    font-size:1.5rem;
    border: solid 2px #dedede;
    border-radius: 1rem;
    padding: 1rem;
    &__item {
      margin: .5rem 0 .5rem 0;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
</style>
