<template>
  <div class="wrapper-view">
    <div class="wrapper-view__form">
      <h4 class="wrapper-view__label">
				Bonjour,<br>
				<br>
				Pour <span class="wrapper-view__text-gras">finaliser votre inscription</span>, merci de cliquer sur le <span class="wrapper-view__text-gras">lien contenu dans le mail</span> que nous venons de vous envoyer.<br>
				<br>
        L’ADIL.
      </h4>
    </div>
    <div class="wrapper-view__connexion-display">
      <div class="wrapper-view__connexion">
        <router-link to="/login">
          <dc-button>Se connecter</dc-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import DcButton from '@/components/ui/Button'

export default {
  name: 'SubscriptionSuccess',
  components: {
    DcButton
  }
}
</script>

<style lang="scss">
.wrapper-view {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: $light-grey-blue;
  background: linear-gradient(90deg, $blue 45%, $light-grey-blue 45%);
  .dc-button--outline {
    color: $orange;
    border: solid 2px $orange;
    &:hover {
      color: lighten($orange, 12%);
      border: solid 2px lighten($orange, 12%);
    }
  }
  &__form {
    position: absolute;
    padding: 1rem;
    left: 50%;
    top: 35%;
    transform: translate(-50%, -50%);
    border-radius: 1rem;
    box-shadow: -7px 12.1px 25.4px 1.6px rgba(0, 0, 0, 0.14);
    background-color: $light;
    padding-top: 12.4rem;
     @media only screen and (max-width: 768px) {
       width: 48rem;
       padding-top: 5rem;
       padding-bottom: 5rem;
        .title{
          display: none;
        }
    }
  }
  &__label{
    font-size: 2.3rem;
    letter-spacing: 0.69px;
    color: $grey2;
    text-align: center;
    margin-bottom: 8.1rem;
    font-weight: $font-weight-regular;
  }
	&__text-gras {
		font-weight: bold;
	}
  &__connexion-display {
    position: absolute;
    margin-top: 45rem;
    padding: 3rem;
    left: 50%;
    top: 35%;
    transform: translate(-50%, -50%);
    border-radius: 1rem;
    box-shadow: -7px 12.1px 25.4px 1.6px rgba(0, 0, 0, 0.14);
    background-color: $light;
     @media only screen and (max-width: 768px) {
      width: 48rem;
      margin-top: 40rem;
      padding-bottom: 5rem;
    }
  }
}
</style>
