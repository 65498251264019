import axios from 'axios'
import store from '@/store'

export async function addCategory (category) {
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.post(process.env.VUE_APP_API_URL + '/category',
      {
        name: category.name
      })
    return response.data
  } catch (error) {
    throw Error('ERROR addCategory() : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}

export async function getCategories () {
  try {
    store.dispatch('SET_LOADING', true)
    const response = await axios.get(process.env.VUE_APP_API_URL + '/category')
    return response.data
  } catch (error) {
    throw Error('ERROR loadCategories : ' + error)
  } finally {
    store.dispatch('SET_LOADING', false)
  }
}
