<template>
  <div class="dc-row">
    <div class="dc-row__name">
      <span>
        <md-icon>person</md-icon>
         {{ item.lastName }} {{ item.firstName ? item.firstName[0] + '.' : '' }}
      </span>
    </div>
    <div class="dc-row__events"
      @dragover.prevent @dragenter.prevent
      @drop="onDrop($event)">
      <template
        v-for="(event, index) in events"
      >
        <template v-if="event.appointment">
          <div
            class="dc-row__event"
            @click="editEvent(event)"
            draggable
            @dragstart="startDrag($event, event)"
            :style="{
              'width': getEventDuration(event.appointment.displayStart, event.appointment.displayEnd) + '%',
              'background-color': event.appointment.thematic.name == 'Congé' ? '#E1E1E1' : ''
              }"
            :key="`event-${index}`"
          >
            <div class="dc-row__event-thematic-color"
            :style="{
              'background-color': event.appointment.thematic.color
              }">
            </div>
            <div class="dc-row__event-time">
              <span>{{ event.appointment.displayStart }} - {{ event.appointment.displayEnd }}</span>
            </div>
            <div
              class="dc-row__event-dot"
              :style="{'background-color': event.appointment.thematic.color}"
            />
            <div class="dc-row__event-thematic">
              <div  class="dc-row__event-thematic-info" v-if="event.appointment.thematic.name !== 'Dispo' && event.appointment.thematic.name !== 'Fermé' && event.appointment.thematic.name !== 'Repas'">
                <span v-if="event.appointment.user">{{ event.appointment.user.lastName }} {{ event.appointment.user.firstName ? event.appointment.user.firstName[0] + '.' : '' }}</span>
                <!-- <span :style="{'color': event.appointment.thematic.color}">{{ event.appointment.thematic.name }}</span> -->
              </div>
              <div v-if="event.appointment.thematic.name === 'Dispo' || event.appointment.thematic.name === 'Fermé' || event.appointment.thematic.name === 'Repas'">
                <span>{{ event.appointment.thematic.name }}</span>
              </div>
            </div>
            <span :style="{'background-color': event.appointment.thematic.color}" class="tooltiptext">
              {{ event.appointment.thematic.name }}
            </span>
          </div>
        </template>
        <!-- <template v-else>
          <div
            class="col-1 dc-row__no-event"
            :key="`event-${index}`"
          />
        </template> -->
      </template>
    </div>

    <dc-popin
      ref="appointmentEditFormPopin"
      :showButtons="false"
      :show="showPopin"
      @on-close="closePopin"
      :title="selectedItem && selectedItem.extendedProps && selectedItem.extendedProps.appointmentId ? 'Modification du rendez-vous' : 'Création d\'un rendez-vous'"
    >
      <appointment-create-form
        @save="onSave"
        @cancel="onCancel"
        @close="closePopin"
        :appointment-data="selectedItem"
      />
    </dc-popin>

  </div>
</template>

<script>
import AppointmentCreateForm from '@/components/appointments/appointment-create-form.vue'
import DcPopin from '@/components/ui/Popin'
import { mapActions } from 'vuex'
export default {
  name: 'DcRow',
  data () {
    return {
      events: [],
      showPopin: false,
      selectedItem: null
    }
  },
  components: {
    DcPopin,
    AppointmentCreateForm
  },
  props: {
    item: { // Juriste
      type: Object,
      required: true
    },
    hours: {
      type: Array,
      required: true
    },
    place: {
      type: Object,
      required: true
    }
  },
  watch: {
    item (value) {
      // the callback will be called immediately after the start of the observation
      this.events = []
      let i = 0
      value.appointments.forEach(element => {
        if (element.status === 'UNAVAILABILITY' && (element.unavailabilityLabel != null || element.unavailabilityLabel !== '')) {
          element.thematic = { color: '#FF9900', name: element.unavailabilityLabel }
        }
        if (element.status === 'UNAVAILABILITY' && (element.unavailabilityLabel == null || element.unavailabilityLabel === '')) {
          element.thematic = { color: '#FF9900', name: 'Indisponible' }
        }
        if (element.status === 'ABSENCE') {
          element.thematic = { color: '#A4A4A4', name: 'Congé' }
        }
        this.events.push({
          index: i,
          time: element.appointmentStartTime,
          appointment: element
        })
        i++
      })
    }
  },
  methods: {
    ...mapActions({
      updateAppointment: 'UPDATE_APPOINTMENT'
    }),
    editEvent (event) {
      const startDate = event.appointment.startDate ? event.appointment.startDate.split('T')[0] : null
      const startTime = event.appointment.startTime ? event.appointment.startTime : null
      const endDate = event.appointment.endDate ? event.appointment.endDate.split('T')[0] : (event.appointment.startDate ? event.appointment.startDate.split('T')[0] : null)
      const endTime = event.appointment.endTime ? event.appointment.endTime : null
      this.selectedItem = {
        start: `${startDate}T${startTime}:00`,
        end: endDate ? `${endDate}T${endTime}:00` : null,
        extendedProps: {
          isOff: event.appointment.isOff,
          appointmentId: event.appointment.id,
          appointmentDay: `${startDate}T${startTime}:00+02:00`,
          appointmentEndTime: endDate ? `${endDate}T${endTime}:00` : null,
          appointmentStartTime: `${startDate}T${startTime}:00`,
          place: event.appointment.place,
          thematic: event.appointment.thematic,
          user: event.appointment.user,
          jurist: event.appointment.jurist ? event.appointment.jurist : this.item,
          notes: event.appointment.notes ? event.appointment.notes : '',
          isHonored: event.appointment.isHonored == null ? true : event.appointment.isHonored,
          status: event.appointment.status ? event.appointment.status : 'APPOINTMENT',
          documents: event.appointment.documents ? event.appointment.documents : '',
          unavailabilityLabel: event.appointment.unavailabilityLabel ? event.appointment.unavailabilityLabel : '',
          privateNotes: event.appointment.privateNotes ? event.appointment.privateNotes : ''
        }
      }
      if (event.appointment.thematic.name !== 'Fermé' && event.appointment.thematic.name !== 'Repas') {
        this.showPopin = true
      }
    },
    startDrag (dragEvent, event) {
      if (event != null && event.appointment != null && event.appointment.id != null) {
        dragEvent.dataTransfer.dropEffect = 'move'
        dragEvent.dataTransfer.effectAllowed = 'move'
        dragEvent.dataTransfer.setData('event', JSON.stringify(event.appointment))
      } else {
        dragEvent.preventDefault()
      }
    },
    async onDrop (dragEvent) {
      if (this.item == null) {
        return
      }
      try {
        const appointment = JSON.parse(dragEvent.dataTransfer.getData('event'))
        appointment.jurist = this.item
        appointment.place = this.place
        await this.updateAppointment(appointment)
        this.$notify({
          group: 'notification-group',
          type: 'success',
          duration: 10000,
          title: 'L\'enregistrement a réussi',
          text: 'Le rendez-vous est modifié'
        })
        this.$emit('refresh')
      } catch (error) {
        this.$emit('error', error)
        // console.error('ERROR save', error)
      }
    },
    onCancel () {
      this.showPopin = false
      this.$emit('refresh')
    },
    closePopin () {
      this.showPopin = false
    },
    onSave () {
      this.closePopin()
      this.$notify({
        group: 'notification-group',
        type: 'success',
        duration: 10000,
        title: 'L\'enregistrement a réussi',
        text: 'Le rendez-vous est modifié'
      })
      this.$emit('refresh')
    },
    getEventDuration (startTime, endTime) {
      const ratio = 60
      const totalMinutesAvailable = (this.hours.length) * ratio
      const startMinute = startTime === null ? 0 : startTime.split(':')[0] * ratio + startTime.split(':')[1] * 1
      const endMinute = endTime === null ? 0 : endTime.split(':')[0] * ratio + endTime.split(':')[1] * 1
      const duration = endMinute - startMinute
      const result = (duration / totalMinutesAvailable) * 100
      return result
    }
  },
  mounted () {
    let i = 0
    this.events = []
    this.item.appointments.forEach(element => {
      if (element.thematic == null && element.isOff) {
        element.thematic = { color: '#A4A4A4', name: 'Congé' }
      }
      if (element.status === 'ABSENCE') {
        element.thematic = { color: '#A4A4A4', name: 'Congé' }
      }
      if (element.status === 'UNAVAILABILITY' && (element.unavailabilityLabel != null || element.unavailabilityLabel !== '')) {
        element.thematic = { color: '#FF9900', name: element.unavailabilityLabel }
      }
      if (element.status === 'UNAVAILABILITY' && (element.unavailabilityLabel == null || element.unavailabilityLabel === '')) {
        element.thematic = { color: '#FF9900', name: 'Indisponible' }
      }

      this.events.push({
        index: i,
        time: element.appointmentStartTime,
        appointment: element
      })
      i++
    })
  }
}
</script>

<style lang="scss">
.dc-row {
  padding: 0.2rem 0 0.2rem 0;
  display: grid;
  grid-template-columns: 1.5fr 10.5fr;
  background-color: $light-grey;
  z-index: 1;

  &__events {
    background-color: $light-grey;
    height: 6.9rem !important;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  &__event {
    display: inline-block;
    position: relative;
    height: 100%; // 6.9rem !important;
    border-radius: 1rem;
    background-color: $light;
    // border-left: 4px solid $dark;
    transition: all .3s ease;
    // margin: 0 .2rem 0 .2rem;
    //  min-width:7%;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 9px 12.2px 0.8px rgba(0, 0, 0, 0.18);
    }
  }
  &__event-time {
    position: absolute;
    top: 0;
    left: 1.5rem;
    font-size: 1.4rem;
    text-align: left;
    color: $light-grey-3;
    .md-icon {
      font-size: 1.4rem !important;
      color: $light-grey-3 !important;
    }
  }
  &__event-thematic-color{
    width: 0.7rem;
    height: 100%;
    border-radius: 1rem;
  }
  &__event-thematic {
    position: absolute;
    bottom: 0;
    left: 1.5rem;
    font-size: 1.4rem;
    font-weight: $font-weight-bold;
    text-align: left;
    color: $dark;
    display: flex;
    flex-direction: column;
    font-size: xx-small;
  }
  &__event-thematic-info{
    line-height: 15px;
    display: flex;
    flex-direction: column;
    font-size: xx-small;
  }
  &__event-dot {
    position: absolute;
    top: .65rem;
    right: .5rem;
    background-color: $dark;
    width: 1rem;
    height: 1rem;
    border-radius: 999px;
  }
  &__no-event {
    height: 6.9rem !important;
  }
  &__name {
    font-size: 1.4rem;
    letter-spacing: 0.28px;
    text-align: left;
    color: $grey2;
    height: 6.9rem !important;
    display:flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 2rem;
    .md-icon {
      margin-right: 1rem;
    }
  }
}

.tooltiptext{
  visibility: hidden;
  width: 100%;
  color: #000;
  text-align: center;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  z-index: 10;
  padding: 1rem;
  position: absolute;
  font-weight: bold;
  font-size: 1.2rem;
  border: 0.1rem solid  #959595;
}

.dc-row__event:hover .tooltiptext {
  visibility: visible;
}

.dc-row__event:hover, .dc-row__event-thematic-color{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
