<template>
    <div class="password-strength">
      <ul class="password-strength__bars">
        <li class="password-strength__bar" :style="{ 'background-color': bar0}"></li>
        <li class="password-strength__bar" :style="{ 'background-color': bar1}"></li>
        <li class="password-strength__bar" :style="{ 'background-color': bar2}"></li>
        <li class="password-strength__bar" :style="{ 'background-color': bar3}"></li>
      </ul>
      <br>
    </div>
</template>

<script>
export default {
  name: 'DcPasswordStrength',
  data () {
    return {
      bar0: null,
      bar1: null,
      bar2: null,
      bar3: null,
      colors: ['darkred', 'orangered', 'orange', 'yellowgreen']
    }
  },
  props: {
    passwordToCheck: {
      type: String,
      default: null
    }
  },
  watch: {
    passwordToCheck (password) {
      this.setBarColors(4, '#DDD')
      if (password) {
        const pwdStrength = this.checkStrength(password)
        const c = this.getColor(pwdStrength)
        this.setBarColors(c.index, c.color)
        pwdStrength >= 30 ? this.$emit('password-strength', true) : this.$emit('password-strength', false)
      }
    }
  },
  methods: {
    getColor (s) {
      let index = 0
      if (s === 10) {
        index = 0
      } else if (s === 20) {
        index = 1
      } else if (s === 30) {
        index = 2
      } else if (s === 40) {
        index = 3
      } else {
        index = 4
      }
      return {
        index: index + 1,
        color: this.colors[index]
      }
    },
    setBarColors (count, col) {
      for (let n = 0; n < count; n++) {
        this['bar' + n] = col
      }
    },
    checkStrength (p) {
      // 1
      let force = 0

      // 2
      // eslint-disable-next-line no-useless-escape
      const regex = /[$-/:-?{-~!"^_@`\[\]]/g
      const lowerLetters = /[a-z]+/.test(p)
      const upperLetters = /[A-Z]+/.test(p)
      const numbers = /[0-9]+/.test(p)
      const symbols = regex.test(p)

      // 3
      const flags = [lowerLetters, upperLetters, numbers, symbols]

      // 4
      let passedMatches = 0
      for (const flag of flags) {
        passedMatches += flag === true ? 1 : 0
      }

      // 5
      force += 2 * p.length + ((p.length >= 10) ? 1 : 0)
      force += passedMatches * 10

      // 6
      force = (p.length <= 8) ? Math.min(force, 10) : force

      // 7
      force = (passedMatches === 1) ? Math.min(force, 10) : force
      force = (passedMatches === 2) ? Math.min(force, 20) : force
      force = (passedMatches === 3) ? Math.min(force, 30) : force
      force = (passedMatches === 4) ? Math.min(force, 40) : force

      return force
    }
  }
}
</script>

<style lang="scss">
.password-strength {
  width: 100%;
  padding-top: 1rem;
	&__bars {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
	}

	&__bar {
		background: #ddd;
		border-radius: 999px;
		height: .5rem;
		margin-right: 1px;
		width: 25%;
	}
}

</style>
