<template>
  <form class="user-edit-form" autocomplete="off">
    <div class="row">
      <div class="col-5">
        <label for="firstname">Nom</label>
        <dc-input
          inputId="firstname"
          v-model.trim="$v.firstname.$model"
          :invalid="$v.firstname.$dirty && !$v.firstname.required"
        />
        <div
          class="error-message"
          v-if="$v.firstname.$error && !$v.firstname.required"
        >
          Vous devez saisir un prénom
        </div>
      </div>
      <div class="col-1"></div>
      <div class="col-5">
        <label for="lastname">Prénom</label>
        <dc-input
          inputId="lastname"
          v-model.trim="$v.lastname.$model"
          :invalid="$v.lastname.$dirty && !$v.lastname.required"
        />
        <div
          class="error-message"
          v-if="$v.lastname.$error && !$v.lastname.required"
        >
          Vous devez saisir un nom
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <label for="phone">Téléphone</label>
        <dc-input
          inputId="phone"
          v-model.trim="$v.phone.$model"
          :invalid="$v.phone.$dirty && !$v.phone.required"
        />
        <div
          class="error-message"
          v-if="$v.phone.$error && !$v.phone.required"
        >
          Vous devez saisir un numéro de téléphone
        </div>
        <div
          class="error-message"
          v-if="$v.phone.$error && !$v.phone.phoneValidator"
        >
          Ce numéro n'est pas valide
        </div>
      </div>
      <div class="col-1"></div>
      <div class="col-5">
        <label for="email">E-mail</label>
        <dc-input
          inputId="email"
          v-model.trim="$v.email.$model"
          :invalid="$v.email.$dirty && !$v.email.email"
        />
        <div
          class="error-message"
          v-if="$v.email.$error && !$v.email.required"
        >
          Vous devez saisir une adresse e-mail
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <label for="profil">Profil</label>
        <dc-select
          selectId="profil"
          :options="profilOptions"
          v-model.trim="$v.profil.$model"
          :invalid="$v.profil.$dirty && !$v.profil.required"
        />
        <div
          class="error-message"
          v-if="$v.profil.$error && !$v.profil.required"
        >
          Vous devez séléctionner un profil
        </div>
      </div>
      <div class="col-1"></div>
      <div class="col-5" v-if="$v.profil.$model == 'MAIRIE' || $v.profil.$model == 'PARTENAIRE'">
        <label for="permanence">Permanence</label>
        <dc-input
          inputId="location"
          prependIcon="location_on"
          v-debounce:100ms="onLocationChange"
          @focus="onLocationChange"
          placeholder="Permanence"
          v-model.trim="$v.location.$model"
          :auto-complete-values="locations"
          @value-selected="selectLocation"
          :auto-complete-on-click="autoCompleteOnClick"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <label for="address">Adresse 1</label>
        <dc-input
          inputId="address"
          v-model.trim="$v.address.$model"
        />
      </div>
      <div class="col-1"></div>
      <div class="col-5">
        <label for="address2">Adresse 2</label>
        <dc-input
          inputId="address2"
          v-model.trim="$v.address2.$model"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <label for="address3">Adresse 3</label>
        <dc-input
          inputId="address3"
          v-model.trim="$v.address3.$model"
        />
      </div>
      <div class="col-1"></div>
      <div class="col-5">
        <label for="city">Ville</label>
        <dc-input
          inputId="city"
          v-model.trim="$v.city.$model"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <label for="postalCode">Code postal</label>
        <dc-input
          inputId="postalCode"
          v-model.trim="$v.postalCode.$model"
          :invalid="$v.postalCode.$dirty && !$v.postalCode.required"
        />
        <div
          class="error-message"
          v-if="$v.postalCode.$error && !$v.postalCode.postalCodeValidator"
        >
          Ce code postal n'est pas valide
        </div>
        <div
          class="error-message"
          v-if="$v.postalCode.$error && !$v.postalCode.required"
        >
          Vous devez saisir un code postal
        </div>
      </div>
      <div class="col-7"></div>
    </div>
    <div class="hr"></div>
    <div class="actions-section">
      <div>
        <dc-button variant="primary" @click.prevent="save">
          Enregistrer
        </dc-button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import DcButton from '@/components/ui/Button'
import DcInput from '@/components/ui/Input'
import DcSelect from '@/components/ui/Select'
import { phoneValidator } from '@/validators/phone.js'
import { required, email, requiredIf } from 'vuelidate/lib/validators'
import { postalCodeValidator } from '@/validators/postal-code.js'
import { update, add } from '@/services/user.service.js'
import { placeQuery } from '@/services/place.service.js'
// import { password } from '@/validators/password.js'
// import DcPasswordStrength from '@/components/ui/PasswordStrength'

export default {
  name: 'UserEditForm',
  components: {
    DcInput,
    DcSelect,
    DcButton
    // DcPasswordStrength
  },
  props: {
    userData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      autoCompleteOnClick: false,
      locations: null,
      id: null,
      location: null,
      selectedLocation: null,
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      profil: 'CLIENT',
      profilOptions: [
        { label: 'Utilisateur', value: 'CLIENT' },
        { label: 'Partenaire', value: 'PARTENAIRE' },
        { label: 'Mairie', value: 'MAIRIE' },
        { label: 'Juriste', value: 'JURISTE' },
        { label: 'Juriste Plus', value: 'JURISTESUP' },
        { label: 'Administrateur', value: 'ADMIN' }
      ],
      address: '',
      address2: '',
      address3: '',
      city: '',
      postalCode: '',
      // adil: '',
      password: null,
      password2: null,
      passwordStrengthValid: false,
      isUpdate: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  methods: {
    selectLocation (value) {
      this.selectedLocation = value
      this.location = value.label
    },
    async onLocationChange (event) {
      let nbChars = this.nbCharAutoComplete
      if (!event || event === '') {
        nbChars = 0
        this.autoCompleteOnClick = true
      } else {
        this.autoCompleteOnClick = false
      }

      if (event.length >= nbChars) {
        try {
          const response = await placeQuery(event)
          this.locations = response
            .map(c => {
              return {
                label: c.name.trim() + '  (' + c.postalCode + ')',
                value: c
              }
            })
            .sort((a, b) => {
              if (a.label > b.label) {
                return 1
              } else {
                return -1
              }
            })
        } catch (error) {
          // console.error(error)
        }
      }
    },
    setStrength (value) {
      this.passwordStrengthValid = value
    },
    cancel () {
      this.$emit('cancel')
    },
    async save () {
      const newUser = {
        id: this.id,
        firstName: this.firstname,
        lastName: this.lastname,
        mobile: this.phone,
        email: this.email,
        role: this.profil,
        address1: this.address,
        address2: this.address2,
        address3: this.address3,
        city: this.city,
        postalCode: this.postalCode,
        password: this.password,
        adilId: this.profil === 'MAIRIE' || this.profil === 'PARTENAIRE' ? this.selectedLocation.value.id : null
      }
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.id) {
          try {
            await update(newUser)
            this.$emit('save')
          } catch (error) {
            // console.error(error)
            this.$emit('error', error)
          }
        } else {
          try {
            const user = await add(newUser)
            this.$emit('save', user)
          } catch (error) {
            this.$emit('error', error)
          }
        }
      }
    }
  },
  beforeMount () {
    if (this.userData) {
      this.isUpdate = !!this.userData.id
      this.id = this.userData.id
      this.firstname = this.userData.firstName
      this.lastname = this.userData.lastName
      this.phone = this.userData.mobile
      this.email = this.userData.email
      this.profil = this.userData.role
      this.address = this.userData.address1
      this.address2 = this.userData.address2
      this.address3 = this.userData.address3
      this.city = this.userData.city
      this.postalCode = this.userData.postalCode
      this.selectedLocation = this.userData.adil
        ? { label: this.userData.adil.name.trim() + '  (' + this.userData.adil.postalCode + ')', value: this.userData.adil } : null
      this.location = this.userData.adil
        ? this.userData.adil.name.trim() + '  (' + this.userData.adil.postalCode + ')' : null
      // this.adil = this.userData.adil ? this.userData.adil.id : null
    }
  },
  validations: {
    firstname: {
      required
    },
    lastname: {
      required
    },
    phone: {
      required: requiredIf(function (value) {
        return this.user.role === 'CLIENT'
      }),
      phoneValidator: function (value) {
        if (this.user.role !== 'CLIENT' && value === '') return true
        else return phoneValidator(value)
      }
    },
    email: {
      email
    },
    profil: {
      required
    },
    address: {
      required: requiredIf(function (value) {
        return this.user.role === 'CLIENT'
      })
    },
    address2: {},
    address3: {},
    city: {
      required: requiredIf(function (value) {
        return this.user.role === 'CLIENT'
      })
    },
    postalCode: {
      required,
      postalCodeValidator: function (value) {
        return postalCodeValidator(value)
      }
    },
    location: {}
  }
}
</script>

<style lang="scss">
.user-edit-form {
  &__error {
    color: $red;
    font-size: 1.1rem;
    font-weight: $font-weight-bold;
  }
  .actions-section {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    background-color: $light;
    width: 100%;
    .dc-button {
      margin: 1rem;
    }
  }
}
</style>
