<template>
  <div class="change-password-view">
    <div class="change-password-view__form">
      <h4>
        Veuillez saisir votre nouveau mot de passe
      </h4>
			<dc-change-password-form></dc-change-password-form>
    </div>

  </div>
</template>

<script>
import DcChangePasswordForm from '@/components/user/change-password-form.vue'

export default {
  name: 'ChangePassword',
  components: {
    DcChangePasswordForm
  }
}
</script>

<style lang="scss">
.change-password-view {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: $light-grey-blue;
  background: linear-gradient(90deg, $blue 45%, $light-grey-blue 45%);
  &__form {
    position: absolute;
    width: 73.4rem;
    height: 63.4rem;
    padding: 1rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 1rem;
    box-shadow: -7px 12.1px 25.4px 1.6px rgba(0, 0, 0, 0.14);
    background-color: $light;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
  }
  h4 {
    font-size: 2.3rem;
    letter-spacing: 0.69px;
    color: $grey2;
    text-align: center;
    margin-bottom: 4rem;
    font-weight: $font-weight-regular;
  }
}
</style>
