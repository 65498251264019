import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import { logout } from '@/services/user.service.js'
import { getErrorMessage } from './error-message.service'

axios.interceptors.request.use(function (config) {
  if (sessionStorage.getItem('secrettoken')) {
    config.headers.Authorization = `Bearer ${sessionStorage.getItem('secrettoken')}`
  }
  return config
}, function (error) {
  Vue.prototype.$notify({
    group: 'notification-group',
    type: 'error',
    title: 'Erreur',
    duration: 10000,
    text: 'Votre demande à échouée.'
  })
  return Promise.reject(error)
})

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    Vue.prototype.$notify({
      group: 'notification-group',
      type: 'error',
      duration: 10000,
      title: 'Accès non-autorisé',
      text: 'Vous devez vous identifier.'
    })
    logout()
    router.push({ name: 'login' })
  } else {
    Vue.prototype.$notify({
      group: 'notification-group',
      type: 'error',
      duration: 10000,
      title: 'Un problème est survenu',
      text: getErrorMessage(error.response.data.code)
    })
    return Promise.reject(error)
  }
})
