<template>
  <div class="appointment-choice">
    <div class="appointment-choice__wrapper">
      <template v-if="placesChoice && placesChoice.length > 0">
        <template v-for="(place, index) in placesChoice">
          <appointments-select
            :key="`appointment-${index}`"
            :events="place.dispos"
            :place="place"
            :duration="place.duration"
            @selectAppointment="chooseAppointment"
          />
        </template>
      </template>
      <template v-if="!placesChoice || placesChoice.length === 0">
        <div class="appointment-choice__message">
          Tous nos conseillers sont actuellement en rdv, veuillez réssayer dans 15 jours.
        </div>
      </template>
    </div>

    <dc-popin
      ref="userEditFormPopin"
      :showButtons="false"
      :show="showPopin"
      @on-close="closePopin"
      :title="'Réservation de rendez-vous'"
    >
      <appointment-create-form
        @save="onSave"
        @error="onError"
        @cancel="closePopin"
        :appointment-data="selectedItem"
      />
    </dc-popin>

  </div>
</template>

<script>
import { listAvailableAppointments } from '@/services/appointment.service.js'
import AppointmentCreateForm from '@/components/appointments/appointment-create-form.vue'
import AppointmentsSelect from '@/components/appointments/appointments-select.vue'
import DcPopin from '@/components/ui/Popin'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AppointmentsView',
  components: {
    DcPopin,
    AppointmentsSelect,
    AppointmentCreateForm
  },
  data () {
    return {
      showPopin: false,
      placesChoice: null,
      appointments: null,
      postalCode: null,
      thematic: null,
      isloading: true,
      selectedItem: null
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'user'
    }),
    isAdmin () {
      return this.currentUser && this.currentUser.role === 'ADMIN'
    }
  },
  methods: {
    ...mapActions({
      setCurrentThematic: 'SET_CURRENT_THEMATIC'
    }),
    onSave () {
      this.closePopin()
      this.$notify({
        group: 'notification-group',
        type: 'success',
        duration: 10000,
        title: 'L\'enregistrement a réussi',
        text: 'Le rendez-vous est enregistré'
      })
      if (this.currentUser.role === 'CLIENT') {
        this.$router.push({
          name: 'home-connected'
        })
      }
    },
    onError (value) {
      this.closePopin()
      this.$notify({
        group: 'notification-group',
        type: 'warning',
        duration: 10000,
        title: 'Aucune disponibilité',
        text: 'Le rendez-vous souhaité n\' est plus disponible'
      })
    },
    closePopin () {
      this.showPopin = false
    },
    chooseAppointment (event) {
      this.selectedItem = event.event
      this.showPopin = true
    }
  },
  async mounted () {
    if (!this.$route.params.postalCode || !this.$route.params.thematic) this.$router.push({ name: 'home' })
    this.postalCode = this.$route.params.postalCode || null
    this.thematic = this.$route.params.thematic || null
    this.setCurrentThematic(this.thematic)
    try {
      this.appointments = await listAvailableAppointments(this.postalCode, this.thematic)
      if (this.appointments) {
        this.placesChoice = this.appointments.filter(p => p.dispos && p.dispos.length > 0)
      }
      this.isloading = false
    } catch (error) {
      // console.error(error)
    }
  }
}
</script>

<style lang="scss">
.appointment-choice {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: $light-grey-blue;
  background: linear-gradient(90deg, $blue 38%, $light-grey-blue 38%);
  padding-top: calc(#{$header-height} + 1rem);
  &__message {
    background-color: $light;
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
    border-radius: 10px;
    padding: 5rem;
    box-shadow: 4.8px 7.6px 12.2px 0.8px rgba(0, 0, 0, 0.07);
  }
  &__wrapper {
    position: absolute;
    overflow-y: scroll;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    padding-top: 17.5rem;
  }
}

@keyframes fromRight {
  0% {
    right: -100vw;
  }
  80% {
    right: 5vw;
  }
  100% {
    right: 0;
  }
}
</style>
