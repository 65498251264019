<template>
  <div class="appointment-detail row">
    <div
      class="appointment-detail__time"
      :class="{'appointment-detail__time--is-history': isHistory}"
    >
      <span>
        <md-icon>today</md-icon>
      </span>
      <span>
        {{labelDate}}
      </span>
      <span>|</span>
      <span>{{appointmentInfos.startTime}} - {{appointmentInfos.endTime}}</span>
    </div>
    <div class="appointment-detail__place">
      <span>
        {{appointmentInfos.place.city}}
        <inline-svg
            :src="require('@/assets/img/icons/ICONE-LIEU.svg')"
          />
      </span>
    </div>
    <div class="appointment-detail__actions">
      <span>
        <dc-button
          v-if="!isHistory"
          @click="showPrompt = true"
          variant="secondary-small"
        >
          Annuler le rendez-vous
        </dc-button>
      </span>
    </div>
    <div class="appointment-detail__actions">
      <span>
        <dc-button
          @click="$emit('show-infos', appointmentInfos)"
          variant="secondary-small"
        >
          Détails
        </dc-button>
      </span>
    </div>

    <md-dialog-confirm
      :md-active.sync="showPrompt"
      md-title="Voulez-vous vraiment supprimer ce rendez-vous ?"
      md-confirm-text="Oui, j'annule ce rendez-vous"
      md-cancel-text="Non"
      @md-cancel="onCancel"
      @md-confirm="onConfirm" />
  </div>
</template>

<script>
import { cancelAppointment } from '@/services/appointment.service.js'
import DcButton from '@/components/ui/Button'
import dayjs from 'dayjs'

export default {
  name: 'AppointmentDetails',
  components: {
    DcButton
  },
  data () {
    return {
      showPrompt: false
    }
  },
  props: {
    appointmentInfos: {
      type: Object,
      required: false
    },
    isHistory: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onCancel () {
      this.showPrompt = false
    },
    async onConfirm () {
      this.showPrompt = false
      try {
        await cancelAppointment(this.appointmentInfos.id)
        this.$emit('cancelAppointment')
      } catch (error) {
        // console.error('ERROR cancelAppointment', error)
      }
    }
  },
  computed: {
    labelDate () {
      return dayjs(this.appointmentInfos.startDate).format('DD MMMM YYYY')
    }
  }
}
</script>

<style lang="scss">
.appointment-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  &__time {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: $blue;
    flex-direction: row;
    width: 40%;
    height: 6.5rem;
    border-radius: 1rem;
    color: $light;
    font-size: 1.8rem;
    font-weight: $font-weight-bold;
    letter-spacing: 1.35px;
    text-align: left;
    .md-icon.md-theme-default.md-icon-font {
      color: $light;
      font-weight: $font-weight-light;
      font-size: 2.4rem !important;
    }
    &--is-history {
      background-color: $light-grey;
      color: $light-grey-3;
      .md-icon.md-theme-default.md-icon-font {
        color: $light-grey-3;
        font-weight: $font-weight-light;
        font-size: 2.4rem !important;
      }
    }

    .appointment-detail__actions{
      span{
        button{
          max-width: initial !important;
        }
      }
    }
  }
  &__place {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    width: 45%;
    height: 6.5rem;
    color: $blue;
    font-size: 1.8rem;
    font-weight: $font-weight-bold;
    letter-spacing: 1.35px;
    text-align: left;
    svg {
      fill: $blue;
      font-weight: $font-weight-light;
      width: 1.5rem !important;
    }
  }
  &__actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    width: 15%;
    height: 6.5rem;
  }
}
.md-ripple {
  .md-button-content {
    color: $dark !important;
  }
}
</style>
