<template>
  <div class="rtt-view">

    <div class="rtt-view__list">
      <title-deck>RTT</title-deck>
      <div class="rtt-view__filter">
        <dc-select
          v-if="advisers"
          selectId="adviserFilter"
          :options="advisers"
          v-model="selectedAdviser"
          placeholder="Conseillè(re)"
        />
        <span>Debut</span>
        <dc-input
          type="date"
          inputId="startDate"
          v-model="startDate"
        />
        <span>Fin</span>
        <dc-input
          type="date"
          inputId="endDate"
          v-model="endDate"
        />
        <dc-button @click="refreshList()" variant="secondary-small">
            <md-icon>refresh</md-icon>
          </dc-button>
      </div>
      <dc-table :columns="cols" :rows="rows" rowItemName="rtt">

        <template v-slot:cell-jurist="{rtt}">
            <div v-bind:class="'jurist'">{{ rtt.jurist }}</div>
        </template>

        <template v-slot:cell-total="{rtt}">
            <div v-bind:class="'total'">{{ rtt.total | minutesTohourString}}</div>
        </template>

        <template v-slot:cell-button-edit="{rtt}">
          <dc-button @click="onDetails(rtt.details)" variant="secondary-small">
            <md-icon>list</md-icon>
          </dc-button>
        </template>
      </dc-table>
      <template v-if="rows">
        <div class="hr hr--no-mb"></div>
        <span class="table-total">
          {{ rows.length }} Total
        </span>
      </template>
    </div>
    <dc-popin
      ref="rttDetailsPopin"
      :showButtons="false"
      :show="showPopin"
      @on-close="closePopin"
      :title="'Détail'"
    >
    <dc-table :columns="detailCols" :rows="detailRows" rowItemName="detail">
        <template v-slot:cell-place="{detail}">
          <div v-bind:class="'place'">{{ detail.place }}</div>
        </template>

        <template v-slot:cell-monday="{detail}">
          <div v-bind:class="'day'">{{ detail.monday | minutesTohourString}}</div>
        </template>

        <template v-slot:cell-tuesday="{detail}">
          <div v-bind:class="'day'">{{ detail.tuesday | minutesTohourString}}</div>
        </template>

        <template v-slot:cell-wednesday="{detail}">
          <div v-bind:class="'day'">{{ detail.wednesday | minutesTohourString}}</div>
        </template>

        <template v-slot:cell-thursday="{detail}">
          <div v-bind:class="'day'">{{ detail.thursday | minutesTohourString}}</div>
        </template>

        <template v-slot:cell-friday="{detail}">
          <div v-bind:class="'day'">{{ detail.friday | minutesTohourString}}</div>
        </template>

        <template v-slot:cell-bonus="{detail}">
          <div v-bind:class="'bonus'">{{ detail.bonus | minutesTohourString}}</div>
        </template>

        <template v-slot:cell-total="{detail}">
          <div v-bind:class="'total'">{{ detail.total | minutesTohourString}}</div>
        </template>
      </dc-table>
    </dc-popin>

  </div>
</template>

<script>
import DcTable from '@/components/ui/Table'
import TitleDeck from '@/components/ui/TitleDeck'
import DcPopin from '@/components/ui/Popin'
import DcButton from '@/components/ui/Button'
import DcSelect from '@/components/ui/Select'
import DcInput from '@/components/ui/Input'
import dayjs from 'dayjs'

import { getRtt } from '@/services/report.service.js'
import { getUserByRole } from '@/services/user.service.js'

export default {
  name: 'rttView',
  components: {
    DcTable,
    DcPopin,
    TitleDeck,
    DcSelect,
    DcInput,
    DcButton
  },
  data () {
    return {
      cols: [
        { name: 'jurist', label: 'Nom', sortOrder: 'ASC' },
        { name: 'total', label: 'Total RTT', sortOrder: 'ASC' },
        { name: 'button-edit', label: '', sortOrder: 'ASC' }
      ],
      rows: [],
      detailCols: [
        { name: 'place', label: 'Lieu', sortOrder: 'ASC' },
        { name: 'monday', label: 'Lundi', sortOrder: 'ASC' },
        { name: 'tuesday', label: 'Mardi', sortOrder: 'ASC' },
        { name: 'wednesday', label: 'Mercredi', sortOrder: 'ASC' },
        { name: 'thursday', label: 'Jeudi', sortOrder: 'ASC' },
        { name: 'friday', label: 'Vendredi', sortOrder: 'ASC' },
        { name: 'bonus', label: 'Bonus RTT', sortOrder: 'ASC' },
        { name: 'total', label: 'Total RTT', sortOrder: 'ASC' }
      ],
      detailRows: [],
      showPopin: false,
      showPrompt: false,
      advisers: null,
      selectedAdviser: null,
      startDate: null,
      endDate: null
    }
  },
  computed: {
    userId () {
      return this.selectedAdviser ? JSON.parse(this.selectedAdviser).id : null
    },
    formattedStartDate () {
      return this.startDate ? dayjs(this.startDate).format('DD/MM/YYYY') : dayjs().format('DD/MM/YYYY')
    },
    formattedEndDate () {
      return this.endDate ? dayjs(this.endDate).format('DD/MM/YYYY') : dayjs().format('DD/MM/YYYY')
    }
  },
  filters: {
    minutesTohourString: function (value) {
      const isNegative = value < 0
      const positiveValue = Math.abs(value)
      const hour = Math.floor(positiveValue / 60)
      const minute = positiveValue % 60
      const hourString = (isNegative ? '-' : '') + hour.toString().padStart(2, '0')
      return hourString + 'h' + minute.toString().padStart(2, '0')
    }
  },
  methods: {
    async refreshList () {
      try {
        const rtts = await getRtt(this.userId, this.formattedStartDate, this.formattedEndDate)
        this.rows = [...rtts]
      } catch (error) {
        // console.error('refreshList()', error)
      }
    },
    onDetails (details) {
      this.showPopin = true
      this.detailRows = details
    },
    onCancel () {
      this.showPrompt = false
      this.detailRows = []
    },
    closePopin () {
      this.showPopin = false
      this.detailRows = []
    },
    async loadAdvisers () {
      try {
        const results = await getUserByRole(['JURISTE', 'JURISTESUP'])
        if (results) {
          this.advisers = results.map(a => {
            return {
              label: `${a.firstName} ${a.lastName}`,
              value: JSON.stringify(a)
            }
          }).sort((a, b) => {
            if (a.label > b.label) {
              return 1
            } else {
              return -1
            }
          })
        }
      } catch (error) {
        // console.error('loadAdvisers', error)
      }
    }
  },
  async mounted () {
    this.endDate = dayjs().format('YYYY-MM-DD')
    this.startDate = dayjs().add(-1, 'month').format('YYYY-MM-DD')
    await this.loadAdvisers()
    await this.refreshList()
  }
}
</script>

<style lang="scss" scoped>
.rtt-view {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: $light-grey-blue;
  background: linear-gradient(90deg, $blue 38%, $light-grey-blue 38%);
  padding-top: calc(#{$header-height} + 1rem);
  overflow-y: auto;
  &__filter {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    margin-bottom: 2rem;
    span {
      margin: auto;
      font-size: 2rem;
    }
    .dc-button {
      height: 3rem;
      width: 6rem;
      margin: auto;
    }
  }

  &__list {
    position: absolute;
    z-index: 2;
    border-radius: 1rem;
    left: 50%;
    top: 14rem;
    transform: translateX(-50%);
    width: 123.5rem;
    background-color: $light;
    box-shadow: 4.8px 7.6px 12.2px 0.8px rgba(0, 0, 0, 0.07);
    padding: 5rem;
    padding-top: 9rem;
    margin-bottom: 12rem;

    .md-icon {
      color: $light !important;
    }
  }

  svg {
    stroke: #ffffff !important;
  }

  &__table-footer-buttons {
    display: flex;
    justify-content: flex-end;
  }
}

.dc-table {
  .place, .jurist {
    width: 30rem;
  }

  .day, .bonus {
    width: 12rem;
    text-align: right;
  }

  .total {
    width: 9rem;
    text-align: right;
  }
}
</style>
