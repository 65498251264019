<template>
  <form @submit.prevent="changePassword"
    class="change-password-form"
    autocomplete="off">
    <label for="password">
      Mot de passe
    </label>
    <dc-input
      inputId="password"
      name="password"
      type="password"
      v-model.trim="$v.password.$model"
      :invalid="$v.password.$error"
    />
    <div
      class="change-password-form_error"
      v-if="$v.password.$error && !$v.password.required"
    >
      Le mot de passe est obligatoire
    </div>
    <dc-password-strength
      :password-to-check="$v.password.$model"
      @password-strength="setStrength"
    />
    <label for="password2">
      Confirmation du mot de passe
    </label>
    <dc-input
      inputId="password2"
      name="password2"
      type="password"
      v-model.trim="$v.password2.$model"
      :invalid="$v.password2.$error"
    />
    <div
      class="change-password-form_error"
      v-if="$v.password2.$error && !$v.password2.required"
    >
      Le mot de passe est obligatoire
    </div>
    <div
      class="change-password-form_error"
      v-if="$v.password2.$error && !$v.password2.password"
    >
      Vos mots de passe sont différents
    </div>
    <dc-button variant="outline" type="submit">
      Valider mon mot de passe
    </dc-button>
  </form>
</template>

<script>
import { password } from '@/validators/password.js'
import DcPasswordStrength from '@/components/ui/PasswordStrength'
import { resetPassword } from '@/services/user.service.js'
import DcInput from '@/components/ui/Input'
import DcButton from '@/components/ui/Button'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'UserLoginForm',
  components: {
    DcInput,
    DcButton,
    DcPasswordStrength
  },
  data () {
    return {
      password: '',
      password2: '',
      resetToken: null,
      passwordStrength: false
    }
  },
  props: {
    tokenValue: {
      type: String,
      default: null
    }
  },
  methods: {
    setStrength (event) {
      this.passwordStrength = event
    },
    async changePassword () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      try {
        await resetPassword({
          password: this.password,
          token: this.resetToken
        })
        this.$notify({
          group: 'notification-group',
          type: 'success',
          duration: 10000,
          title: 'Changement de mot de passe validé',
          text: 'Vous allez être redirigé'
        })
        this.$router.push('/login')
      } catch (error) {
        // console.error('ERROR changePassword', error)
      }
    }
  },
  mounted () {
    this.resetToken = this.$route.query ? this.$route.query.token : this.tokenValue
  },
  validations: {
    password: {
      required,
      password
    },
    password2: {
      required,
      password
    }
  }
}
</script>

<style lang="scss">
.change-password-form  {
  width: 52.4rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  &__error {
    color: $red;
    font-size: 1.1rem;
    font-weight: $font-weight-bold;
  }
  .dc-input {
    margin-bottom: 2.5rem;
  }
}
</style>
